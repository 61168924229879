import React, { Component } from "react"
import { tquery } from "../app/ResponsiveLayout-20190729"

export var ratings
ratings = []

export class StarsComponent extends Component {
  render() {
    var variable = this.props.variable
    var type = this.props.type
    var result = []

    //default
    if (ratings[variable] === undefined) ratings[variable] = 3

    //INPUT
    if (type === "input") {
      for (let i = 0; i < ratings[variable]; i++) {
        result.push(
          <i
            key={"stars" + i}
            onClick={event => {
              ratings[variable] = i + 1

              tquery("#updateReact").click()
            }}
            className="fas fa-star link"
          ></i>,
        )
      }
      for (let i = ratings[variable]; i < 5; i++) {
        result.push(
          <i
            key={"stars" + i}
            onClick={event => {
              ratings[variable] = i + 1

              tquery("#updateReact").click()
            }}
            style={{ opacity: "0.2" }}
            className="fas fa-star link"
          ></i>,
        )
      }
    }

    //OUTPUT
    else {
      for (let i = 0; i < ratings[variable]; i++) result.push(<span key={"stars" + i} className="fas fa-star" />)
      for (let i = ratings[variable]; i < 5; i++)
        result.push(<span key={"stars" + i} style={{ opacity: "0.2" }} className="fas fa-star" />)
    }

    //RETURN
    return <span> {result} </span>
  }
}
