import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { tquery } from "../app/ResponsiveLayout-20190729"
import bannerSmall from "../img/banner.png"
import { savedText } from "./../SavedText"
import { tracker, Xstate } from "../functions/Shared"
import { LoginModal } from "../functions/Account"

const ChangeColor = () => {
  Xstate.css.change = 1
  if (Xstate.css.mode === "dark") Xstate.css.mode = "light"
  else Xstate.css.mode = "dark"
  tquery("#responsiveLayout").click()
  tquery("#updateReact").click()
}
const buttonPaint = (
  <button
    className="btn btn-header"
    title="change the color scheme"
    id="changecolor"
    onClick={event => {
      ChangeColor()
    }}
  >
    <i className=" fa fa-paint-brush" />
  </button>
)
const buttonCoffee = (
  <button
    className="btn btn-header"
    id="coffee"
    title="support connactive club - buy us a coffee"
    onClick={() => {
      tracker("coffee")
      window.open("https://ko-fi.com/connactiveclub")
    }}
  >
    <i className=" fa fa-coffee" />
  </button>
)
const supportModal = () => {}
const buttonSport = () => {
  let text = Xstate.sport
  if (Xstate.sport === "default") text = "Select a Sport"
  return (
    <button
      className="btn btn-header sportsButtonVisible"
      id={text}
      onClick={event => {
        Xstate.sportVariable = "Xstate"
        Xstate.modal.status = "SportandLocationList"
        tquery("#updateReact").click()
      }}
    >
      {text}
    </button>
  )
}
export class AppHeader extends Component {
  navbarLarge = () => {
    return (
      <div className="w100p navbar-large">
        <div className="left">
          <img src={bannerSmall} className="banner-title" alt="Connactive Club"></img>
          <h1 className="hidden"> Connactive Club</h1>
        </div>
        <div className="center middle">
          <NavLink
            id="homeButton"
            className="btn btn-header"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
            exact
            to="/"
          >
            <i className=" fa  fa-home"></i> Home
          </NavLink>
          <NavLink
            id="ClubButton"
            className="btn btn-header"
            to="/club"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
          >
            <span id="leagueIcon">
              <i className=" fas fa-clipboard"></i>
            </span>
            Clubs
          </NavLink>
          <NavLink
            id="venuesButton"
            className="btn btn-header"
            to="/venues"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
          >
            <span id="venueIcon">
              <i className=" fa  fa-globe"></i>
            </span>
            Venues
          </NavLink>
          <NavLink
            id="playerSearchButton"
            className="btn btn-header"
            to="/playersearch"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
          >
            <span id="playerSearchIcon">
              <i className=" fa  fa-user-friends"></i>
            </span>
            Player Search
          </NavLink>
          <NavLink
            id="messageBoardButton"
            className="btn btn-header"
            to="/messageboard"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
          >
            <span id="messageBoardIcon">
              <i className=" fa  fa-comment-alt"></i>
            </span>
            Message Board
          </NavLink>
          <NavLink
            id="aboutusButton"
            className="btn btn-header"
            to="/aboutus"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
          >
            <span>
              <i className=" fa  fa-info-circle"></i>
            </span>
            {savedText.home.about_us}
          </NavLink>
          {buttonSport()}
        </div>
        <div className="right">
          <NavLink
            onClick={() => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            id="loginButton"
            to="/account"
            style={{ display: "none" }}
          >
            <i className=" fa  fa-user"></i>
          </NavLink>
          {buttonPaint}
          {buttonCoffee}
          <button
            type="submit"
            id="loginModalButton1"
            className="btn btn-header loginButtonVisible"
            onClick={event => {
              if (Xstate.login.status) {
                document.querySelector("#loginButton").click()
              } else {
                LoginModal()
              }
            }}
          >
            <i className=" fa  fa-user"></i>
            <span id="loginText">{Xstate.login.text}</span>
          </button>
        </div>
      </div>
    )
  }
  navbarMedium = () => {
    return (
      <div className="w100p navbar-medium">
        <div className="left">
          <img src={bannerSmall} className="banner-medium" alt="Connactive Club"></img>
          <h1 className="hidden"> Connactive Club</h1>
        </div>
        <div className="left middle">
          <NavLink
            id="homeButton"
            className="btn btn-header btn-header-medium"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
            exact
            to="/"
          >
            <i className=" fa  fa-home"></i>
          </NavLink>
          <NavLink
            id="ClubButton"
            className="btn btn-header btn-header-medium"
            to="/club"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
          >
            <i className=" fas fa-clipboard"></i>
          </NavLink>
          <NavLink
            id="venuesButton"
            className="btn btn-header btn-header-medium"
            to="/venues"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
          >
            <i className=" fa  fa-globe"></i>
          </NavLink>
          <NavLink
            id="playerSearchButton"
            className="btn btn-header btn-header-medium"
            to="/playersearch"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
          >
            <i className=" fa  fa-user-friends"></i>
          </NavLink>
          <NavLink
            id="messageBoardButton"
            className="btn btn-header btn-header-medium"
            to="/messageboard"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
          >
            <i className=" fa  fa-comment-alt"></i>
          </NavLink>
          <NavLink
            id="aboutusButton"
            className="btn btn-header btn-header-medium"
            to="/aboutus"
            onClick={event => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            isActive={function () {
              return false
            }}
          >
            <i className=" fa  fa-info-circle"></i>
          </NavLink>
          {buttonSport()}
        </div>
        <div className="right middle">
          <NavLink
            onClick={() => {
              tquery("#updateReact").click()
              tquery(".contentsection").scrollTo(0, 0)
            }}
            id="loginButton"
            to="/account"
            style={{ display: "none" }}
          >
            <i className=" fa  fa-user"></i>
          </NavLink>
          {buttonPaint}
          {buttonCoffee}
          <button
            type="submit"
            id="loginModalButton2"
            className="btn btn-header btn-header-medium loginButtonVisible"
            onClick={event => {
              if (Xstate.login.status) {
                document.querySelector("#loginButton").click()
              } else {
                LoginModal()
              }
            }}
          >
            <i className=" fa  fa-user"></i>
            <span id="loginText">{Xstate.login.text}</span>
          </button>
        </div>
      </div>
    )
  }
  navbarTiny = () => {
    return (
      <div>
        <div className="p100 navbar-tiny flex-center">
          <img src={bannerSmall} className="banner-title" alt="Connactive Club"></img>
          <h1 className="hidden"> Connactive Club</h1>
        </div>
        <div className="p100 navbar-tiny flex-between">
          <div>
            <button
              id="menuButton"
              className="btn btn-header"
              onClick={event => {
                Xstate.modal.status = "menu"
                tquery("#updateReact").click()
              }}
            >
              <i className=" fa  fa-bars" /> Menu
            </button>
          </div>
          <div>
            {buttonSport()}
            <NavLink
              onClick={event => {
                tquery("#updateReact").click()
                tquery(".contentsection").scrollTo(0, 0)
              }}
              id="loginButton"
              to="/account"
              style={{ display: "none" }}
            >
              <i className=" fa  fa-user"></i>
            </NavLink>
            {buttonPaint}
            {buttonCoffee}
            <button
              type="submit"
              id="loginModalButton4"
              className=" loginButtonVisible btn btn-header"
              onClick={event => {
                if (Xstate.login.status) {
                  document.querySelector("#loginButton").click()
                } else {
                  LoginModal()
                }
              }}
            >
              <i className=" fa  fa-user"></i> <span id="loginText">{Xstate.login.text}</span>
            </button>
          </div>
        </div>
      </div>
    )
  }
  navbarHidden = () => {
    return (
      <div hidden>
        {" "}
        <NavLink
          id="scheduleButton"
          to="/schedule"
          onClick={event => {
            tquery("#updateReact").click()
            tquery(".contentsection").scrollTo(0, 0)
          }}
          isActive={function () {
            return false
          }}
        >
          SCHEDULE
        </NavLink>{" "}
      </div>
    )
  }

  render() {
    return (
      <div className="navbar-fixed-top">
        {this.navbarLarge()}
        {this.navbarMedium()}
        {this.navbarTiny()}
        {this.navbarHidden()}
      </div>
    )
  }
}
