import React, { useEffect, Component } from "react"
import { BrowserRouter } from "react-router-dom"
import { ShowModal } from "./app/AppModal"
import { savedText } from "./SavedText"
import { AppHeader } from "./app/AppHeader"
import { AppContent } from "./app/AppContent"
import { AdComponent } from "./app/Adsense"
import { LandingPage } from "./app/LandingPage"
import { SportSelectionPage } from "./app/SportSelectionPage"
import { fetchLink } from "./fetchLink"
import { DatabaseStart, Xstate, tDateFormat } from "./functions/Shared"
import { UpdateLogin } from "./functions/Account"
import { hyperlinks } from "./functions/hyperlinks"
import { tquery, DelayedResponsiveLayout, ResponsiveLayout, cleanPath } from "./app/ResponsiveLayout-20190729"
import { appfooter } from "./app/AppFooter"
import { kofi } from "./kofi/kofi"

//DATABASE
export var dbStatus = () => {
  if (Xstate.dbStatusValue === undefined) Xstate.dbStatusValue = 0
  if (Xstate.dbStatusValue !== 0) {
    return Xstate.dbStatusValue
  }
  var formData = new FormData()
  formData.append("section", "general")
  formData.append("request", "dbStatus")
  return fetch(fetchLink, {
    credentials: "include",
    method: "POST",
    body: formData,
  })
    .catch(error => {
      Xstate.dbStatusValue = "local"
      return "local"
    })
    .then(function (response) {
      if (!response.ok) {
        Xstate.dbStatusValue = "local"
        return "local"
      } else {
        return response.text()
      }
    })
    .then(response => {
      if (response === 1) {
        /*do nothing*/
        Xstate.dbStatusValue = "error"
        tquery("#updateReact").click()
      } else {
        Xstate.dbStatusValue = response
        tquery("#updateReact").click()
      }
      return response
    })
}
let counter = 0

//DEFAULT VARIABLES FOR STATE
export var message = {}

export var playersearch = {
  count: 0,
  showall: 1,
  posts0: "all",
  posts1: "all",
  league_type: "all",
  KM: "100",
}

export var members = {
  showall: 1,
}

class App extends Component {
  constructor() {
    super()

    this.state = { ...Xstate }

    //League
    this.updateReactState = this.updateReactState.bind(this)
  }
  updateReactState() {
    DatabaseStart()
    if (Xstate.layoutTime === undefined) {
      Xstate.layoutTime = 0
    }
    let currentTime = tDateFormat(new Date(), "num-full(secs)")
    if (currentTime > 1 * Xstate.layoutTime) {
      DelayedResponsiveLayout(1)
      DelayedResponsiveLayout(5)
      DelayedResponsiveLayout(10)
      Xstate.layoutTime = 1 * currentTime
    }
    for (let $i = 0; $i < Object.keys(Xstate).length; $i++) {
      let item = Object.keys(Xstate)[$i]
      this.setState({ [item]: Xstate[item] })
    }
  }

  render() {
    //CHECK FOR HYPERLINKS
    if (window.location.search !== "") hyperlinks()

    dbStatus()
    //UpdateLogin();

    //buttonTesting
    let buttonTesting = (
      <button
        className="hidden"
        onClick={() => {
          if (Xstate.testing === "on") Xstate.testing = "off"
          else if (Xstate.testing === "off") Xstate.testing = "test"
          else Xstate.testing = "on"
          tquery("#updateReact").click()
          console.log(Xstate.testing)
        }}
        id="buttonTesting"
      >
        {Xstate.testing}
      </button>
    )

    let buttonUpdateReact = (
      <>
        <button
          onClick={() => {
            cleanPath()
            kofi()
            this.updateReactState()
          }}
          id="updateReact"
          className="hidden"
        />
        <button
          onClick={() => {
            ResponsiveLayout()
          }}
          id="responsiveLayout"
          className="hidden"
        />
      </>
    )
    if (this.state.login.db === 0) {
      counter++
      if (counter < 2) {
        UpdateLogin()
        setTimeout(() => {
          tquery("#updateReact").click()
        }, 10)
      }
      if (counter >= 2) {
        Xstate.login.db = 1
      }
      return (
        <span>
          {buttonTesting}
          {buttonUpdateReact}
          <div className="block-green" />
        </span>
      )
    }
    if (Xstate.login.db === 1) {
      if (Xstate.login.status === true) {
        Xstate.landingPage = 0
        Xstate.subLandingPage = 0
        if (Xstate.sport === "default") {
          Xstate.sport = Xstate.login.default_sport
          tquery("#updateReact").click()
        }
      } else if (Xstate.login.status === false) {
        if (Xstate.sport === "default") {
          Xstate.sport = "All Sports"
          this.setState({ sport: Xstate.sport })
        }
      }
    }

    if (cleanPath().length > 3) {
      Xstate.subLandingPage = 0
      Xstate.landingPage = 0
    }

    if (Xstate.landingPage === 1) {
      return (
        <BrowserRouter>
          <span>
            {buttonTesting}
            {buttonUpdateReact}
            {LandingPage()}
          </span>
        </BrowserRouter>
      )
    } else if (Xstate.subLandingPage === 1) {
      return (
        <span>
          {buttonTesting}
          {buttonUpdateReact}
          <BrowserRouter>
            <span>
              <SportSelectionPage parentState={this.state} updateFetch={this.updateFetch} />
              <ShowModal parentState={this.state} />
            </span>
          </BrowserRouter>
        </span>
      )
    }

    return (
      <div className="divApp" style={{ paddingTop: "env(safe-area-inset-top)" }}>
        <span>
          {buttonTesting}
          {buttonUpdateReact}
          <BrowserRouter>
            <div className="App">
              <AdComponent />
              <ShowModal parentState={this.state} />
              <AppHeader parentState={this.state} updateFetch={this.updateFetch} />
              <AppContent
                parentState={this.state}
                //REACT SOCKET
                updateStandings={this.updateStandings}
                createNewLeague={this.createNewLeague}
              />
              {appfooter}
            </div>
          </BrowserRouter>
        </span>
      </div>
    )
  }
}

export default App
