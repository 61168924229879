import React, { Component } from "react"
import GoogleLogin from "react-google-login"
import { NavLink } from "react-router-dom"
import { Xstate } from "../functions/Shared"
import PictureGoogle from "../img/google.png"

import { tquery } from ".././app/ResponsiveLayout-20190729"
import { modalText, modalTextNoButton } from "../app/AppModal"
import { savedText } from "../SavedText"
import {
  ClearAccountMsg,
  ChangeUsernameSubmit,
  Login,
  LoginModal,
  Register,
  ResetPasswordFetch,
  ChangePassword,
} from "../functions/Account"
//import dateformat from 'dateformat';
//import DatePicker from "react-datepicker";

export class ModalLogin extends Component {
  //CONSTRUCTOR
  constructor(props) {
    super(props)
    this.responseGoogle = this.responseGoogle.bind(this)
    this.registerGoogle = this.registerGoogle.bind(this)
  }

  //LOGIN FUNCTIONS
  handleRegisterSubmit(event = 1) {
    if (event !== 1) {
      event.preventDefault()
      Xstate.login.register.type = "email"
      Xstate.login.register.inputUsername = tquery("#inputUsername").value
      Xstate.login.register.inputEmail = tquery("#inputEmail").value
      Xstate.login.register.inputPassword = tquery("#inputPassword").value
      Xstate.login.register.inputPassword2 = tquery("#inputPassword2").value
      tquery("#inputEmail").required = true
      tquery("#inputPassword").required = true
      tquery("#inputPassword2").required = true
    }
    Xstate.login.register.default_sport = "none"
    Register()
  }
  ResetPasswordFetch(event) {
    event.preventDefault()
    Xstate.login.resetpswd.inputResetEmail = tquery("#inputResetEmail").value
    ResetPasswordFetch(event)
  }
  clickGoogle(event) {
    event.preventDefault()
    if (Xstate.login.register.inputUsername === undefined) Xstate.login.register.inputUsername = ""
    if (Xstate.modal.status === "register") {
      if (Xstate.login.register.inputUsername.trim() === "") {
        Xstate.login.register.msg0 = "Please Create a Username To Register"
        tquery("#updateReact").click()
        return 0
      }
      tquery("#inputEmail").required = false
      tquery("#inputPassword").required = false
      tquery("#inputPassword2").required = false
    }
    document.getElementsByClassName("google")[0].click()
  }
  responseGoogle(response) {
    console.log("here")
    if (response.type === "Error") {
      modalText("Sorry, the connection is not strong with this internet.")
      return 1
    }
    console.log(response)
    Xstate.login.inputEmail = response.profileObj.email
    Xstate.login.inputPassword = response.googleId
    Xstate.login.type = "google"
    Login()
  }
  registerGoogle(response) {
    Xstate.login.register.inputUsername = tquery("#inputUsername").value
    if (Xstate.login.register.inputUsername.trim() === "") {
      Xstate.login.register.msg0 = "Please Create a Username To Register"
      tquery("#updateReact").click()
      return 0
    }
    console.log(response)
    Xstate.login.register.inputEmail = response.profileObj.email
    Xstate.login.register.inputPassword = response.googleId
    Xstate.login.register.inputPassword2 = response.googleId
    Xstate.login.register.type = "google"
    this.handleRegisterSubmit()
  }

  //RENDER
  render() {
    //LOGIN
    if (Xstate.modal.status === "login") {
      return modalTextNoButton(
        <span>
          <h2> Login </h2>
          <h4> Login by Email</h4>
          <form
            id="login_form"
            onSubmit={event => {
              event.preventDefault()
              Xstate.login.type = "email"
              Login()
            }}
          >
            <div className="row">
              <div className="left col-sm-12 bold"> Email: </div>
              <div className="left col-sm-12">
                <input
                  className="input-login"
                  type="text"
                  id="inputEmail"
                  name="inputEmail"
                  value={Xstate.login.inputEmail}
                  title="email required: please input a valid email address."
                  pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$"
                  autoComplete="on"
                  onChange={event => {
                    ClearAccountMsg("login")
                    Xstate.login.inputEmail = event.target.value
                  }}
                  required
                />
              </div>
              <div className="font-small col-sm-12">&nbsp;</div>
              <div className="left col-sm-12 bold"> Password: </div>
              <div className="left col-sm-12">
                <input
                  className="input-login"
                  type="password"
                  autoComplete="password"
                  id="inputPassword"
                  name="inputPassword"
                  value={Xstate.login.inputPassword}
                  onChange={event => {
                    ClearAccountMsg("login")
                    Xstate.login.inputPassword = event.target.value
                    tquery("#updateReact").click()
                  }}
                  required
                />
              </div>
            </div>
            <br />
            <div id="alert-message0">{Xstate.login.msg}</div>
            <div id="reactivation-message">{Xstate.JSX.loginReactivate}</div>
            <button id="signin" className="btn btn-content1" data-loading-text="Loading...">
              Login
            </button>
            <br />
            <button
              id="forgotPasswordButton"
              className="btn btn-content3 btn-small"
              onClick={event => {
                Xstate.modal.status = "forgotpswd"
                tquery("#updateReact").click()
              }}
            >
              Forgot Password
            </button>
          </form>
          <div className="green-line" />
          <br />
          <span id="Google">
            <h4> Login with Social Media</h4>
            <img
              id="googleLoginButton"
              src={PictureGoogle}
              className="btn btn-content1 social_login"
              onClick={event => {
                this.clickGoogle(event)
              }}
            />

            <span style={{ display: "none" }}>
              <GoogleLogin
                clientId="1018078836675-5sm8qcsdhepuktkasi56r36ud8cefedp.apps.googleusercontent.com"
                spanText="Google"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={"single_host_origin"}
                className="google"
              />
            </span>
            <div className="green-line" />
          </span>
          <h4>{savedText.login.register_for_new_account}</h4>
          <div>
            <button
              id="registerButton"
              className="btn btn-content1"
              onClick={event => {
                Xstate.login.register = {}
                Xstate.modal.status = "register"
                tquery("#updateReact").click()
              }}
            >
              Register
            </button>
            <br />
          </div>
          <br />
          &nbsp;
        </span>,
      )
    }
    if (Xstate.modal.status === "register") {
      if (Xstate.login.register.msg0 !== undefined) {
        if (Xstate.login.register.msg0.trim() === "Login-Social") {
          Xstate.modal.status = "text"
          tquery("#updateReact").click()
          Xstate.login.msg = savedText.login.registration_social
        }
      }
      return modalTextNoButton(
        <>
          <button
            className="btn btn-content1"
            onClick={event => {
              LoginModal()
            }}
          >
            Login
          </button>
          <h2> Register </h2>
          <h4> {savedText.login.create_username} </h4>
          <div></div>
          <form
            id="login_form"
            onSubmit={event => {
              this.handleRegisterSubmit(event)
            }}
          >
            <b>Username:&nbsp;</b>
            <br />
            <input
              className="input-login"
              type="text"
              id="inputUsername"
              name="inputUsername"
              value={Xstate.login.register.inputUsername}
              minLength="2"
              maxLength="100"
              title="public username: your public username must be between 2 and 100 characters"
              onChange={event => {
                ClearAccountMsg("register")
                Xstate.login.register.inputUsername = event.target.value
                tquery("#updateReact").click()
              }}
              required
            />
            <br />
            <div className="center 100p">
              <div style={{ textAlign: "left", display: "inline-block" }}>
                <div id="alert-message0">{Xstate.login.register.msg0}</div>
                <div id="alert-message1">{Xstate.login.register.msg1}</div>
                <div id="alert-message2">{Xstate.login.register.msg2}</div>
                <div id="alert-message3">{Xstate.login.register.msg3}</div>
                <div id="alert-message4">{Xstate.login.register.msg4}</div>
                <div id="alert-message5">{Xstate.login.register.msg5}</div>
                <div id="alert-message6">{Xstate.login.register.msg6}</div>
                <div id="alert-message7">{Xstate.login.register.msg7}</div>
                <div id="alert-message8">{Xstate.login.register.msg8}</div>
                <div id="alert-message9">{Xstate.login.register.msg9}</div>
              </div>
            </div>

            <div className="green-line bold" />
            <span id="Google">
              <h4> {savedText.login.register_with} </h4>
              <img
                src={PictureGoogle}
                id="googleRegisterButton"
                className="btn btn-content1 social_login"
                onClick={event => {
                  this.clickGoogle(event)
                }}
              />
              <span style={{ display: "none" }}>
                <GoogleLogin
                  clientId="1018078836675-5sm8qcsdhepuktkasi56r36ud8cefedp.apps.googleusercontent.com"
                  spanText="Google"
                  onSuccess={this.registerGoogle}
                  onFailure={this.registerGoogle}
                  cookiePolicy={"single_host_origin"}
                  className="google"
                />
              </span>
              <div className="green-line bold" />
            </span>
            <h4> Register by Email </h4>
            <div className="row">
              <div className="left col-sm-12 bold">Email:</div>
              <div className="left col-sm-12">
                <input
                  className="input-login"
                  type="text"
                  id="inputEmail"
                  name="inputEmail"
                  value={Xstate.login.register.inputEmail}
                  title="email required: please input a valid email address."
                  pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$"
                  onChange={event => {
                    ClearAccountMsg("register")
                    Xstate.login.register.inputEmail = event.target.value
                  }}
                  required
                />
              </div>
              <div className="font-small col-sm-12">&nbsp;</div>
              <div className="left col-sm-12 bold">Password:</div>
              <div className="left col-sm-12">
                <input
                  className="input-login"
                  autoComplete="password"
                  type="password"
                  id="inputPassword"
                  name="inputPassword"
                  value={Xstate.login.register.inputPassword}
                  onChange={event => {
                    ClearAccountMsg("register")
                    Xstate.login.register.inputPassword = event.target.value
                    tquery("#updateReact").click()
                  }}
                  required
                />
              </div>
              <div className="font-small col-sm-12">&nbsp;</div>
              <div className="left col-sm-12 bold">Confirm Password:</div>
              <div className="left col-sm-12">
                <input
                  className="input-login"
                  autoComplete="password2"
                  type="password"
                  id="inputPassword2"
                  name="inputPassword2"
                  value={Xstate.login.register.inputPassword2}
                  onChange={event => {
                    ClearAccountMsg("register")
                    Xstate.login.register.inputPassword2 = event.target.value
                    tquery("#updateReact").click()
                  }}
                />
              </div>
            </div>
            <br />
            <button id="signin" className="btn btn-content1" data-loading-text="Loading...">
              Register By Email
            </button>
          </form>
          <br />
          &nbsp;
        </>,
      )
    }
    if (Xstate.modal.status === "registersuccess") {
      return modalTextNoButton(
        <>
          <h2> Register </h2>
          <br />
          <div id="success-message">{Xstate.login.register.msg0}</div>
          <div id="success-message">{Xstate.login.register.msg1}</div>
          <div id="success-message">{Xstate.login.register.msg2}</div>
          <div id="success-message">{Xstate.login.register.msg3}</div>
          <div id="success-message">{Xstate.login.register.msg4}</div>
          <div id="success-message">{Xstate.login.register.msg5}</div>
          <div id="success-message">{Xstate.login.register.msg6}</div>
          <div id="success-message">{Xstate.login.register.msg7}</div>
          <div id="success-message">{Xstate.login.register.msg8}</div>
          <div id="success-message">{Xstate.login.register.msg9}</div>
          <br />
          <button
            id="signin"
            className="btn btn-content1"
            onClick={event => {
              LoginModal()
            }}
          >
            Login
          </button>
        </>,
      )
    }
    if (Xstate.modal.status === "forgotpswd") {
      return modalTextNoButton(
        <>
          <h2> Reset your password </h2>
          <form
            id="login_form"
            onSubmit={event => {
              this.ResetPasswordFetch(event)
            }}
          >
            <b> Email: </b>
            <br />
            <input
              className="input-login"
              type="text"
              id="inputResetEmail"
              name="inputResetEmail"
              title="email required: please input a valid email address."
              pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$"
              onChange={event => {
                ClearAccountMsg("forgotpswd")
              }}
              required
            />
            <br />
            <div id="alert-message0">{Xstate.login.resetpswd.msg}</div>
            <br />
            <button id="resetpassword" type="submit" className="btn btn-content1" data-loading-text="Loading...">
              {savedText.login.reset_password}
            </button>
            <br />
            <button
              className="btn btn-content4"
              onClick={event => {
                LoginModal()
              }}
            >
              Login
            </button>
            <br />
            <br />
          </form>
        </>,
      )
    }
    if (Xstate.modal.status === "loggedin") {
      tquery("#loginspan").click()
    }
    if (Xstate.modal.status === "changepswd") {
      if (Xstate.login.pswd.inputOldPassword === undefined) {
        Xstate.login.pswd.inputOldPassword = ""
      }
      if (Xstate.login.pswd.inputNewPassword === undefined) {
        Xstate.login.pswd.inputNewPassword = ""
      }
      if (Xstate.login.pswd.inputNewPassword2 === undefined) {
        Xstate.login.pswd.inputNewPassword2 = ""
      }
      return modalTextNoButton(
        <>
          <h2> Change Password </h2>
          <br />
          <form
            onSubmit={event => {
              event.preventDefault()
            }}
          >
            <div className="row">
              <div className="left col-sm-12 bold">Current Password: </div>
              <div className="left col-sm-12">
                <input
                  className="input-login"
                  type="password"
                  id="inputOldPassword"
                  name="inputOldPassword"
                  value={Xstate.login.pswd.inputOldPassword}
                  autoComplete="on"
                  onChange={event => {
                    Xstate.login.pswd.inputOldPassword = event.target.value
                    tquery("#updateReact").click()
                  }}
                />
              </div>
              <div className="left col-sm-12 bold">New Password: </div>
              <div className="left col-sm-12">
                <input
                  className="input-login"
                  type="password"
                  id="inputNewPassword"
                  name="inputNewPassword"
                  autoComplete="off"
                  value={Xstate.login.pswd.inputNewPassword}
                  onChange={event => {
                    Xstate.login.pswd.inputNewPassword = event.target.value
                    tquery("#updateReact").click()
                  }}
                />
              </div>
              <div className="left col-sm-12 bold">Confirm New Password: </div>
              <div className="left  col-sm-12">
                <input
                  className="input-login"
                  type="password"
                  id="inputNewPassword2"
                  name="inputNewPassword2"
                  autoComplete="off"
                  value={Xstate.login.pswd.inputNewPassword2}
                  onChange={event => {
                    Xstate.login.pswd.inputNewPassword2 = event.target.value
                    tquery("#updateReact").click()
                  }}
                />
              </div>
            </div>
            <table className="table-center left">
              <tbody>
                <tr>
                  <td className="td-input">
                    <b> Confirm New Password: </b>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </form>
          <div className="center 100p">
            <div style={{ textAlign: "left", display: "inline-block" }}>
              <div id="alert-message0">{Xstate.login.pswd.msg0}</div>
              <div id="alert-message1">{Xstate.login.pswd.msg1}</div>
              <div id="alert-message2">{Xstate.login.pswd.msg2}</div>
              <div id="alert-message3">{Xstate.login.pswd.msg3}</div>
              <div id="alert-message4">{Xstate.login.pswd.msg4}</div>
              <div id="alert-message5">{Xstate.login.pswd.msg5}</div>
              <div id="alert-message6">{Xstate.login.pswd.msg6}</div>
              <div id="alert-message7">{Xstate.login.pswd.msg7}</div>
            </div>
          </div>
          <br />
          <button
            id="changepswd"
            type="submit"
            className="btn btn-content1"
            onClick={() => {
              ChangePassword()
            }}
          >
            Change password
          </button>
        </>,
      )
    }
    if (Xstate.modal.status === "changeUserName") {
      return modalTextNoButton(
        <>
          <h2> Change Username </h2>
          <br />
          <br />
          <div className="row">
            <div className="col-lg-12">
              <b>Current Username:</b>&nbsp;{Xstate.login.username}
              {savedText.oneLine}
            </div>
            <div className="col-sm-12">
              <b>New Username:</b>
            </div>
            <div className="col-sm-12">
              <input
                className="input-login"
                type="text"
                id="inputNewUsername"
                name="inputNewUsername"
                value={Xstate.login.newusername}
                onChange={event => {
                  Xstate.login.errorMessage0 = ""
                  Xstate.login.newusername = event.target.value
                  tquery("#updateReact").click()
                }}
              />
            </div>
          </div>

          <br />
          <div id="alert-message0">{Xstate.login.errorMessage0}</div>
          <br />
          <button
            id="changeusername"
            type="submit"
            className="btn btn-content1"
            onClick={() => {
              ChangeUsernameSubmit()
            }}
          >
            Change Username
          </button>
        </>,
      )
    }
    //>>>>> TH: For change a username, there should be errors.
    //What if the user selects a username already taken?

    if (Xstate.modal.status === "changepswdsuccess") {
      return modalTextNoButton(
        <>
          <div id="success-message">{Xstate.login.pswd.msg}</div>
        </>,
      )
    }
    if (Xstate.modal.status === "reactivation") {
      return modalTextNoButton(
        <>
          <div id="success-message">{Xstate.login.msg}</div>
          <br />
          <button
            id="signin"
            className="btn btn-content1"
            onClick={event => {
              LoginModal()
            }}
          >
            {savedText.venues.back_to_log_in}
          </button>
        </>,
      )
    }
    return <span />
  }
}
