import { getUserName } from "./League"
import { Xstate } from "./Shared"

export const hyperlinks = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const keys = urlParams.keys()
  const values = urlParams.values()
  const entries = urlParams.entries()

  //ONLY RUN ONCE
  if (Xstate.hyperlinksFollowed === undefined) {
    Xstate.hyperlinksFollowed = 1

    //SPECIFIC EVENT
    if (urlParams.get("event_id") !== undefined) {
      if (urlParams.get("event_id") !== null) {
        Xstate.club.navigationView = "events"
        Xstate.club.eventSingle = 1
        Xstate.view.clubEvent = urlParams.get("event_id") + ""
      }
    }

    //SPECIFIC USER (LET THEM MARK ATTENDANCE WITHOUT LOGGING IN)
    //timestamp is to make it harder to hack?
    //eg __A29Q2121
    if (urlParams.get("event_id") !== undefined) {
      if (urlParams.get("event_id") !== null) {
        if (urlParams.get("timestamp") !== undefined) {
          if (urlParams.get("timestamp") !== null) {
            Xstate.club.navigationView = "events"
            let codedID = urlParams.get("timestamp")
            codedID = codedID.substring(2, 100)
            codedID = codedID.substring(0, codedID.indexOf("Q"))
            Xstate.club.tempUser = { userid: codedID }
          }
        }
      }
    }
  }
}
