import React, { Component } from "react"
import { Xstate } from "../functions/Shared"
import { tquery } from "../app/ResponsiveLayout-20190729"
import { fetchLink } from "../fetchLink"
import { modalOuter } from "../app/AppModal"
import { savedText } from "../SavedText"

export class ModalUsers extends Component {
  render() {
    //Defaults
    if (Xstate.club.addUser === undefined) Xstate.club.addUser = {}
    if (Xstate.club.addUser.message === undefined) Xstate.club.addUser.message = <span />
    if (Xstate.club.addUser.team_type === undefined) Xstate.club.addUser.team_type = "view"
    if (Xstate.club.addUser.team_id === undefined) Xstate.club.addUser.team_id = "none"
    if (Xstate.club.addUser.league_type === undefined) Xstate.club.addUser.league_type = "view"
    if (Xstate.club.details.dbLeagueGeneral !== undefined)
      Xstate.club.addUser.league_id = Xstate.club.details.dbLeagueGeneral[0].league_id
    //end of defaults

    if (Xstate.modal.status === "addusers-leagueFound") {
      //CHECK IF USER EXISTS IN THE LEAGUE
      let newUserCheck = true
      Xstate.club.details.dbLeagueUsers.forEach(eachUser => {
        if (eachUser.username === Xstate.club.addUser.username) {
          newUserCheck = false
        }
      })
      if (!newUserCheck) {
        Xstate.modal.status = "league_user_already_exists"
        tquery("#updateReact").click()
        return 1
      }
      Xstate.modal.status = "LeagueInvite"

      tquery("#updateReact").click()
    }
    if (Xstate.modal.status === "addusers-eventFound") {
      Xstate.message.to = [Xstate.club.addUser.username]
      Xstate.message.url = "https://connactiveclub.com/club-event-" + Xstate.club.details.dbLeagueGeneral[0].league_id
      Xstate.message.urlText = "Go to Club"
      Xstate.JSX.msgIntroduction = "You have received an invitation from "
      Xstate.JSX.msgIntroduction += Xstate.login.username
      Xstate.JSX.msgIntroduction += " to join their league on ConnactiveClub.com:"
      Xstate.JSX.msgIntroduction += Xstate.club.details.dbLeagueGeneral[0].league_name + "."
      Xstate.JSX.msgConclusion = "Click below to join"

      if (Xstate.club.userType === "league admin") {
        if (Xstate.club.details.dbLeagueGeneral[0].private !== "public") {
          Xstate.JSX.msgConclusion =
            "Click below to join.  Here is the league password <b>" +
            Xstate.club.details.dbLeagueGeneral[0].password +
            "<b/> ."
        }
      }
      Xstate.modal.status = "message"
      Xstate.modal.fxYes = undefined
      setTimeout(() => {
        tquery("#updateReact").click()
      }, 1000)
      return <span />
    }
    if (Xstate.modal.status.substr(0, 8) === "addusers") {
      let title = "Invite Member"
      if (Xstate.modal.status === "addusers-league") {
        title = "Select Users"
      }
      return modalOuter(
        <span>
          <h2>
            <i className="borderFA far fa-clipboard" /> {Xstate.club.details.dbLeagueGeneral[0].league_name} <br />{" "}
            {title}
          </h2>
          <div style={{ textAlign: "center", width: "100%", display: "table" }}>
            <div className="table-center left">
              <b> Search by username: </b>
              <br />
              <input
                id="inputUsername"
                type="text"
                className="input input-large"
                value={Xstate.club.addUser.username}
                onChange={event => {
                  Xstate.club.addUser.username = event.target.value
                  Xstate.modal.status = "addusers-league"
                  tquery("#updateReact").click()
                }}
              />
              <button
                id="searchUsername"
                className="btn btn-content1 btn-small fas fa-search"
                onClick={() => {
                  //first check if the user already exists in the league
                  let newUserCheck = true
                  Xstate.club.details.dbLeagueUsers.forEach(eachUser => {
                    if (eachUser.username === Xstate.club.addUser.username) {
                      newUserCheck = false
                    }
                  })
                  if (!newUserCheck) {
                    Xstate.modal.status = "league_user_already_exists"
                    tquery("#updateReact").click()
                  } else {
                    let formData = new FormData()
                    formData.append("section", "league")
                    formData.append("request", "findUserByUsername")
                    formData.append("details", Xstate.club.addUser.username)
                    if (Xstate.testing === "on") {
                      console.log("FETCH")
                      console.log("section: league")
                      console.log("request: findUserByUsername")
                      console.log("details:" + Xstate.club.addUser.username)
                    }
                    fetch(fetchLink, {
                      credentials: "include",
                      method: "POST",
                      body: formData,
                    })
                      .then(response => {
                        return response.text()
                      })
                      .then(data => {
                        let result = data.trim()
                        if (result === "fail") {
                          Xstate.club.addUser.message = (
                            <span>
                              Username was not found in our database. Please try again.
                              <br />
                              <br />
                              Alternatively, you can search by email and/or invite new users by email.
                              <br />
                              <br />
                            </span>
                          )
                          Xstate.modal.status = "addusers-league"

                          tquery("#updateReact").click()
                        } else {
                          Xstate.club.addUser.username = data.trim()
                          Xstate.message.subject = ""
                          Xstate.modal.status = "LeagueInvite"
                          tquery("#updateReact").click()
                        }
                      })
                  }
                }}
              />
              {savedText.oneLine}
              <b>Search by email address:</b> <br />
              <input
                id="inputEmail"
                type="text"
                className="input input-large"
                value={Xstate.club.addUser.useremail}
                onChange={event => {
                  Xstate.club.addUser.useremail = event.target.value
                  Xstate.modal.status = "addusers-league"
                  tquery("#updateReact").click()
                }}
              />
              <button
                id="searchEmail"
                className="btn btn-content1 btn-small fas fa-search"
                onClick={() => {
                  //valid email
                  var pattern = /\S+@\S+\.\S+/
                  Xstate.club.addUser.useremail = Xstate.club.addUser.useremail.trim().toLowerCase()
                  if (pattern.test(Xstate.club.addUser.useremail)) {
                    let formData = new FormData()
                    formData.append("section", "league")
                    formData.append("request", "findUserByEmail")
                    formData.append("details", Xstate.club.addUser.useremail)
                    if (Xstate.testing === "on") {
                      console.log("FETCH")
                      console.log("section: league")
                      console.log("request: findUserByEmail")
                      console.log("details:" + Xstate.club.addUser.useremail)
                    }
                    fetch(fetchLink, {
                      credentials: "include",
                      method: "POST",
                      body: formData,
                    })
                      .then(response => {
                        return response.text()
                      })
                      .then(data => {
                        let result = data.trim().toLowerCase()
                        if (result === "fail") {
                          Xstate.club.addUser.message = (
                            <span>
                              Member (email) was not found in our database. Please try again or invite your friend to
                              join ConnactiveClub.com.
                              <br />
                              <br />
                              <button
                                id="sendInviteButton"
                                className="btn btn-content1"
                                onClick={() => {
                                  Xstate.club.addUser.username = Xstate.club.addUser.useremail
                                  Xstate.message.subject = ""
                                  Xstate.modal.status = "LeagueInvite"
                                  tquery("#updateReact").click()
                                }}
                              >
                                Invite to ConnactiveClub.com
                              </button>
                            </span>
                          )
                          Xstate.modal.status = "addusers-league"

                          tquery("#updateReact").click()
                        } else {
                          Xstate.club.addUser.username = data.trim()
                          Xstate.message.subject = ""
                          Xstate.modal.status = "LeagueInvite"
                          tquery("#updateReact").click()
                        }
                      })
                  }
                  //invalid email
                  else {
                    Xstate.club.addUser.message = (
                      <span>
                        That email is not valid.
                        <br />
                        <br />
                        Please enter a valid email address or search by username.
                      </span>
                    )
                    Xstate.modal.status = "addusers-league"

                    tquery("#updateReact").click()
                  }
                }}
              />
              {savedText.oneLine}
            </div>
          </div>
          <div className="red-font" id="addUserMessage">
            {Xstate.club.addUser.message}
            {savedText.oneLine}
          </div>
        </span>,
      )
    }
    if (Xstate.modal.status === "LeagueInvite") {
      Xstate.message.to = [Xstate.club.addUser.username]
      Xstate.message.url = "https://connactiveclub.com/club-join-" + Xstate.club.details.dbLeagueGeneral[0].league_id
      Xstate.message.urlText = "Go to Club"
      Xstate.JSX.msgIntroduction = "You have received an invitation to join ConnactiveClub.com from "
      Xstate.JSX.msgIntroduction += Xstate.login.username
      Xstate.JSX.msgIntroduction += ". ConnactiveClub.com is being used to manage the club: "
      Xstate.JSX.msgIntroduction += Xstate.club.details.dbLeagueGeneral[0].league_name + "."
      Xstate.JSX.msgConclusion = "Click below to join"

      if (Xstate.club.userType === "league admin") {
        if (Xstate.club.details.dbLeagueGeneral[0].private !== "public") {
          Xstate.JSX.msgConclusion =
            "Click below to join. Here is the league password <b>" +
            Xstate.club.details.dbLeagueGeneral[0].password +
            "<b/> ."
        }
      }

      Xstate.modal.status = "message"
      Xstate.modal.fxYes = undefined
      setTimeout(() => {
        tquery("#updateReact").click()
      }, 1000)
    }
    return <span />
  }
}
