import React, { Component } from "react"
//import {league} from "../functions/League"
import { tquery } from "../app/ResponsiveLayout-20190729"
import { modalOuter } from "../app/AppModal"
//import {fetchLink, testing} from "../fetchLink";
//import { savedText } from '../SavedText';

import { sportsList, headerSportsList, SportsListOptions, Xstate } from "../functions/Shared"

export class SportandLocationList extends Component {
  render() {
    let resultJSX = ""
    let tableJSX = []
    let tCount = 11230841237591
    let selectAllButton = <span />

    tableJSX.push(
      <tr key={tCount++}>
        <td className="td-bold td-left"> {headerSportsList[0]} </td>
        <td className="td-bold td-left"> {headerSportsList[1]} </td>
        <td className="td-bold td-left hidden >>>>>>"> {headerSportsList[2]} </td>
        <td className="td-bold td-left hidden >>>>>>"> {headerSportsList[3]} </td>
      </tr>,
    )

    let loopfunction = sport => {
      if (Xstate.sportVariable === "Xstate") {
        Xstate.sport = sport
        Xstate.subLandingPage = 0
        Xstate.modal.status = "none"
        Xstate.posts.filters.advanced = undefined
        tquery("#updateReact").click()
        Xstate.posts.filters.refresh = 1
      } else if (Xstate.sportVariable === "Default") {
        Xstate.login.default_sport_temp = sport
        tquery("#updateReact").click()
        Xstate.subLandingPage = 0
        Xstate.modal.status = "none"
        Xstate.posts.filters.advanced = undefined
        tquery("#updateReact").click()
      }
    }

    for (let i = 0; i < sportsList[0].length; i++) {
      tableJSX.push(
        <tr key={tCount++}>
          <td
            className="td-padding link linkmodal left"
            id={sportsList[0][i]}
            onClick={() => loopfunction(sportsList[0][i])}
          >
            {sportsList[0][i]}
          </td>
          <td
            className="td-padding link linkmodal  left"
            id={sportsList[1][i]}
            onClick={() => loopfunction(sportsList[1][i])}
          >
            {sportsList[1][i]}
          </td>
          <td
            className="td-padding link linkmodal left"
            id={sportsList[2][i]}
            onClick={() => loopfunction(sportsList[2][i])}
          >
            {sportsList[2][i]}
          </td>
          <td
            className="td-padding link linkmodal hidden >>>>>>"
            id={sportsList[3][i]}
            onClick={() => loopfunction(sportsList[3][i])}
          >
            {sportsList[3][i]}
          </td>
        </tr>,
      )
    }
    resultJSX = (
      <table className="p100">
        <tbody>{tableJSX}</tbody>
      </table>
    )

    if (Xstate.modal.status === "SportandLocationList") {
      let sportselect = (
        <span className="select-large">
          <div style={{ textAlign: "center", width: "100%", display: "table" }}>
            <div className="table-center left">{resultJSX}</div>
          </div>
        </span>
      )

      if (Xstate.sportVariable === "Xstate") {
        selectAllButton = (
          <div>
            <br />
            <button
              className="btn btn-content1"
              id="AllSportsButton"
              onClick={() => {
                Xstate.sport = "All Sports"
                Xstate.modal.status = "none"
                Xstate.subLandingPage = 0
                Xstate.posts.filters.refresh = 1
                Xstate.posts.filters.advanced = undefined
                tquery("#updateReact").click()
              }}
            >
              All Sports
            </button>
          </div>
        )
      }

      if (document.body.clientWidth < 1000) {
        selectAllButton = <span />
        let thisValue = Xstate.login.default_sport_temp
        if (Xstate.sportVariable === "Xstate") thisValue = Xstate.sport
        sportselect = (
          <span className="select-small">
            <div className="center">
              <div style={{ width: "100%", height: "300px" }}>
                <select
                  id="modalSportSelect"
                  className="input input-large td-middle"
                  value={thisValue}
                  onChange={event => {
                    if (Xstate.sportVariable === "Xstate") Xstate.sport = event.target.value
                    else Xstate.login.default_sport_temp = event.target.value
                    tquery("#updateReact").click()
                  }}
                >
                  {SportsListOptions("modal")}
                </select>
                <br />
                <br />
                <button
                  id="continueButton"
                  className="btn-content1"
                  onClick={event => {
                    if (thisValue === "none") thisValue = "All Sports"
                    Xstate.modal.status = "none"
                    Xstate.login.sportmessage = ""
                    Xstate.posts.filters.refresh = 1
                    tquery("#updateReact").click()
                  }}
                >
                  Continue
                </button>
                <br />
                <br />
                <br />
                <div id="alert-message0">{Xstate.login.sportmessage}</div>
              </div>
            </div>
          </span>
        )
      }

      return modalOuter(
        <span>
          <h2> Select a Sport </h2>
          {selectAllButton}
          <br />
          {sportselect}
        </span>,
        "nowidth",
      )
    }
    return <span />
  }
}
