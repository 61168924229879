import React, { Component, lazy, Suspense } from "react"
import { Route, Switch } from "react-router-dom"

const AboutUs = lazy(() => import(".././aboutus/index"))
const Rental = lazy(() => import(".././rental/index"))
const Account = lazy(() => import(".././account/index"))
const Home = lazy(() => import(".././home/index"))
const Venues = lazy(() => import(".././venues/index"))
const PlayerSearch = lazy(() => import(".././playersearch/index"))
const MessageBoard = lazy(() => import("../posts/MessageBoard"))
const LocationRedirect = lazy(() => import("../posts/LocationRedirect"))
const Club = lazy(() => import(".././club/index"))
const Schedule = lazy(() => import(".././club/schedule"))

export class AppContent extends Component {
  render() {
    return (
      <div key="contentSection" className="contentsection">
        <div className="maincontent">
          <div className="maincontenttext">
            <div className="maincontent-inner">
              <Suspense fallback={<div> Loading...</div>}>
                <Switch>
                  <Route exact path="/" render={() => <Home />} />
                  <Route exact path="/location_*" render={() => <LocationRedirect />} />
                  <Route exact path="/rental*" render={() => <Rental />} />
                  <Route exact path="/aboutus*" render={() => <AboutUs />} />
                  <Route path="/account*" render={() => <Account parentState={this.props.parentState} />} />
                  <Route exact path="/club*" render={() => <Club parentState={this.props.parentState} />} />
                  <Route exact path="/schedule" render={() => <Schedule parentState={this.props.parentState} />} />
                  <Route path="/venues*" render={() => <Venues parentState={this.props.parentState} />} />
                  <Route path="/playersearch*" render={() => <PlayerSearch parentState={this.props.parentState} />} />
                  <Route path="/messageboard*" render={() => <MessageBoard parentState={this.props.parentState} />} />
                  <Route render={() => <Home />} />
                </Switch>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
