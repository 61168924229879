import { fetchLink } from "../fetchLink"
import { addDays, Xstate, JSXnotLoggedIn, isJSON, tDateFormat } from "../functions/Shared"
import dateformat from "dateformat"
import { tquery } from "../app/ResponsiveLayout-20190729"
import React from "react"
import { savedText } from "../SavedText"
import { LeagueSettingsLocationSelect } from "../club/LeagueSettingsLocationSelect"

export var allowAdd = () => {
  let allowAdd = 0
  if (
    Xstate.club.users.details.league_type === "league admin" ||
    Xstate.club.users.details.league_type === "edit" ||
    Xstate.club.users.details.team_type === "edit"
  ) {
    Xstate.club.details.dbLeagueDivisions.forEach(division => {
      if (
        division.roundrobinGames + division.roundrobinComplete + division.ladderGames + division.ladderComplete > 0 ||
        division.playoffGames + division.playoffComplete === 0
      )
        allowAdd = 1
    })
  }
  return allowAdd
}
export var AcceptChallenge = (league_id, challenge_id) => {
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "accept-challenge")
  formData.append("league_id", league_id)
  formData.append("challenge_id", challenge_id)
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:league")
    console.log("request:accept-challenge")
    console.log("league_id:" + league_id)
    console.log("challenge_id:" + challenge_id)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(response => {
      return response.text()
    })
    .then(data => {
      var myResponse = data.trim()
      if (myResponse.substr(myResponse.length - 7) === "success") {
        Xstate.modal.text = "Success. Make sure you send an email to set up the date and time!"
      } else {
        Xstate.modal.text = "Sorry, something went wrong. "
      }
      ClearClub(0, 0, 0, 0)
      Xstate.modal.status = "text"
      tquery("#updateReact").click()
    })
}
export var addMatchModal = () => {
  Xstate.club.game_id_temp = 0
  Xstate.club.game_edit = 1
  Xstate.club.detailsTemp.dbLeagueGames = []
  Xstate.modal.status = "leagueScores"
  tquery("#updateReact").click()
}
export var AddRecurringDates = () => {
  var daysAdd = 0
  var monthsAdd = 0
  var addDate = new Date()
  addDate.setTime(Xstate.club.recurring.firstdate.getTime())
  var addDate1 = new Date()
  addDate1.setTime(Xstate.club.recurring.firstdate.getTime())

  if (1 * Xstate.club.recurring.type === 1) daysAdd = 1
  else if (1 * Xstate.club.recurring.type === 2) daysAdd = 7
  if (1 * Xstate.club.recurring.type === 3) monthsAdd = 1

  if (addDate > 0) {
    if (1 * Xstate.club.recurring.type === 0 || 1 * Xstate.club.recurring.unlimitted === 1) {
      Xstate.club.recurring.amount = 1
      Xstate.club.detailsTemp.dbLeagueGeneral[0].days = Math.min(
        Xstate.club.detailsTemp.dbLeagueGeneral[0].days + 1,
        2000,
      )
    } else {
      Xstate.club.detailsTemp.dbLeagueGeneral[0].days = Math.min(
        Xstate.club.detailsTemp.dbLeagueGeneral[0].days + Xstate.club.recurring.amount,
        2000,
      )
    }

    for (var j = 0; j < Xstate.club.recurring.amount; j++) {
      addDate1.setTime(Xstate.club.recurring.firstdate.getTime())
      addDate1.setMonth(addDate1.getMonth() + monthsAdd * j)
      addDate1.setDate(addDate1.getDate() + daysAdd * j)
      var addDateText1 = dateformat(addDate1, "yyyymmddHHMM")
      if (1 * Xstate.club.recurring.type === 0 || 1 * Xstate.club.recurring.unlimitted === 1) {
        Xstate.club.detailsTemp.dbLeagueDates.push({
          league_id: Xstate.club.detailsTemp.dbLeagueGeneral[0].league_id,
          start: addDateText1,
          games: Xstate.club.recurring.timeslots,
          type: Xstate.club.recurring.type,
        })
      } else {
        Xstate.club.detailsTemp.dbLeagueDates.push({
          league_id: Xstate.club.detailsTemp.dbLeagueGeneral[0].league_id,
          start: addDateText1,
          games: Xstate.club.recurring.timeslots,
          type: 0,
        })
      }
      Xstate.club.detailsTemp.dbLeagueDates.sort((a, b) => {
        return a.start - b.start
      })
      for (var i = 0; i < Xstate.club.detailsTemp.dbLeagueDates.length; i++) {
        Xstate.club.detailsTemp.dbLeagueDates[i].date_id = i + 1
      }
    }
  }
}
export const ClearClub = (schedule = 1, standings = 1, navigationView = 1, allLeagues = 1) => {
  Xstate.club.details = 0
  Xstate.club.detailsTemp = 0
  Xstate.club.cashTransactions = undefined
  Xstate.club.cashView = "Summary"
  Xstate.club.modal = []
  Xstate.club.game_id = 0
  Xstate.club.tempUser = undefined
  Xstate.club.users = undefined
  Xstate.club.userType = undefined
  Xstate.club.currentEventDetails = undefined
  Xstate.club.currentAttendance = undefined
  Xstate.club.saveDashboardButtonOnOff = 0
  if (navigationView === 1) Xstate.club.navigationView = undefined
  if (standings === 1) Xstate.club.standings = undefined
  if (schedule === 1) Xstate.club.schedule = undefined
  if (allLeagues === 1) {
    Xstate.club.allLeagues = undefined
    Xstate.club.adminLeague = undefined
  }
  setTimeout(1000, () => tquery("#updateReact").click())
}
export var createNewLeague = () => {
  if (Xstate.dbStatusValue === 0) {
    /*MUST BE CONNECTED TO CREATE A NEW LEAGUE */
  } else {
    if (Xstate.club.allLeagues !== 0) {
      if (Xstate.club.allLeagues !== 1) {
        Xstate.club.details = 0
        Xstate.club.newID = 1
        //get new ID
        if (Xstate.club.rebuild === 1) {
          Xstate.club.newID = Xstate.club.league_id
          Xstate.club.newView = "summary"
        } else {
          Xstate.club.newID = Math.round(100000000 + Math.random() * 100000000)
          var matches = 1
          while (matches > 0) {
            matches = 0
            Xstate.club.newID++
            for (var i = 0; i < Xstate.club.allLeagues.length; i++) {
              if (Xstate.club.allLeagues[i].league_id === Xstate.club.newID) {
                matches++
              }
            }
          }
        }

        updateLeague(Xstate.club.newID)
        Xstate.club.newView = "start"
        if (Xstate.club.rebuild === 1) {
          Xstate.club.newView = "summary"
        }
        return 1
      }
    }
  }
  return 0
}
export var BuildSchedule = () => {
  //No need to resend old games
  Xstate.club.detailsTemp.dbLeagueGames = []

  if (Xstate.club.detailsTemp.error !== 0) {
    Xstate.modal.text = "Schedule contains errors.  Please fix before continuing"
    Xstate.modal.status = "text"
    tquery("#updateReact").click()
    return 1
  }

  Xstate.modal.text = (
    <span>
      <b>Building Schedule.</b>
      {savedText.oneLine} Please give us a minute. Grab a cup of a coffee or throw on some Justin Bieber. That is more
      fun than building the schedule yourself.
    </span>
  )
  if (Xstate.club.totalCost !== undefined && 1 * Xstate.club.totalCost > 0) {
    Xstate.modal.text = (
      <span>
        <b>Building Schedule.</b>
        {savedText.oneLine} Let us do the hard work while you enjoy the action. Since your schedule is more complex than
        standard; you will require to pay the cost in order to unlock the schedule.
        {savedText.oneLine}
        Please make a payment using our external payment provider Ko-Fi. We will receive notifiation and unlock your
        schedule within 24 hours.
        {savedText.oneLine}
        Amount = <b>${Xstate.club.totalCost}</b>
        <br />
        League ID = <b>{Xstate.club.id}</b>
        {savedText.oneLine}
        <button
          className="btn btn-header fa fa-coffee"
          title="support connactive club - buy us a coffee"
          onClick={() => {
            window.open("https://ko-fi.com/connactiveclub/shop")
            Xstate.club.scheduleView = undefined
            tquery("#updateReact").click()
          }}
        >
          KO-FI
        </button>
      </span>
    )
  }
  Xstate.modal.status = "text-nobutton"
  tquery("#updateReact").click()

  Xstate.club.detailsTemp.dbLeagueDates.forEach(date => {
    date.dateString = <tr />
  })

  Xstate.club.detailsTemp.dbLeagueDivisions.forEach(division => {
    division.playoffType = 1 * division.playoffType
  })

  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "build")
  formData.append("league_id", Xstate.club.detailsTemp.dbLeagueGeneral[0].league_id)
  formData.append("details", JSON.stringify(Xstate.club.detailsTemp))
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:league")
    console.log("request:build")
    console.log("league_id:" + Xstate.club.detailsTemp.dbLeagueGeneral[0].league_id)
    console.log("details:" + JSON.stringify(Xstate.club.detailsTemp))
  }

  //FETCH
  setTimeout(() => {
    fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
      .then(response => {
        Xstate.club.id = 1 * Xstate.club.detailsTemp.dbLeagueGeneral[0].league_id

        ClearClub()
        return response.text()
      })
      .then(response => {
        Xstate.club.navigationView = undefined
        Xstate.club.page = "home"
        Xstate.club.newID = 0
        Xstate.club.newStatus = 0
        Xstate.club.navigationView = "dashboard"
        ClearClub(0, 0, 0, 0)
        if (response.trim().substr(0, 9) === "thank you") {
          Xstate.modal.status = "none"
          Xstate.club.scheduleView = ""
          tquery("#updateReact").click()
        } else {
          Xstate.modal.status = "none"
          document.querySelector("#club-home").click()
        }
      })
  }, 3000)
}
export var BuildScheduleExcel = () => {
  //No need to resend old games

  Xstate.modal.text = (
    <span>
      <b>Building Schedule.</b>
      {savedText.oneLine} Please give us a minute. Grab a cup of a coffee or throw on some Justin Bieber. That is more
      fun than building the schedule yourself.
    </span>
  )
  Xstate.modal.status = "text-nobutton"
  tquery("#updateReact").click()

  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "build-excel")
  formData.append("league_id", Xstate.club.detailsTemp.dbLeagueGeneral[0].league_id)
  formData.append("file", tquery("#scheduleXLSX").files[0], "schedule.xlsx")
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:league")
    console.log("request:build-excel")
    console.log("league_id:" + Xstate.club.detailsTemp.dbLeagueGeneral[0].league_id)
    console.log("details:" + JSON.stringify(Xstate.club.detailsTemp))
  }

  //FETCH
  setTimeout(() => {
    fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
      .then(response => {
        Xstate.club.id = 1 * Xstate.club.detailsTemp.dbLeagueGeneral[0].league_id

        ClearClub()
        return response.text()
      })
      .then(response => {
        Xstate.club.navigationView = undefined
        Xstate.club.page = "home"
        Xstate.club.newID = 0
        Xstate.club.newStatus = 0
        Xstate.club.navigationView = "dashboard"
        ClearClub(0, 0, 0, 0)
        if (response.trim().substr(0, 9) === "thank you") {
          Xstate.modal.status = "none"
          Xstate.club.scheduleView = ""
          tquery("#updateReact").click()
        } else {
          Xstate.modal.status = "none"
          document.querySelector("#club-home").click()
        }
      })
  }, 3000)
}
export var CreateClub = thisLeague => {
  //Special Rule for scheduleOnly
  if (Xstate.club.scheduleOnly) thisLeague.flag_schedule = 1

  //Create the Users Profile
  const dbLeagueUsers = [
    {
      league_id: thisLeague.league_id,
      team_id: "none",
      userid: Xstate.login.userid,
      username: Xstate.login.username,
      league_type: "league admin",
      team_lock: 0,
      active: 1,
      team_type: "view",
    },
  ]

  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "update")
  formData.append("league_id", thisLeague.league_id)
  formData.append(
    "details",
    JSON.stringify({
      dbLeagueGeneral: [thisLeague],
      dbLeagueUsers: dbLeagueUsers,
    }),
  )
  Xstate.club.id = 1 * thisLeague.league_id
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:league")
    console.log("request:update")
    console.log("league_id:" + Xstate.club.details.dbLeagueGeneral[0].league_id)
    console.log(
      "details:" +
        JSON.stringify({
          dbLeagueGeneral: [thisLeague],
          dbLeagueUsers: dbLeagueUsers,
        }),
    )
  }
  Xstate.modal.text = (
    <span>
      <b> Club started </b> <br />
      <br /> Your club has been created. Go to the <b>Settings</b> to finish the setup.
    </span>
  )

  tquery("#updateReact").click()
  Xstate.modal.status = "text"

  tquery("#updateReact").click()
  return fetch(fetchLink, {
    credentials: "include",
    method: "POST",
    body: formData,
  })
    .then(response => {
      return response.text()
    })
    .then(response => {
      ClearClub(0, 0, 0, 0)
      document.querySelector("#club-dashboard").click()
      for (let i = 1; i <= 10; i++) {
        setTimeout(() => {
          document.querySelector("#club-dashboard").click()
        }, i * 1000)
      }
      if (Xstate.club.scheduleOnly) {
        Xstate.club.scheduleView = "schedule"
        Xstate.club.scheduleOnly = false
      }
    })
}
export var CreateClubEvent = thisEventID => {
  Xstate.view.clubEvent = 1
  Xstate.club.currentEventDetails = undefined
  Xstate.club.details.dbLeagueEvents.forEach(event => {
    if (1 * event.event_id >= Xstate.view.clubEvent) {
      Xstate.view.clubEvent = 1 * event.event_id + 1
    }
  })
  if (thisEventID === 0) {
    Xstate.club.detailsTemp.dbLeagueEvents = [
      {
        title: "",
        details: "",
        event_id: Xstate.view.clubEvent,
        league_id: Xstate.club.details.dbLeagueGeneral[0].league_id,
        allday: 0,
        tracking: "members",
        venue_id: 0,
        venue_name: "",
        max_attendance: 999,
        min_attendance: 0,
      },
    ]
  } else {
    let thisEvent = Xstate.club.details.dbLeagueEvents.filter(x => {
      return 1 * x.event_id === 1 * thisEventID
    })
    thisEvent[0].event_id = Xstate.view.clubEvent
    Xstate.club.detailsTemp.dbLeagueEvents = thisEvent
  }
  Xstate.club.eventsView = "NewEvent"
  tquery("#updateReact").click()
}
export var CreateClubMessage = subject => {
  let message_id = "new"
  let title = ""
  let title1 = "New Message"
  if (subject !== 0) {
    title = subject
    title1 = subject
  }
  Xstate.club.messageDetails = {
    message_id: message_id,
    status: "edit",
    deleted: 0,
    title: title,
    title1: title1,
    datetime: dateformat(new Date(), "yyyymmddHHMM"),
    text: "",
    user_id: Xstate.login.userid,
    username: Xstate.login.username,
  }
  Xstate.modal.status = "league-message"
  Xstate.modal.fxYes = undefined
  tquery("#updateReact").click()
}
export var DeleteChallenge = (league_id, challenge_id) => {
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "delete-challenge")
  formData.append("league_id", league_id)
  formData.append("challenge_id", challenge_id)
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:league")
    console.log("request:delete-challenge")
    console.log("league_id:" + league_id)
    console.log("challenge_id:" + challenge_id)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(response => {
      return response.text()
    })
    .then(data => {
      var myResponse = data.trim()
      Xstate.modal.text =
        myResponse.substr(myResponse.length - 7) === "success" ? "Success." : "Sorry, you cannot delete this challenge."
      ClearClub(0, 0, 0, 0)
      Xstate.modal.status = "text"
      tquery("#updateReact").click()
    })
}
export var DeleteDate = date_id => {
  if (date_id > 0) {
    for (var i = date_id; i < Xstate.club.detailsTemp.dbLeagueDates.length; i++) {
      Xstate.club.detailsTemp.dbLeagueDates[i - 1] = Xstate.club.detailsTemp.dbLeagueDates[i]
      Xstate.club.detailsTemp.dbLeagueDates[i - 1].date_id--
    }
    Xstate.club.detailsTemp.dbLeagueDates = Xstate.club.detailsTemp.dbLeagueDates.slice(
      0,
      Xstate.club.detailsTemp.dbLeagueDates.length - 1,
    )
  } else {
    Xstate.club.detailsTemp.dbLeagueDates = []
  }
}
export var DeleteEvent = thisEventID => {
  let thisEvent = Xstate.club.details.dbLeagueEvents.filter(x => {
    return 1 * x.event_id === 1 * thisEventID
  })
  thisEvent[0].deleted = 1
  Xstate.club.detailsTemp.dbLeagueEvents = thisEvent
  Xstate.modal.text = "Saving"
  Xstate.modal.status = "text-nobutton"
  tquery("#updateReact").click()
  saveLeague({ dbLeagueEvents: Xstate.club.detailsTemp.dbLeagueEvents })
  ClearClub(0, 0, 0, 0)
  setTimeout(() => {
    Xstate.modal.status = "none"
    tquery("#updateReact").click()
  }, 1000)
}
export var DeleteLeague = league_id => {
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "delete")
  formData.append("league_id", league_id)
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:league")
    console.log("request:delete")
    console.log("league_id:" + league_id)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(response => {
      return response.text()
    })
    .then(data => {
      var myResponse = data.trim()
      if (myResponse.substr(myResponse.length - 7) === "success") {
        Xstate.modal.text = "Club has been deleted."
        Xstate.modal.status = "text"
        tquery("#updateReact").click()
      } else {
        Xstate.modal.text = "Sorry, you cannot delete this club."
      }
      Xstate.club.allLeagues = undefined
      document.querySelector("#club").click()
      Xstate.modal.status = "text"
      tquery("#updateReact").click()
    })
}
export var DeleteLeagueUser = (league_id, userid) => {
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "delete-user")
  formData.append("league_id", league_id)
  formData.append("userid", userid)
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:league")
    console.log("request:delete-user")
    console.log("league_id:" + league_id)
    console.log("userid:" + userid)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(response => {
      return response.text()
    })
    .then(data => {
      var myResponse = data.trim()
      if (myResponse.substr(myResponse.length - 7) === "success") {
        Xstate.modal.text = "Success."
      } else {
        Xstate.modal.text = "Sorry, you cannot delete this member."
      }
      ClearClub(0, 0, 0, 0)
      Xstate.modal.status = "text"
      tquery("#updateReact").click()
    })
}
export const DeleteTransaction = () => {
  Xstate.modal.status = "leagueTransactionDeleteConfirm"
  Xstate.modal.text = "This will delete the transaction. Are you sure?"
  tquery("#updateReact").click()
}
export const DeleteTransactionConfirm = () => {
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "delete transaction")
  formData.append("league_id", Xstate.club.id)
  formData.append("transaction_id", "" + Xstate.club.cashTransactions[0].transaction_id)
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section: league")
    console.log("request: delete transaction")
    console.log("league_id:" + Xstate.club.id)
    console.log("transaction_id:" + Xstate.club.cashTransactions[0].transaction_id)
  }
  ClearClub(0, 0, 0, 0)
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData }).then(response => {
    updateLeague(Xstate.club.id)
  })
}
export const DeleteAllGames = () => {
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "DeleteAllGames")
  formData.append("league_id", Xstate.club.details.dbLeagueGeneral[0].league_id)
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section: league")
    console.log("request: DeleteAllGames")
    console.log("league_id: " + Xstate.club.details.dbLeagueGeneral[0].league_id)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData }).then(response => {
    ClearClub(0, 0, 0, 0)
  })
}
export const DeleteUpcomingGames = () => {
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "DeleteUpcomingGames")
  formData.append("league_id", Xstate.club.details.dbLeagueGeneral[0].league_id)
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section: league")
    console.log("request: DeleteUpcomingGames")
    console.log("league_id: " + Xstate.club.details.dbLeagueGeneral[0].league_id)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData }).then(response => {
    ClearClub(0, 0, 0, 0)
  })
}
export const DeleteGame = (league_id, game_id) => {
  return "TO DO"
}
export const EditMatch = (allowEdit, id) => {
  Xstate.club.modal = []
  Xstate.club.game_edit = 1
  if (allowEdit !== 1) Xstate.club.game_edit = 0
  Xstate.club.game_id_temp = 1 * id
  Xstate.club.detailsTemp.dbLeagueGames = []
  Xstate.modal.status = "leagueScores"
  tquery("#updateReact").click()
}
export const filterByLocation = yesNo => {
  let tCount = 13232
  if (yesNo === "No") {
    return (
      <span key={tCount++}>
        <b>Filter by Location</b>
        {Xstate.club.noDistances}
        <div className="div-input">
          <button
            id="locationYes"
            className="btn btn-content1 btn-small"
            onClick={() => {
              Xstate.posts.filters.KM = 100
              tquery("#updateReact").click()
            }}
          >
            Yes
          </button>
          <button id="locationNo" className="btn btn-content1 btn-small active">
            No
          </button>
        </div>
      </span>
    )
  }
  let myLocation = 0
  if (!(isNaN(1 * Xstate.login.latitude) || isNaN(1 * Xstate.login.longitude))) {
    myLocation = 1
  }
  let locationSelector = <span />
  if (myLocation === 0) {
    locationSelector = (
      <table style={{ width: "fit-content" }}>
        <tbody>{LeagueSettingsLocationSelect(0)}</tbody>
      </table>
    )
  }
  if (myLocation === 1) {
    locationSelector = (
      <table style={{ width: "fit-content", margin: "auto" }}>
        <tbody>{LeagueSettingsLocationSelect(0)}</tbody>
      </table>
    )
  }
  return (
    <span key="League">
      <b>Filter by Location</b>
      <div className="div-input">
        <button id="locationYes" className="btn btn-content1 btn-small active">
          Yes
        </button>
        <button
          id="locationNo"
          className="btn btn-content1 btn-small"
          onClick={() => {
            Xstate.posts.filters.KM = 1000
            tquery("#updateReact").click()
          }}
        >
          No
        </button>
      </div>
      <div className="div-input">{locationSelector}</div>
      {savedText.halfLine}
    </span>
  )
}
export const getTeamName = id => {
  if (Xstate.club.details.dbLeagueTeams.length > 0) {
    for (let i = 0; i < Xstate.club.details.dbLeagueTeams.length; i++) {
      if (Xstate.club.details.dbLeagueTeams[i].team_id === id) return Xstate.club.details.dbLeagueTeams[i].team_name
      if (1 * Xstate.club.details.dbLeagueTeams[i].team_id === 1 * id)
        return Xstate.club.details.dbLeagueTeams[i].team_name
    }
  }
  return "Team_" + id
}
export const getTeamID = name => {
  if (name === undefined) return 0
  if (Xstate.club.details.dbLeagueTeams.length > 0) {
    for (let i = 0; i < Xstate.club.details.dbLeagueTeams.length; i++) {
      if (
        Xstate.club.details.dbLeagueTeams[i].team_name.trim().toLowerCase().replace(" ", "") ===
        name.trim().toLowerCase().replace(" ", "")
      )
        return Xstate.club.details.dbLeagueTeams[i].team_id
    }
  }
  return 0
}
export const getUserID = username => {
  if (Xstate.club.details.dbLeagueUsers.length > 0) {
    for (let i = 0; i < Xstate.club.details.dbLeagueUsers.length; i++) {
      if (Xstate.club.details.dbLeagueUsers[i].username === username) return Xstate.club.details.dbLeagueUsers[i].userid
    }
  }
  return "Other User"
}
export const getUserName = id => {
  if (Xstate.club.details.dbLeagueUsers.length > 0) {
    for (let i = 0; i < Xstate.club.details.dbLeagueUsers.length; i++) {
      if (Xstate.club.details.dbLeagueUsers[i].userid === id) return Xstate.club.details.dbLeagueUsers[i].username
      if (1 * Xstate.club.details.dbLeagueUsers[i].userid === 1 * id)
        return Xstate.club.details.dbLeagueUsers[i].username
    }
  }
  return "Other User"
}
export const getTeamAttribute = (attribute, id) => {
  if (attribute === "group_id") {
    //console.log(id)
  }
  if (Xstate.club.details.dbLeagueTeams.length > 0) {
    for (let i = 0; i < Xstate.club.details.dbLeagueTeams.length; i++) {
      if (attribute === "group_id") {
        //console.log(Xstate.club.details.dbLeagueTeams[i])
      }
      if (Xstate.club.details.dbLeagueTeams[i].team_id === id) return Xstate.club.details.dbLeagueTeams[i][attribute]
      if (1 * Xstate.club.details.dbLeagueTeams[i].team_id === 1 * id)
        return Xstate.club.details.dbLeagueTeams[i][attribute]
    }
  }
  return 0
}
export const globalPercentile = percentile => {
  if (percentile === 100) return "GOLD (first place)"
  if (percentile > 90) return "Gold (top 10%)"
  if (percentile > 80) return "Silver (top 20%)"
  if (percentile > 70) return "Bronze (top 30%)"
  if (percentile > 50) return "Above Average"
  if (percentile > 30) return "Average"
  return "Rookie"
}
export const joinLeague = thisLeague => {
  var resultJSX = []
  var tCount = 9796131

  resultJSX.push(
    <span key={tCount++}>
      <br />
      <h3>Join</h3>
    </span>,
  )
  if (Xstate.club.users.details !== undefined) {
    if (Xstate.club.users.details.league_type !== "none") {
      resultJSX.push(
        <span key={tCount++}>
          {savedText.oneLine}
          You are already in the club! Have fun.
          {savedText.oneLine}
        </span>,
      )
      return resultJSX
    }
  }

  if (Xstate.login.status === false) {
    return (
      <span>
        <div className="div-input">
          {resultJSX}
          {JSXnotLoggedIn(
            "You need an account to join this league. Please log in or create an account. It's quick and easy.",
          )}
          <br />
          <br />
        </div>
      </span>
    )
  }
  if (Xstate.club.joinUser === undefined) {
    Xstate.club.joinUser = {
      team_id: "none",
      team_type: "view",
      league_type: "view",
      password: "",
    }
  }

  let teamSelectOptions = []
  Xstate.club.details.dbLeagueTeams.forEach(thisTeam => {
    teamSelectOptions.push(
      <option key={tCount++} value={thisTeam.team_id}>
        {thisTeam.team_name}
      </option>,
    )
  })

  if (thisLeague.private === "public") {
    if (thisLeague.league_type === "ladder") {
      teamSelectOptions.push(
        <option key={tCount++} value="new">
          New team
        </option>,
      )
    }
  }

  let teamSelect = (
    <span>
      <b>Select a team: </b>
      <br />
      <select
        className="sportSelect"
        value={Xstate.club.joinUser.team_id}
        onChange={event => {
          Xstate.club.joinUser.team_id = event.target.value
          tquery("#updateReact").click()
        }}
      >
        <option value="none"> none (league) </option>
        {teamSelectOptions}
      </select>
      {savedText.oneLine}
    </span>
  )

  if (
    1 * Xstate.club.details.dbLeagueGeneral[0].flag_schedule +
      1 * Xstate.club.details.dbLeagueGeneral[0].flag_ladder ===
    0
  ) {
    teamSelect = <span />
  }

  let newTeam = <span />
  if (Xstate.club.joinUser.team_id === "new") {
    newTeam = (
      <span>
        <b> {thisLeague.participant_type} Name </b>
        <br />
        <input
          type="text"
          className="input"
          value={Xstate.club.joinUser.name}
          onChange={event => {
            Xstate.club.joinUser.name = event.target.value
            tquery("#updateReact").click()
          }}
        />
        {savedText.halfLine}
      </span>
    )
  }
  let passwordInput = <span />
  if (thisLeague.private !== "public") {
    passwordInput = (
      <span>
        <b> Password </b> (check your email)
        <br />
        <input
          type="text"
          className="input"
          value={Xstate.club.joinUser.password}
          onChange={event => {
            Xstate.club.joinUser.password = event.target.value
            tquery("#updateReact").click()
          }}
        />
        {savedText.halfLine}
      </span>
    )
  }

  let joinButton = (
    <span>
      <button
        id="joinLeagueButton"
        className="btn btn-content1"
        onClick={() => {
          Xstate.modal.text = "Working on it..."
          Xstate.modal.status = "text"
          tquery("#updateReact").click()
          let formData = new FormData()
          formData.append("section", "league")
          formData.append("request", "joinLeague")
          formData.append("league_id", thisLeague.league_id)
          formData.append("details", JSON.stringify(Xstate.club.joinUser))

          if (Xstate.testing === "on") {
            console.log("FETCH")
            console.log("section:league")
            console.log("request:joinLeague")
            console.log("league_id:" + thisLeague.league_id)
            console.log("details:" + JSON.stringify(Xstate.club.joinUser))
          }
          fetch(fetchLink, {
            credentials: "include",
            method: "POST",
            body: formData,
          })
            .then(response => {
              return response.text()
            })
            .then(data => {
              if (data.trim().substring(0, 7) === "Success") {
                ClearClub(0, 0, 0, 0)
                Xstate.club.userView = "All"
              }
              Xstate.modal.text = data.trim()
              Xstate.modal.status = "text"
              Xstate.club.allLeagues = undefined
              tquery("#updateReact").click()
            })
        }}
      >
        Join
      </button>
      {savedText.oneLine}
    </span>
  )

  resultJSX.push(
    <span key={tCount++}>
      {teamSelect}
      {newTeam}
      {passwordInput}
      {joinButton}
    </span>,
  )

  return (
    <span>
      <div className="div-input">{resultJSX}</div>
    </span>
  )
}
export const leagueGameCount = () => {
  let tempValue = 1 * Xstate.club.details.dbLeagueGeneral[0].totalGames

  Xstate.club.details.dbLeagueGeneral[0].roundrobinGames = 0
  Xstate.club.details.dbLeagueGeneral[0].roundrobinComplete = 0
  Xstate.club.details.dbLeagueGeneral[0].ladderGames = 0
  Xstate.club.details.dbLeagueGeneral[0].ladderComplete = 0
  Xstate.club.details.dbLeagueGeneral[0].playoffGames = 0
  Xstate.club.details.dbLeagueGeneral[0].playoffComplete = 0
  Xstate.club.details.dbLeagueGeneral[0].totalGames = 0

  for (let i = 0; i < Xstate.club.details.dbLeagueDivisions.length; i++) {
    Xstate.club.details.dbLeagueDivisions[i].roundrobinGames = 0
    Xstate.club.details.dbLeagueDivisions[i].roundrobinComplete = 0
    Xstate.club.details.dbLeagueDivisions[i].playoffGames = 0
    Xstate.club.details.dbLeagueDivisions[i].playoffComplete = 0
    Xstate.club.details.dbLeagueDivisions[i].ladderGames = 0
    Xstate.club.details.dbLeagueDivisions[i].ladderComplete = 0
    Xstate.club.details.dbLeagueDivisions[i].totalGames = 0

    //NUMBER OF GAMES
    for (let x = 0; x < Xstate.club.details.dbLeagueGames.length; x++) {
      let game = Xstate.club.details.dbLeagueGames[x]
      if (1 * game.division_id === 1 * Xstate.club.details.dbLeagueDivisions[i].division_id) {
        if (1 * Xstate.club.details.dbLeagueGeneral[0].ladder === 1) {
          if (game.series_id === "roundrobin") {
            if (1 * game.result === 1) Xstate.club.details.dbLeagueDivisions[i].ladderComplete++
            else Xstate.club.details.dbLeagueDivisions[i].ladderGames++
          }
        } else {
          if (game.series_id === "roundrobin") {
            if (1 * game.result === 1) Xstate.club.details.dbLeagueDivisions[i].roundrobinComplete++
            else Xstate.club.details.dbLeagueDivisions[i].roundrobinGames++
          } else {
            if (1 * game.result === 1) Xstate.club.details.dbLeagueDivisions[i].playoffComplete++
            else Xstate.club.details.dbLeagueDivisions[i].playoffGames++
          }
        }
      }
    }

    Xstate.club.details.dbLeagueDivisions[i].totalGames =
      Xstate.club.details.dbLeagueDivisions[i].playoffGames +
      Xstate.club.details.dbLeagueDivisions[i].playoffComplete +
      Xstate.club.details.dbLeagueDivisions[i].roundrobinGames +
      Xstate.club.details.dbLeagueDivisions[i].roundrobinComplete +
      Xstate.club.details.dbLeagueDivisions[i].ladderGames +
      Xstate.club.details.dbLeagueDivisions[i].ladderComplete

    Xstate.club.details.dbLeagueGeneral[0].ladderGames += Xstate.club.details.dbLeagueDivisions[i].ladderGames
    Xstate.club.details.dbLeagueGeneral[0].ladderComplete += Xstate.club.details.dbLeagueDivisions[i].ladderComplete
    Xstate.club.details.dbLeagueGeneral[0].playoffGames += Xstate.club.details.dbLeagueDivisions[i].playoffGames
    Xstate.club.details.dbLeagueGeneral[0].playoffComplete += Xstate.club.details.dbLeagueDivisions[i].playoffComplete
    Xstate.club.details.dbLeagueGeneral[0].roundrobinGames += Xstate.club.details.dbLeagueDivisions[i].roundrobinGames
    Xstate.club.details.dbLeagueGeneral[0].roundrobinComplete +=
      Xstate.club.details.dbLeagueDivisions[i].roundrobinComplete
    Xstate.club.details.dbLeagueGeneral[0].totalGames += Xstate.club.details.dbLeagueDivisions[i].totalGames
  }

  if (Xstate.club.details.dbLeagueGeneral[0].totalGames === tempValue) return 1
  document.querySelector("#updateReact").click()
}
export const leagueMax = {
  divisions: 1,
  premiumDivisions: 5,
  proDivisions: 20,
  teams: 32,
  premiumTeams: 100,
  proTeams: 10000,
  games: 200,
  premiumGames: 1000,
  proGames: 5000,
}
export const playerSearchOptions = (all = 1) => {
  let allJSX = <></>
  if (all === 1) allJSX = <option value="All">All</option>
  return (
    <>
      {allJSX}
      <option value="Player">Player</option>
      <option value="Team">Team</option>
      <option value="League">League</option>
      <option value="Coach">Coach</option>
    </>
  )
}
export const playerSearchOptions1 = (all = 1) => {
  let allJSX = <></>
  if (all === 1) allJSX = <option value="All">All</option>
  return (
    <>
      {allJSX}
      <option value="One time">One time</option>
      <option value="Season">Season</option>
    </>
  )
}
export const playerSearchAddPost = () => {
  Xstate.posts.msg = ""
  if (Xstate.posts.playerSearch.content === "" || Xstate.posts.playerSearch.title === "") {
    Xstate.posts.msg = "Please fill in subject and content"
    tquery("#updateReact").click()
    return 1
  }

  if (Xstate.posts.playerSearch.longitude === "") {
    Xstate.posts.msg = "Please select a location"
    tquery("#updateReact").click()
    return 1
  }
  //Setup Post Variables
  if (Xstate.posts.playerSearch === undefined) Xstate.posts.playerSearch = {}
  let thisPost = Xstate.posts.playerSearch
  if (1 === 1 * 1) {
    if (thisPost.title === undefined) thisPost.title = ""
    if (thisPost.content === undefined) thisPost.content = ""
    thisPost.post_id = "new"
    thisPost.user_id = Xstate.login.userid
    thisPost.user_name = Xstate.login.username
    thisPost.user_email = Xstate.login.email
    thisPost.title = Xstate.posts.playerSearch.title
    thisPost.league_type = Xstate.posts.playerSearch.league_type
    thisPost.my_type = Xstate.posts.playerSearch.my_type
    thisPost.search_type = Xstate.posts.playerSearch.search_type
    thisPost.address_line1 = ""
    thisPost.address_line2 = ""
    //thisPost.postcode = Xstate.location[0].postal_code1
    //thisPost.city = Xstate.location[0].selected
    thisPost.province = ""
    thisPost.country = ""
    //thisPost.longitude = Xstate.location[0].lat1
    //thisPost.latitude = Xstate.location[0].lng1
    thisPost.update_date = dateformat(new Date(), "yyyymmddHHMMss")
    thisPost.expiry_date = ""
    thisPost.deleted = "0"
    thisPost.expired = "0"
    thisPost.email = "0"
    tquery("#updateReact").click()
  }
  playerSearchEditPost()
}
export const playerSearchEditPost = () => {
  let thisPost = Xstate.posts.playerSearch
  Xstate.posts.playerSearch.update_date = tDateFormat(new Date(), "num-full(secs)")
  Xstate.posts.playerSearch.expiry_date = tDateFormat(addDays(new Date(), 30), "num-full(secs)")

  //Create form
  var formData = new FormData()
  formData.append("section", "posts")
  formData.append("request", "save-playersearch")
  formData.append("post_id", thisPost.post_id)
  formData.append("details", JSON.stringify(thisPost))

  //localStorage.dbPlayerSearch = 0;
  Xstate.modal.text = "Saving"
  Xstate.modal.status = "text-nobutton"
  tquery("#updateReact").click()

  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:posts")
    console.log("request:save-playersearch")
    console.log("post_id:" + thisPost.post_id)
    console.log("details:" + JSON.stringify(thisPost))
  }
  Xstate.posts.dbPlayerSearchTemp = 0
  return fetch(fetchLink, {
    credentials: "include",
    method: "POST",
    body: formData,
  }).then(response => {
    Xstate.posts.dbPlayerSearchTemp = 0
    Xstate.posts.dbPlayerSearch = 0
    setTimeout(() => {
      Xstate.modal.status = "none"
      ClearClub()
      Xstate.club.navigationView = "posts"
      tquery("#updateReact").click()
    }, 3000)
    return 1
    //localStorage.dbPlayerSearch = 0;
  })
}
export const playerSearchDeletePost = () => {
  Xstate.posts.playerSearch.deleted = 1
  playerSearchEditPost()
}
export const premiumDivisionsText = divisions => {
  let result = ""
  if (divisions > leagueMax.divisions) {
    Xstate.club.detailsTemp.premium = 1
    result = (
      <span className="fa fa-star premium pup">
        Premium Feature
        <i className="fa fa-star" />
        <span> {savedText.club.premium_divisions} </span>
      </span>
    )
  }
  if (divisions > leagueMax.premiumDivisions) {
    if (1 * divisions === Math.max(1, Xstate.club.detailsTemp.dbLeagueDivisions.length)) Xstate.club.detailsTemp.pro++

    result = (
      <span className="fa fa-star premium pup">
        Pro Feature
        <i className="fa fa-star" />
        <span> {savedText.club.pro_divisions} </span>
      </span>
    )
  }
  return result
}
export const premiumGamesText = games => {
  let result = ""
  if (games > leagueMax.games) {
    Xstate.club.detailsTemp.premium = 1
    result = (
      <span className="pup fa fa-star premium">
        Premium Feature
        <i className="fa fa-star" />
        <span> {savedText.club.premium_games} </span>
      </span>
    )
  }
  if (games > leagueMax.premiumGames) {
    Xstate.club.detailsTemp.pro++
    result = (
      <span className="pup fa fa-star premium">
        Pro Feature
        <i className="fa fa-star" />
        <span> {savedText.club.pro_games} </span>
      </span>
    )
  }
  return result
}
export const premiumTeamsText = teams => {
  let result = ""
  if (teams > leagueMax.teams) {
    Xstate.club.detailsTemp.premium = 1
    result = (
      <span className="pup fa fa-star premium">
        Premium Feature
        <i className="fa fa-star" />
        <span> {savedText.club.premium_teams} </span>
      </span>
    )
  }
  if (teams > leagueMax.premiumTeams) {
    Xstate.club.detailsTemp.pro++
    result = (
      <span className="pup fa fa-star premium">
        Pro Feature
        <i className="fa fa-star" />
        <span> {savedText.club.pro_teams} </span>
      </span>
    )
  }
  return result
}
export var saveLeague = newLeague => {
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "update")
  formData.append("league_id", Xstate.club.details.dbLeagueGeneral[0].league_id)
  formData.append("details", JSON.stringify(newLeague))
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:league")
    console.log("request:update")
    console.log("league_id:" + Xstate.club.details.dbLeagueGeneral[0].league_id)
    console.log("details:" + JSON.stringify(newLeague))
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })

  ClearClub(0, 0, 0, 0)
}
export var saveLeagueGames = () => {
  var newLeague = {}
  newLeague.dbLeagueGames = JSON.parse(JSON.stringify(Xstate.club.detailsTemp.dbLeagueGames))
  if (newLeague.dbLeagueGames[0].deleted === null) newLeague.dbLeagueGames[0].deleted = 0
  saveLeague(newLeague)
}
export var saveLeagueSettings = () => {
  //Special Rules for Password
  if (Xstate.club.detailsTemp.dbLeagueGeneral !== undefined) {
    if (
      Xstate.club.detailsTemp.dbLeagueGeneral[0].password === undefined ||
      Xstate.club.detailsTemp.dbLeagueGeneral[0].password === ""
    ) {
      Xstate.club.detailsTemp.dbLeagueGeneral[0].password = Xstate.club.detailsTemp.dbLeagueGeneral[0].league_id
    }
  }

  //Remove JSX from dbLeagueUsers
  if (Xstate.club.detailsTemp.dbleagueUsers !== undefined) {
    Xstate.club.detailsTemp.dbLeagueUsers.forEach(user => {
      user.leagueSelect = 0
      if (user.team_type === undefined) {
        user.team_type = "view"
      }
    })
  }
  Xstate.club.detailsJSX.labelError = 0
  var newLeague = JSON.parse(JSON.stringify(Xstate.club.detailsTemp))
  saveLeague(newLeague)
  ClearClub(0, 0, 0, 0)
}
export const SendLeagueChallenge = (team_id, team_id2, league_id, message) => {
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "SendLeagueChallenge")
  formData.append("league_id", Xstate.club.details.dbLeagueGeneral[0].league_id)
  formData.append("details", JSON.stringify({ team_id: team_id, team_id2: team_id2, message: message }))
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section: league")
    console.log("request: SendLeagueChallenge")
    console.log("league_id: " + Xstate.club.details.dbLeagueGeneral[0].league_id)
    console.log("details:" + JSON.stringify({ team_id: team_id, team_id2: team_id2, message: message }))
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData }).then(response => {
    ClearClub(0, 0, 0, 0)
  })
}
export const setupUser = () => {
  //EXIT EARLY
  if (Xstate.club.details === 0) return 1
  if (Xstate.club.details === 1) return 1
  if (Xstate.club.details[0] === "fail") return 1
  if (Xstate.club.users !== undefined) {
    if (Xstate.club.users.details !== undefined) return 1
  }

  //Default
  if (Xstate.club.users === undefined) Xstate.club.users = {}
  Xstate.club.users.details = {
    username: "not logged in",
    team_id: "none",
    league_type: "none",
  }

  //special case - admin
  if ("A1" === Xstate.login.userid || Xstate.club.details.dbLeagueGeneral[0].userid === Xstate.login.userid) {
    Xstate.club.users.details = {
      team_id: "none",
      league_type: "league admin",
      team_type: "view",
    }
    for (let i = 0; i < Xstate.club.details.dbLeagueUsers.length; i++) {
      if (Xstate.club.details.dbLeagueUsers[i].userid === Xstate.login.userid) {
        Xstate.club.details.dbLeagueUsers[i].league_type = "league admin"
        Xstate.club.details.dbLeagueUsers[i].deleted = 0
      }
    }
  }

  //All users

  for (let i = 0; i < Xstate.club.details.dbLeagueUsers.length; i++) {
    if (Xstate.club.details.dbLeagueUsers[i].status_id !== "deleted") {
      if (Xstate.club.details.dbLeagueUsers[i].userid === Xstate.login.userid) {
        Xstate.club.users.details = Xstate.club.details.dbLeagueUsers[i]
      }
    }
  }
}
export const updateAllLeagues = () => {
  if (Xstate.dbStatusValue === 0) {
    return "No Database" //Wait for Connection;
  }
  if (Xstate.club.allLeagues !== undefined) {
    return Xstate.club.allLeagues
  }
  Xstate.club.allLeagues = 1
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "retrieve all")
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(response => {
      return response.text()
    })
    .then(data => {
      if (isJSON(data)) {
        Xstate.club.allLeagues = JSON.parse(data)
        tquery("#updateReact").click()
      }
    })
}
export var updateAllEvents = () => {
  if (Xstate.dbStatusValue === 0) {
    return "No DB"
  }
  if (Xstate.club.allEvents !== undefined) {
    return Xstate.club.allEvents
  }

  Xstate.club.allEvents = 1
  var formData = new FormData()
  formData.append("section", "league")
  formData.append("request", "myleagueevents")
  if (Xstate.testing === "on") console.log("FETCH - league - myleagueevents")
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(response => {
      return response.text()
    })
    .then(data => {
      if (isJSON(data)) {
        Xstate.club.allEvents = JSON.parse(data)
        tquery("#updateReact").click()
      } else {
        Xstate.club.allEvents = JSON.parse('{"yes":[],"wait":[],"no":[],"":[null]}')
        tquery("#updateReact").click()
      }
    })
}
export var updateLeague = leagueID => {
  if (Xstate.dbStatusValue === 0) {
    //WAIT FOR CONNECTION
  } else {
    if (Xstate.club.details === 0) {
      Xstate.club.details = 1
      if (Xstate.dbStatusValue === "connected") {
        var formData = new FormData()
        formData.append("section", "league")
        formData.append("request", "retrieve")
        formData.append("league_id", leagueID)
        if (Xstate.testing === "on") {
          console.log("FETCH")
          console.log("section: league")
          console.log("request: retrieve")
          console.log("league_id: " + leagueID)
        }
        if (Xstate.club.newID > 0) formData.append("new", 1)
        fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
          .then(response => {
            return response.text()
          })
          .then(data => {
            if (isJSON(data)) {
              Xstate.club.details = JSON.parse(data)
              //GLOBAL LEAGUE (BUT WORKS FOR ALL): REMOVE GAMES THAT ARE FROM OTHER LEAGUES
              //(Not required because we actually want to keep these!)
              /*Xstate.club.details.dbLeagueGames = Xstate.club.details.dbLeagueGames.filter(game => {
                return 1 * game.league_id === 1 * Xstate.club.id
              })*/

              //EXCLUDE TESTING AND ADMIN FROM SUGGESTIONS
              if (Xstate.club.details.dbLeagueSuggestedMatches !== undefined) {
                Xstate.club.details.dbLeagueSuggestedMatches = Xstate.club.details.dbLeagueSuggestedMatches.filter(
                  team => {
                    if (1 * team.team1 === 0 || 1 * team.team2 === 0) return false
                    if (team.team1 === "A1" || team.team2 === "A1") return false
                    if (team.team1 === "A1004" || team.team2 === "A1004") return false
                    if (team.team1 === "A999" || team.team2 === "A999") return false
                    return true
                  },
                )
              }

              //EXTRA TABLES WITH THE USERID AS THE KEY
              Xstate.club.details.dbLeagueUsers0 = {}
              Xstate.club.details.dbLeagueUsers.forEach(user => {
                Xstate.club.details.dbLeagueUsers0[user.userid] = user
              })
              Xstate.club.details.dbLeagueTeams0 = {}
              let totalTeams = 0
              Xstate.club.details.dbLeagueTeams.forEach(team => {
                Xstate.club.details.dbLeagueTeams0[team.team_id] = team
              })
              Xstate.club.details.dbLeagueStandings.forEach(team => {
                if (1 * team.p !== 0) totalTeams += 1
              })
              Xstate.club.details.dbLeagueStandings.sort((a, b) => {
                return -1 * a.p + 1 * b.p
              })
              for (let i = 0; i < Xstate.club.details.dbLeagueStandings.length; i++) {
                let team = Xstate.club.details.dbLeagueStandings[i]
                let team1 = Xstate.club.details.dbLeagueStandings[i - 1]
                if (i === 0) team.percentile = 100
                else if (team.p === team1.p) team.percentile = 1 * team1.percentile
                else if (1 * team.p === 0) team.percentile = 0
                else team.percentile = Math.round(((totalTeams - i) / totalTeams) * 100)
              }
              Xstate.club.details.dbLeagueStandings0 = {}
              Xstate.club.details.dbLeagueStandings.forEach(team => {
                Xstate.club.details.dbLeagueStandings0[team.team_id] = team
              })

              Xstate.club.detailsTemp = JSON.parse(JSON.stringify(Xstate.club.details))
              Xstate.club.detailsJSX = {}

              setupUser()
              leagueGameCount()
              return 1
            }
          })
      }
    }
  }
  return 0
}
export var updateTeams = (newValues, labelTeams) => {
  if (newValues.length === 1) {
    let dbTeamsCount = 0
    let i = 0
    for (let j = 0; j < Xstate.club.detailsTemp.dbLeagueTeams.length; j++) {
      dbTeamsCount++
      let thisTeam = Xstate.club.detailsTemp.dbLeagueTeams[j]
      if (dbTeamsCount > newValues[i]) thisTeam.deleted = 1
    }
    for (let j = dbTeamsCount; j < newValues[i]; j++) {
      Xstate.club.detailsTemp.dbLeagueTeams.push({
        league_id: Xstate.club.details.dbLeagueGeneral[0].league_id,
        team_name: labelTeams + "_" + (j + 1),
        team_id: 1,
        ladder_initial: "unassigned",
        division_id: i,
        group_id: "unassigned",
      })
    }
    let tempResult = Xstate.club.detailsTemp.dbLeagueTeams.filter(thisTeam => thisTeam.deleted !== 1)
    i = 0
    tempResult.forEach(thisTeam => {
      i++
      thisTeam.team_id = i
    })
    Xstate.club.detailsTemp.dbLeagueTeams = tempResult
  } else {
    for (let i = 0; i < newValues.length; i++) {
      let dbTeamsCount = 0
      for (let j = 0; j < Xstate.club.detailsTemp.dbLeagueTeams.length; j++) {
        let thisTeam = Xstate.club.detailsTemp.dbLeagueTeams[j]
        if (1 * thisTeam.division_id === i) {
          dbTeamsCount++
          if (dbTeamsCount > newValues[i]) thisTeam.deleted = 1
        }
      }
      for (let j = dbTeamsCount; j < newValues[i]; j++) {
        Xstate.club.detailsTemp.dbLeagueTeams.push({
          league_id: Xstate.club.details.dbLeagueGeneral[0].league_id,
          team_name: labelTeams + "_" + (i + 1) + "-" + (j + 1),
          team_id: 1,
          ladder_initial: "unassigned",
          division_id: i,
          group_id: "unassigned",
        })
      }
    }
    let tempResult = Xstate.club.detailsTemp.dbLeagueTeams.filter(thisTeam => {
      return thisTeam.deleted !== 1
    })
    tempResult = tempResult.sort((a, b) => {
      return 1 * a.division_id - 1 * b.division_id
    })
    let i = 0
    tempResult.forEach(thisTeam => {
      i++
      thisTeam.team_id = i
    })
    Xstate.club.detailsTemp.dbLeagueTeams = tempResult
  }
}
export var updateStandings = leagueID => {
  if (Xstate.dbStatusValue === 0) {
    //WAIT FOR CONNECTION
  } else {
    if (Xstate.club.standings === 0) {
      Xstate.club.standings = 1
      if (Xstate.dbStatusValue === "connected") {
        var formData = new FormData()
        formData.append("section", "league")
        formData.append("request", "retrieve-standings")
        formData.append("league_id", leagueID)
        fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
          .then(response => {
            return response.text()
          })
          .then(data => {
            Xstate.club.standings = JSON.parse(data)
          })
      }
      return 1
    }
  }
  return 0
}
