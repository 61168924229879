import React from "react"

import { fetchLink } from "../fetchLink"
import { NavLink } from "react-router-dom"
import { savedText } from "../SavedText"

import dateformat from "dateformat"

import { tquery } from "../app/ResponsiveLayout-20190729"
import { isJSON, Xstate } from "../functions/Shared"

import { dbStatus } from "../App"

export var VenueAttributes = () => {
  if (Xstate.dbStatusValue === 0) {
    return "No Database" //Wait for Connection;
  }
  if (Xstate.posts.venueAttributes !== undefined) {
    return Xstate.posts.venueAttributes
  }
  Xstate.posts.venueAttributes = 1
  var formData = new FormData()
  formData.append("section", "posts")
  formData.append("request", "attributes")
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(response => {
      return response.text()
    })
    .then(data => {
      if (isJSON(data)) {
        Xstate.posts.venueAttributes = JSON.parse(data)
        return "OK"
      }
    })
}
export var VenuesRefresh = () => {
  if (Xstate.posts.filters.refresh === undefined) Xstate.posts.filters.refresh = 0
  if (Xstate.posts.filters.refresh === 0) return <span />
  return (
    <span>
      <button
        id="refreshButton"
        onClick={() => {
          Xstate.posts.dbVenues = undefined
          Xstate.posts.filters.refresh = 0
          tquery("#updateReact").click()
        }}
        className="btn btn-content3"
      >
        Refresh
      </button>
    </span>
  )
}
export var VenuesUpdate = (db, attribute, details) => {
  console.log("T", details)
  if (Xstate.login.userid === "null") details.user_id = "X" + Math.round(Math.random() * 10, 0)
  else details.user_id = Xstate.login.userid

  details.post_id = Xstate.posts.currentVenue.dbVenuesGeneral.post_id
  var formData = new FormData()
  formData.append("section", "posts")
  formData.append("request", "update-venues")
  formData.append("database", db)
  formData.append("attribute", attribute)
  formData.append("details", JSON.stringify(details))
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:posts")
    console.log("request:update-venues")
    console.log("database:" + db)
    console.log("attribute:" + attribute)
    console.log("details:" + JSON.stringify(details))
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })

  if (Xstate.posts.dbVenues.personal === undefined || Xstate.posts.dbVenues.personal === 0)
    Xstate.posts.dbVenues.personal = []

  let postFound = -1
  let sportFound = -1
  for (let i = 0; i < Xstate.posts.dbVenues.personal.length; i++) {
    if (postFound === -1) {
      if (Xstate.posts.dbVenues.personal[i][db].post_id === details.post_id) {
        postFound = 1 * i
        Xstate.posts.dbVenues.personal[i][db][attribute] = details[attribute]
      }
      if (db === "dbVenuesSportsValues") {
        if (postFound > -1) {
          for (let j = 0; j < Xstate.posts.dbVenues.personal[i][db].length; j++) {
            if (Xstate.posts.dbVenues.personal[i][db][j].sport_name === details.sport_name) {
              sportFound = 1 * j
              Xstate.posts.dbVenues.personal[i][db][j][attribute] = details[attribute]
            }
          }
        }
      }
    }
  }
  if (postFound === -1) {
    Xstate.posts.dbVenues.personal.push({
      dbVenuesGeneral: {
        post_id: details.post_id,
        user_id: details.user_id,
        last_update: dateformat(new Date(), "yyyymmdd"),
      },
    })
    Xstate.posts.dbVenues.personal[Xstate.posts.dbVenues.personal.length - 1].dbVenuesGeneral[attribute] =
      details[attribute]
    Xstate.posts.dbVenues.personal[Xstate.posts.dbVenues.personal.length - 1].dbVenuesSportsValues = []
    if (db === "dbVenuesSportsValues") {
      Xstate.posts.dbVenues.personal[Xstate.posts.dbVenues.personal.length - 1].dbVenuesSportsValues = [
        {
          post_id: details.post_id,
          user_id: details.user_id,
          last_update: dateformat(new Date(), "yyyymmdd"),
          sport_name: details.sport_name,
        },
      ]
      Xstate.posts.dbVenues.personal[Xstate.posts.dbVenues.personal.length - 1].dbVenuesSportsValues[0][attribute] =
        details[attribute]
    }
  } else if (sportFound === -1) {
    if (db === "dbVenuesSportsValues") {
      Xstate.posts.dbVenues.personal[postFound][db].push({
        post_id: details.post_id,
        user_id: details.user_id,
        last_update: dateformat(new Date(), "yyyymmdd"),
        sport_name: details.sport_name,
      })
      Xstate.posts.dbVenues.personal[postFound][db][Xstate.posts.dbVenues.personal[postFound][db].length - 1][
        attribute
      ] = details[attribute]
    }
  }
}
export var VenueNewAdd = () => {
  Xstate.posts.venueaddcomplete = "Thanks for adding the venue. We will update our database and let other users know!"
  tquery("#updateReact").click()

  let thisVenue = Xstate.posts.myCurrentVenue
  var formData = new FormData()
  formData.append("section", "posts")
  formData.append("request", "newVenueID")
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:posts")
    console.log("request:newVenueID")
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      thisVenue.dbVenuesGeneral.post_id = data
      VenueNewSave()
    })
}
export var VenueNewNearby = () => {
  let thisVenue = Xstate.posts.myCurrentVenue

  Xstate.posts.similarVenuesText = "Finding venue. Give us a second please. "
  tquery("#updateReact").click()

  let address = ""
  address = address + thisVenue.dbVenuesGeneral.name + address + " "
  address = address + thisVenue.dbVenuesGeneral.venue_address_line1
  address = address + ", " + thisVenue.dbVenuesGeneral.venue_city
  address = address + ", " + thisVenue.dbVenuesGeneral.venue_province
  address = address + ", " + thisVenue.dbVenuesGeneral.venue_country

  var formData = new FormData()
  formData.append("section", "map")
  formData.append("request", "geocode")
  formData.append("details", address)
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:map")
    console.log("request:geocode")
    console.log("details:" + address)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      if (isJSON(data)) {
        Xstate.posts.similarVenuesText = "Venue found. Looking for similar venues"
        tquery("#updateReact").click()

        data = JSON.parse(data)
        let lat = data[0]
        let lng = data[1]
        thisVenue.dbVenuesGeneral.venue_latitude = lat
        thisVenue.dbVenuesGeneral.venue_longitude = lng

        let details = '{"location":[{"lat":' + lat + ',"lng":' + lng + '}],"KM":3,"max":5,"sortby":"Distance"}'

        let formData = new FormData()
        formData.append("section", "posts")
        formData.append("request", "retrieve-venues")
        formData.append("details", details)
        if (Xstate.testing === "on") {
          console.log("FETCH")
          console.log("section:retrieve-venues")
          console.log("details:" + details)
        }
        fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
          .then(function (response) {
            return response.text()
          })
          .then(function (data) {
            if (isJSON(data)) {
              console.log(JSON.parse(data))
              Xstate.posts.similarVenues = JSON.parse(data)
              Xstate.posts.similarVenuesText = ""
              tquery("#updateReact").click()
            } else {
              Xstate.posts.similarVenuesText = savedText.error.general
              tquery("#updateReact").click()
            }
          })
      } else {
        Xstate.posts.similarVenuesText = savedText.error.general
        tquery("#updateReact").click()
      }
    })
}
export var VenueNewSame = post_id => {
  Xstate.posts.venueaddcomplete = "Thanks for updating the venue. We will update our database and let other users know!"
  tquery("#updateReact").click()

  let thisVenue = Xstate.posts.myCurrentVenue
  thisVenue.dbVenuesGeneral.post_id = post_id
  VenueNewSave()
}
export var VenueNewSave = () => {
  let thisVenue = Xstate.posts.myCurrentVenue

  for (let i = 0; i < Object.keys(thisVenue.dbVenuesGeneral).length; i++) {
    let thisKey = Object.keys(thisVenue.dbVenuesGeneral)[i]
    if (thisKey === "post_id") continue
    if (thisKey === "update_date") continue
    VenuesUpdate("dbVenuesGeneral", thisKey, thisVenue.dbVenuesGeneral)
  }

  for (let h = 0; h < thisVenue.dbVenuesSportsValues.length; h++) {
    for (let i = 0; i < Object.keys(thisVenue.dbVenuesSportsValues[h]).length; i++) {
      let thisKey = Object.keys(thisVenue.dbVenuesSportsValues[h])[i]

      if (thisKey === "post_id") continue
      if (thisKey === "update_date") continue
      if (thisKey === "sport_name") continue
      VenuesUpdate("dbVenuesSportsValues", thisKey, thisVenue.dbVenuesSportsValues[h])
    }
    VenuesUpdate("dbVenuesSportsValues", "sport_exists", thisVenue.dbVenuesSportsValues[h])
  }
}
export var PostsVenueUpdate = () => {
  if (Xstate.dbStatusValue === 0) {
    //WAIT FOR CONNECTION
  } else {
    if (Xstate.posts.dbPosts === 0) {
      Xstate.posts.dbPosts = 1
      Xstate.posts.dbPostsType = "db"
      var formData = new FormData()
      formData.append("section", "posts")
      formData.append("request", "retrieve-posts")
      fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
        .then(function (response) {
          return response.text()
        })
        .then(function (data) {
          if (isJSON(data.split("\\n").join("<br/>"))) {
            Xstate.posts.dbPosts = JSON.parse(data.split("\\n").join("<br/>"))
            tquery("#updateReact").click()
          }
          return 0
        })
      return 1
    }
  }
  return 0
}
export var PostsVenues = () => {
  if (Xstate.dbStatusValue === 0) {
    dbStatus()
  } else {
    if (Xstate.posts.dbVenues === undefined) {
      let details = {}
      if (Xstate.posts.filters !== undefined) details = JSON.parse(JSON.stringify(Xstate.posts.filters))
      if (details.name !== undefined) details.name = details.name.trim()
      if (details.name === "") details.name = undefined
      details.sport = Xstate.sport
      if (details.sport !== undefined) {
        if (details.sport === "All Sports") details.sport = undefined
      }
      if (Xstate.posts.filters_id !== undefined) details.filters_id = Xstate.posts.filters_id
      if (Xstate.posts.advancedFilters === 0 || Xstate.posts.advancedFilters === undefined) details.advanced = undefined
      if (JSON.stringify(details) === "[]") details = { sport: details.sport }
      Xstate.posts.dbVenues = 1

      Xstate.posts.dbVenuesType = "db"
      var formData = new FormData()
      formData.append("section", "posts")
      formData.append("request", "retrieve-venues")
      formData.append("details", JSON.stringify(details))

      if (Xstate.testing === "on") {
        console.log("FETCH")
        console.log("section:posts")
        console.log("request:retrieve-venues")
        console.log("details:" + JSON.stringify(details))
      }
      fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
        .then(function (response) {
          return response.text()
        })
        .then(function (data) {
          if (isJSON(data)) {
            Xstate.posts.dbVenues = JSON.parse(data)
            Xstate.posts.printMax = 19
            tquery("#updateReact").click()
            return 1
          }
          return 0
        })

      return 1
    }
  }
}
export var VenuesHeader = () => {
  return (
    <div className="sportheadertext">
      <h1>
        <i className="borderFA fa fa-globe"></i>
        Venues
      </h1>
      <div>
        <NavLink
          onClick={() => {
            tquery("#updateReact").click()
            tquery(".contentsection").scrollTo(0, 0)
          }}
          className="btn btn-menu1"
          to="/venues"
        >
          Venues Home
        </NavLink>
      </div>
    </div>
  )
}
export var VenuesWiki = () => {
  if (Xstate.posts.type !== "venues") return <span />
  return (
    <div>
      <b> Wiki - Venues</b>
      <br />
      The results below are based on user input. This is our Wiki-Venues database.
      <br />
      <NavLink
        onClick={() => {
          tquery("#updateReact").click()
          tquery(".contentsection").scrollTo(0, 0)
        }}
        to="/venues-about"
        className="btn btn-content2 btn-small"
      >
        More Information
      </NavLink>
      <button
        id="refreshButton"
        onClick={() => {
          Xstate.posts.dbVenues = undefined
          Xstate.posts.filters.refresh = 0
          tquery("#updateReact").click()
        }}
        className="btn btn-content3 btn-small"
      >
        Refresh
      </button>
      <div className="thick-gold-line">&nbsp;</div>
    </div>
  )
}
export var VenuesMinimumRating = () => {
  let tCount = 6712
  if (Xstate.posts.filters.minimumRating === undefined) {
    Xstate.posts.filters.minimumRating = 1
  }
  let stars = []
  for (let i = 1; i <= Xstate.posts.filters.minimumRating; i++) {
    stars.push(
      <span key={tCount++}>
        <i
          onClick={() => {
            Xstate.posts.filters.minimumRating = i
            Xstate.posts.filters.refresh = 1
            Xstate.posts.filters.refresh = 1

            tquery("#updateReact").click()
          }}
          className="fas fa-star active"
        ></i>
      </span>,
    )
  }
  for (let i = Xstate.posts.filters.minimumRating + 1; i <= 5; i++) {
    stars.push(
      <span key={tCount++}>
        <i
          style={{ opacity: "0.2" }}
          onClick={() => {
            Xstate.posts.filters.minimumRating = i
            Xstate.posts.filters.refresh = 1

            tquery("#updateReact").click()
          }}
          className="fas fa-star"
        ></i>
      </span>,
    )
  }
  return (
    <tr>
      <td>
        <b>Venue Rating: </b>
      </td>
      <td>
        Minimum:
        <br />
        {stars}
      </td>
    </tr>
  )
}
export var VenuesAdvancedFilters = () => {
  if (Xstate.posts.advancedFilters === undefined) Xstate.posts.advancedFilters = 0
  if (Xstate.posts.type !== "venues") {
    return (
      <tr>
        <td className="font-small">&nbsp;</td>
      </tr>
    )
  }

  if (Xstate.posts.advancedFilters === 0) {
    return (
      <tr>
        <td>
          <b>Advanced Filters: </b>
        </td>
        <td>
          <button
            id="advancedFilters"
            className="btn btn-content2 btn-small"
            onClick={() => {
              Xstate.posts.advancedFilters = 1
              tquery("#updateReact").click()
            }}
          >
            Open
          </button>
        </td>
      </tr>
    )
  }

  //Sport Specific Advanced Filters
  let tCount = 91731
  let resultJSX = [<br key={tCount++} />, <br key={tCount++} />]

  PostsVenuesFiltersID(Xstate.sport)
  console.log(Xstate.posts.filters_id)

  //FREE
  if (Xstate.posts.filters_id.free !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.free = undefined
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonFree = (
      <button
        id="buttonFree"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.free = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Free
      </button>
    )
    let buttonPaid = (
      <button
        id="buttonPaid"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.free = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Paid
      </button>
    )
    let hourly_rate = <span />
    let annual_cost = <span />
    if (Xstate.posts.filters.advanced.free === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.free === 1) {
      buttonFree = (
        <button id="buttonFree" className="btn btn-content3 btn-small active">
          Free
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.free === 0) {
      buttonPaid = (
        <button id="buttonPaid" className="btn btn-content3 btn-small active">
          Paid
        </button>
      )
      if (Xstate.posts.filters.advanced.hourly_rate0 === undefined) Xstate.posts.filters.advanced.hourly_rate0 = 0
      if (Xstate.posts.filters.advanced.hourly_rate1 === undefined) Xstate.posts.filters.advanced.hourly_rate1 = 1000
      hourly_rate = (
        <div>
          <b>Hourly ($): </b>
          <div className="div-input">
            <input
              type="number"
              className="input-number w5em"
              value={1 * Xstate.posts.filters.advanced.hourly_rate0}
              step="0.01"
              onChange={event => {
                Xstate.posts.filters.advanced.hourly_rate0 = 1 * event.target.value
                Xstate.posts.filters.refresh = 1

                tquery("#updateReact").click()
              }}
            />
            &nbsp;-&nbsp;
            <input
              type="number"
              className="input-number w5em"
              value={1 * Xstate.posts.filters.advanced.hourly_rate1}
              step="0.01"
              onChange={event => {
                Xstate.posts.filters.advanced.hourly_rate1 = 1 * event.target.value
                Xstate.posts.filters.refresh = 1

                tquery("#updateReact").click()
              }}
            />
          </div>
        </div>
      )
      if (Xstate.posts.filters.advanced.annual_cost0 === undefined) Xstate.posts.filters.advanced.annual_cost0 = 0
      if (Xstate.posts.filters.advanced.annual_cost1 === undefined) Xstate.posts.filters.advanced.annual_cost1 = 100000

      let buttonAll = (
        <button
          className="btn btn-content3 btn-small"
          onClick={() => {
            Xstate.posts.filters.advanced.annual_cost0 = 0
            Xstate.posts.filters.advanced.annual_cost1 = 100000
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
      let button1 = (
        <button
          className="btn btn-content3 btn-small"
          onClick={() => {
            Xstate.posts.filters.advanced.annual_cost0 = 0
            Xstate.posts.filters.advanced.annual_cost1 = 100
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          $0 to $100
        </button>
      )
      let button2 = (
        <button
          className="btn btn-content3 btn-small"
          onClick={() => {
            Xstate.posts.filters.advanced.annual_cost0 = 100
            Xstate.posts.filters.advanced.annual_cost1 = 300
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          $100 to $300
        </button>
      )
      let button3 = (
        <button
          className="btn btn-content3 btn-small"
          onClick={() => {
            Xstate.posts.filters.advanced.annual_cost0 = 300
            Xstate.posts.filters.advanced.annual_cost1 = 1000
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          $300 to $1,000
        </button>
      )
      let button4 = (
        <button
          className="btn btn-content3 btn-small"
          onClick={() => {
            Xstate.posts.filters.advanced.annual_cost0 = 1000
            Xstate.posts.filters.advanced.annual_cost1 = 100000
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          $1,000+
        </button>
      )
      if (Xstate.posts.filters.advanced.annual_cost0 === 0 && Xstate.posts.filters.advanced.annual_cost1 === 100000) {
        let buttonAll = (
          <button
            id="buttonAll"
            className="btn btn-content3 btn-small"
            onClick={() => {
              tquery("#updateReact").click()
            }}
          >
            All
          </button>
        )
      } else if (Xstate.posts.filters.advanced.annual_cost0 === 0) {
        button1 = (
          <button
            className="btn btn-content3 btn-small active"
            onClick={() => {
              tquery("#updateReact").click()
            }}
          >
            $0 to $100
          </button>
        )
      } else if (Xstate.posts.filters.advanced.annual_cost0 === 100) {
        button2 = (
          <button
            className="btn btn-content3 btn-small active"
            onClick={() => {
              tquery("#updateReact").click()
            }}
          >
            $100 to $300
          </button>
        )
      } else if (Xstate.posts.filters.advanced.annual_cost0 === 300) {
        button3 = (
          <button
            className="btn btn-content3 btn-small active"
            onClick={() => {
              tquery("#updateReact").click()
            }}
          >
            $300 to $1,000
          </button>
        )
      } else if (Xstate.posts.filters.advanced.annual_cost0 === 1000) {
        button4 = (
          <button
            className="btn btn-content3 btn-small active"
            onClick={() => {
              tquery("#updateReact").click()
            }}
          >
            $1,000+
          </button>
        )
      }
      annual_cost = (
        <div>
          <b>Annual Cost ($): </b>
          <div className="div-input">
            {buttonAll}
            {button1}
            {button2}
            {button3}
            {button4}
          </div>
        </div>
      )
    }

    resultJSX.push(
      <span>
        <b>Free/Paid :</b>
        <div className="div-input">
          {buttonAll}
          {buttonFree}
          {buttonPaid}
        </div>
        {hourly_rate}
        {annual_cost}
      </span>,
    )
  }
  if (Xstate.posts.filters_id.membership_open !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.membership_open = 1
          Xstate.posts.filters.advanced.membership_waitlist = 1
          Xstate.posts.filters.advanced.membership_closed = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonOpen = (
      <button
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.membership_open = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        Open
      </button>
    )
    let buttonWaitlist = (
      <button
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.membership_waitlist = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        Waitlist
      </button>
    )
    let buttonClosed = (
      <button
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.membership_closed = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        Closed
      </button>
    )
    if (
      Xstate.posts.filters.advanced.membership_open === undefined ||
      Xstate.posts.filters.advanced.membership_open === 1
    ) {
      if (
        Xstate.posts.filters.advanced.membership_waitlist === undefined ||
        Xstate.posts.filters.advanced.membership_waitlist === 1
      ) {
        if (
          Xstate.posts.filters.advanced.membership_closed === undefined ||
          Xstate.posts.filters.advanced.membership_closed === 1
        ) {
          buttonAll = (
            <button id="buttonAll" className="btn btn-content3 btn-small active">
              All
            </button>
          )
        }
      }
    }
    if (
      Xstate.posts.filters.advanced.membership_closed === undefined ||
      Xstate.posts.filters.advanced.membership_closed === 1
    ) {
      buttonClosed = (
        <button
          className="btn btn-content3 btn-small active"
          onClick={() => {
            Xstate.posts.filters.advanced.membership_closed = 0
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          Closed
        </button>
      )
    }
    if (
      Xstate.posts.filters.advanced.membership_waitlist === undefined ||
      Xstate.posts.filters.advanced.membership_waitlist === 1
    ) {
      buttonWaitlist = (
        <button
          className="btn btn-content3 btn-small active"
          onClick={() => {
            Xstate.posts.filters.advanced.membership_waitlist = 0
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          Waitlist
        </button>
      )
    }
    if (
      Xstate.posts.filters.advanced.membership_open === undefined ||
      Xstate.posts.filters.advanced.membership_open === 1
    ) {
      buttonOpen = (
        <button
          className="btn btn-content3 btn-small active"
          onClick={() => {
            Xstate.posts.filters.advanced.membership_open = 0
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          Open
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Membership:</b>
        <div className="div-input">
          {buttonAll}
          {buttonOpen}
          {buttonWaitlist}
          {buttonClosed}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.advance_booking !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.advance_booking = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.advance_booking = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.advance_booking = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.advance_booking === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.advance_booking === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.advance_booking === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Able to Book:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }
  if (Xstate.posts.filters_id.public !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.public = undefined
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonPublic = (
      <button
        id="buttonPublic"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.public = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        Public
      </button>
    )
    let buttonPrivate = (
      <button
        id="buttonPrivate"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.public = 0
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        Private
      </button>
    )
    if (Xstate.posts.filters.advanced.public === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.public === 1) {
      buttonPublic = (
        <button id="buttonPublic" className="btn btn-content3 btn-small active">
          Public
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.public === 0) {
      buttonPrivate = (
        <button id="buttonPrivate" className="btn btn-content3 btn-small active">
          Private
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Public:</b>
        <div className="div-input">
          {buttonAll}
          {buttonPublic}
          {buttonPrivate}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.lights !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.lights = undefined
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.lights = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.lights = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.lights === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.lights === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.lights === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Light:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.parking !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.parking = undefined
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.parking = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.parking = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.parking === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.parking === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.parking === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Parking:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.busy !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters["advanced"].busy1 = 1
          Xstate.posts.filters["advanced"].busy2 = 1
          Xstate.posts.filters["advanced"].busy3 = 1
          Xstate.posts.filters["advanced"].busy4 = 1
          Xstate.posts.filters["advanced"].busy5 = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    Xstate.posts.filters["advanced"].busyAll = 1
    for (let t = 1; t <= 5; t++) {
      if (Xstate.posts.filters["advanced"]["busy" + t] === undefined) {
        Xstate.posts.filters["advanced"]["busy" + t] = 1
        Xstate.posts.filters["advanced"]["busy"] = 0
        Xstate.posts.filters.refresh = 1
      }
      Xstate.posts.filters["advanced"].busyAll = Math.min(
        Xstate.posts.filters["advanced"].busyAll,
        Xstate.posts.filters["advanced"]["busy" + t],
      )
    }
    if (Xstate.posts.filters["advanced"].busyAll === 1) {
      buttonAll = (
        <button
          id="buttonAll"
          className="btn btn-content3 btn-small active"
          onClick={() => {
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
    }

    let buttons = []
    for (let t = 1; t <= 5; t++) {
      buttons[t] = (
        <button
          id={"button" + t}
          className="btn btn-content3 btn-small"
          onClick={() => {
            if (Xstate.posts.filters["advanced"].busyAll === 1) {
              Xstate.posts.filters["advanced"].busy1 = 0
              Xstate.posts.filters["advanced"].busy2 = 0
              Xstate.posts.filters["advanced"].busy3 = 0
              Xstate.posts.filters["advanced"].busy4 = 0
              Xstate.posts.filters["advanced"].busy5 = 0
            }
            Xstate.posts.filters["advanced"]["busy" + t] = 1
            Xstate.posts.filters.refresh = 1

            tquery("#updateReact").click()
          }}
        >
          {t}
        </button>
      )
      if (Xstate.posts.filters["advanced"]["busy" + t] === 1) {
        if (Xstate.posts.filters["advanced"].busyAll !== 1) {
          buttons[t] = (
            <button
              id={"button" + t}
              className="btn btn-content3 btn-small active"
              onClick={() => {
                Xstate.posts.filters["advanced"]["busy" + t] = 0
                Xstate.posts.filters.refresh = 1

                tquery("#updateReact").click()
              }}
            >
              {t}
            </button>
          )
        }
      }
    }
    resultJSX.push(
      <span>
        <b>Busy Level:</b>
        <div className="div-input">
          {buttonAll}
          {buttons}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.hill !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters["advanced"].hill1 = 1
          Xstate.posts.filters["advanced"].hill2 = 1
          Xstate.posts.filters["advanced"].hill3 = 1
          Xstate.posts.filters["advanced"].hill4 = 1
          Xstate.posts.filters["advanced"].hill5 = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    Xstate.posts.filters["advanced"].hillAll = 1
    for (let t = 1; t <= 5; t++) {
      if (Xstate.posts.filters["advanced"]["hill" + t] === undefined) {
        Xstate.posts.filters["advanced"]["hill" + t] = 1
        Xstate.posts.filters["advanced"]["hill"] = 0
        Xstate.posts.filters.refresh = 1
      }
      Xstate.posts.filters["advanced"].hillAll = Math.min(
        Xstate.posts.filters["advanced"].hillAll,
        Xstate.posts.filters["advanced"]["hill" + t],
      )
    }
    if (Xstate.posts.filters["advanced"].hillAll === 1) {
      buttonAll = (
        <button
          id="buttonAll"
          className="btn btn-content3 btn-small active"
          onClick={() => {
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
    }

    let buttons = []
    for (let t = 1; t <= 5; t++) {
      buttons[t] = (
        <button
          id={"button" + t}
          className="btn btn-content3 btn-small"
          onClick={() => {
            if (Xstate.posts.filters["advanced"].hillAll === 1) {
              Xstate.posts.filters["advanced"].hill1 = 0
              Xstate.posts.filters["advanced"].hill2 = 0
              Xstate.posts.filters["advanced"].hill3 = 0
              Xstate.posts.filters["advanced"].hill4 = 0
              Xstate.posts.filters["advanced"].hill5 = 0
            }
            Xstate.posts.filters["advanced"]["hill" + t] = 1
            Xstate.posts.filters.refresh = 1

            tquery("#updateReact").click()
          }}
        >
          {t}
        </button>
      )
      if (Xstate.posts.filters["advanced"]["hill" + t] === 1) {
        if (Xstate.posts.filters["advanced"].hillAll !== 1) {
          buttons[t] = (
            <button
              id={"button" + t}
              className="btn btn-content3 btn-small active"
              onClick={() => {
                Xstate.posts.filters["advanced"]["hill" + t] = 0
                Xstate.posts.filters.refresh = 1

                tquery("#updateReact").click()
              }}
            >
              {t}
            </button>
          )
        }
      }
    }
    resultJSX.push(
      <span>
        <b>Hill Level:</b>
        <div className="div-input">
          {buttonAll}
          {buttons}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.indoor !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.indoor = 1
          Xstate.posts.filters.advanced.outdoor = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    if (Xstate.posts.filters.advanced.indoor === undefined) {
      Xstate.posts.filters.advanced.indoor = 1
    }
    if (Xstate.posts.filters.advanced.outdoor === undefined) {
      Xstate.posts.filters.advanced.outdoor = 1
    }

    Xstate.posts.filters.advanced.inOutall = Math.min(
      1,
      Xstate.posts.filters.advanced.indoor,
      Xstate.posts.filters.advanced.outdoor,
    )
    if (Xstate.posts.filters.advanced.inOutall === 1) {
      buttonAll = (
        <button
          id="buttonAll"
          className="btn btn-content3 btn-small active"
          onClick={() => {
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
    }

    let buttonIndoor = (
      <button
        id="buttonIndoor"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.outdoor = 0
          Xstate.posts.filters.advanced.indoor = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Indoor
      </button>
    )
    let buttonOutdoor = (
      <button
        id="buttonOutdoor"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.indoor = 0
          Xstate.posts.filters.advanced.outdoor = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Outdoor
      </button>
    )
    if (Xstate.posts.filters.advanced.inOutall !== 1) {
      if (Xstate.posts.filters.advanced.outdoor === 1) {
        buttonIndoor = (
          <button
            id="buttonIndoor"
            className="btn btn-content3 btn-small"
            onClick={() => {
              Xstate.posts.filters.advanced.indoor = 1
              Xstate.posts.filters.refresh = 1
              tquery("#updateReact").click()
            }}
          >
            Indoor
          </button>
        )
        buttonOutdoor = (
          <button
            id="buttonOutdoor"
            className="btn btn-content3 btn-small active"
            onClick={() => {
              Xstate.posts.filters.advanced.outdoor = 0
              Xstate.posts.filters.refresh = 1
              tquery("#updateReact").click()
            }}
          >
            Outdoor
          </button>
        )
      }
      if (Xstate.posts.filters.advanced.indoor === 1) {
        buttonIndoor = (
          <button
            id="buttonIndoor"
            className="btn btn-content3 btn-small active"
            onClick={() => {
              Xstate.posts.filters.advanced.indoor = 0
              Xstate.posts.filters.refresh = 1
              tquery("#updateReact").click()
            }}
          >
            Indoor
          </button>
        )
        buttonOutdoor = (
          <button
            id="buttonOutdoor"
            className="btn btn-content3 btn-small"
            onClick={() => {
              Xstate.posts.filters.advanced.outdoor = 1
              Xstate.posts.filters.refresh = 1
              tquery("#updateReact").click()
            }}
          >
            Outdoor
          </button>
        )
      }
    }
    resultJSX.push(
      <span>
        <b>Indoor/Outdoor:</b>
        <div className="div-input">
          {buttonAll}
          {buttonIndoor}
          {buttonOutdoor}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.fullcourt !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.fullcourt = 1
          Xstate.posts.filters.advanced.halfcourt = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    if (Xstate.posts.filters.advanced.fullcourt === undefined) {
      Xstate.posts.filters.advanced.fullcourt = 1
    }
    if (Xstate.posts.filters.advanced.halfcourt === undefined) {
      Xstate.posts.filters.advanced.halfcourt = 1
    }

    Xstate.posts.filters.advanced.fullhalfall = Math.min(
      1,
      Xstate.posts.filters.advanced.fullcourt,
      Xstate.posts.filters.advanced.halfcourt,
    )
    if (Xstate.posts.filters.advanced.fullhalfall === 1) {
      buttonAll = (
        <button
          id="buttonAll"
          className="btn btn-content3 btn-small active"
          onClick={() => {
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
    }

    let buttonFullcourt = (
      <button
        id="buttonFullcourt"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.halfcourt = 0
          Xstate.posts.filters.advanced.fullcourt = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        Fullcourt
      </button>
    )
    let buttonHalfcourt = (
      <button
        id="buttonHalfcourt"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.fullcourt = 0
          Xstate.posts.filters.advanced.halfcourt = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Halfcourt
      </button>
    )
    if (Xstate.posts.filters.advanced.fullhalfall !== 1) {
      if (Xstate.posts.filters.advanced.halfcourt === 1) {
        buttonFullcourt = (
          <button
            id="buttonFullcourt"
            className="btn btn-content3 btn-small"
            onClick={() => {
              Xstate.posts.filters.advanced.fullcourt = 1
              Xstate.posts.filters.refresh = 1
              tquery("#updateReact").click()
            }}
          >
            Fullcourt
          </button>
        )
        buttonHalfcourt = (
          <button
            id="buttonHalfcourt"
            className="btn btn-content3 btn-small active"
            onClick={() => {
              Xstate.posts.filters.advanced.halfcourt = 0
              Xstate.posts.filters.refresh = 1
              tquery("#updateReact").click()
            }}
          >
            Halfcourt
          </button>
        )
      }
      if (Xstate.posts.filters.advanced.fullcourt === 1) {
        buttonFullcourt = (
          <button
            id="buttonFullcourt"
            className="btn btn-content3 btn-small active"
            onClick={() => {
              Xstate.posts.filters.advanced.fullcourt = 0
              Xstate.posts.filters.refresh = 1
              tquery("#updateReact").click()
            }}
          >
            Fullcourt
          </button>
        )
        buttonHalfcourt = (
          <button
            id="buttonHalfcourt"
            className="btn btn-content3 btn-small"
            onClick={() => {
              Xstate.posts.filters.advanced.halfcourt = 1
              Xstate.posts.filters.refresh = 1
              tquery("#updateReact").click()
            }}
          >
            Halfcourt
          </button>
        )
      }
    }
    resultJSX.push(
      <span>
        <b>Fullcourt/Halfcourt:</b>
        <div className="div-input">
          {buttonAll}
          {buttonFullcourt}
          {buttonHalfcourt}
        </div>
      </span>,
    )
  }
  if (Xstate.posts.filters_id.court1 !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.court1 = 1
          Xstate.posts.filters.advanced.court2 = 1
          Xstate.posts.filters.advanced.court3 = 1
          Xstate.posts.filters.advanced.court4 = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    Xstate.posts.filters.advanced.courtAll = 1
    for (let t = 1; t <= 4; t++) {
      if (Xstate.posts.filters.advanced["court" + t] === undefined) {
        Xstate.posts.filters.advanced["court" + t] = 1
      }
      Xstate.posts.filters.advanced.courtAll = Math.min(
        Xstate.posts.filters.advanced.courtAll,
        Xstate.posts.filters.advanced["court" + t],
      )
    }
    if (Xstate.posts.filters.advanced.courtAll === 1) {
      buttonAll = (
        <button
          id="buttonAll"
          className="btn btn-content3 btn-small active"
          onClick={() => {
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
    }

    let buttons = []
    for (let t = 1; t <= 4; t++) {
      buttons[t] = (
        <button
          id={"button" + t}
          className="btn btn-content3 btn-small"
          onClick={() => {
            if (Xstate.posts.filters.advanced.courtAll === 1) {
              Xstate.posts.filters.advanced.court1 = 0
              Xstate.posts.filters.advanced.court2 = 0
              Xstate.posts.filters.advanced.court3 = 0
              Xstate.posts.filters.advanced.court4 = 0
            }
            Xstate.posts.filters.advanced["court" + t] = 1
            Xstate.posts.filters.refresh = 1

            tquery("#updateReact").click()
          }}
        >
          {Xstate.posts.filters_id.courtNames[t]}
        </button>
      )
      if (Xstate.posts.filters.advanced["court" + t] === 1) {
        if (Xstate.posts.filters.advanced.courtAll !== 1) {
          buttons[t] = (
            <button
              id={"button" + t}
              className="btn btn-content3 btn-small active"
              onClick={() => {
                Xstate.posts.filters.advanced["court" + t] = 0
                Xstate.posts.filters.refresh = 1
                tquery("#updateReact").click()
              }}
            >
              {Xstate.posts.filters_id.courtNames[t]}
            </button>
          )
        }
      }
    }
    resultJSX.push(
      <span>
        <b>Court Type:</b>
        <div className="div-input">
          {buttonAll}
          {buttons}
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </div>
      </span>,
    )
  }
  if (Xstate.posts.filters_id.squashcourt1 !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.squashcourt1 = 1
          Xstate.posts.filters.advanced.squashcourt2 = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    Xstate.posts.filters.advanced.squashcourtAll = 1
    for (let t = 1; t <= 2; t++) {
      if (Xstate.posts.filters.advanced["squashcourt" + t] === undefined) {
        Xstate.posts.filters.advanced["squashcourt" + t] = 1
      }
      Xstate.posts.filters.advanced.squashcourtAll = Math.min(
        Xstate.posts.filters.advanced.squashcourtAll,
        Xstate.posts.filters.advanced["squashcourt" + t],
      )
    }
    if (Xstate.posts.filters.advanced.squashcourtAll === 1) {
      buttonAll = (
        <button
          id="buttonAll"
          className="btn btn-content3 btn-small active"
          onClick={() => {
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
    }

    let buttons = []
    for (let t = 1; t <= 2; t++) {
      buttons[t] = (
        <button
          id={"button" + t}
          className="btn btn-content3 btn-small"
          onClick={() => {
            if (Xstate.posts.filters.advanced.squashcourtAll === 1) {
              Xstate.posts.filters.advanced.squashcourt1 = 0
              Xstate.posts.filters.advanced.squashcourt2 = 0
            }
            Xstate.posts.filters.advanced["squashcourt" + t] = 1
            Xstate.posts.filters.refresh = 1

            tquery("#updateReact").click()
          }}
        >
          {Xstate.posts.filters_id.squashcourtNames[t]}
        </button>
      )
      if (Xstate.posts.filters.advanced["squashcourt" + t] === 1) {
        if (Xstate.posts.filters.advanced.squashcourtAll !== 1) {
          buttons[t] = (
            <button
              id={"button" + t}
              className="btn btn-content3 btn-small active"
              onClick={() => {
                Xstate.posts.filters.advanced["squashcourt" + t] = 0
                Xstate.posts.filters.refresh = 1
                tquery("#updateReact").click()
              }}
            >
              {Xstate.posts.filters_id.squashcourtNames[t]}
            </button>
          )
        }
      }
    }
    resultJSX.push(
      <span>
        <b>Court Type:</b>
        <div className="div-input">
          {buttonAll}
          {buttons}
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.soccercourt1 !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.soccercourt1 = 1
          Xstate.posts.filters.advanced.soccercourt2 = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    Xstate.posts.filters.advanced.soccercourtAll = 1
    for (let t = 1; t <= 2; t++) {
      if (Xstate.posts.filters.advanced["soccercourt" + t] === undefined) {
        Xstate.posts.filters.advanced["soccercourt" + t] = 1
      }
      Xstate.posts.filters.advanced.soccercourtAll = Math.min(
        Xstate.posts.filters.advanced.soccercourtAll,
        Xstate.posts.filters.advanced["soccercourt" + t],
      )
    }
    if (Xstate.posts.filters.advanced.soccercourtAll === 1) {
      buttonAll = (
        <button
          id="buttonAll"
          className="btn btn-content3 btn-small active"
          onClick={() => {
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
    }

    let buttons = []
    for (let t = 1; t <= 2; t++) {
      buttons[t] = (
        <button
          id={"button" + t}
          className="btn btn-content3 btn-small"
          onClick={() => {
            if (Xstate.posts.filters.advanced.soccercourtAll === 1) {
              Xstate.posts.filters.advanced.soccercourt1 = 0
              Xstate.posts.filters.advanced.soccercourt2 = 0
            }
            Xstate.posts.filters.advanced["soccercourt" + t] = 1
            Xstate.posts.filters.refresh = 1

            tquery("#updateReact").click()
          }}
        >
          {Xstate.posts.filters_id.soccercourtNames[t]}
        </button>
      )
      if (Xstate.posts.filters.advanced["soccercourt" + t] === 1) {
        if (Xstate.posts.filters.advanced.soccercourtAll !== 1) {
          buttons[t] = (
            <button
              id={"button" + t}
              className="btn btn-content3 btn-small active"
              onClick={() => {
                Xstate.posts.filters.advanced["soccercourt" + t] = 0
                Xstate.posts.filters.refresh = 1
                tquery("#updateReact").click()
              }}
            >
              {Xstate.posts.filters_id.soccercourtNames[t]}
            </button>
          )
        }
      }
    }
    resultJSX.push(
      <span>
        <b>Court Type:</b>
        <div className="div-input">
          {buttonAll}
          {buttons}
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.fountain !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.fountain = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.fountain = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.fountain = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.fountain === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.fountain === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.fountain === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Fountain:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.changeroom !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.changeroom = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.changeroom = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.changeroom = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.changeroom === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.changeroom === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.changeroom === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Changeroom:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.washroom !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.washroom = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.washroom = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.washroom = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.washroom === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.washroom === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.washroom === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Washroom:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.rental !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.rental = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.rental = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.rental = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.rental === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.rental === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.rental === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Rental:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.nets !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.nets = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.nets = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.nets = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.nets === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.nets === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.nets === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Nets:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.fence !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.fence = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.fence = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.fence = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.fence === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.fence === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.fence === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>fence:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.board !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.board = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.board = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.board = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.board === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.board === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.board === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>board:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.driving_range !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.driving_range = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.driving_range = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.driving_range = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.driving_range === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.driving_range === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.driving_range === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Driving Range:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.mini_putt !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.mini_putt = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonYes = (
      <button
        id="buttonYes"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.mini_putt = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        Yes
      </button>
    )
    let buttonNo = (
      <button
        id="buttonNo"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.mini_putt = 0
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        No
      </button>
    )
    if (Xstate.posts.filters.advanced.mini_putt === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.mini_putt === 1) {
      buttonYes = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Yes
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.mini_putt === 0) {
      buttonNo = (
        <button id="buttonNo" className="btn btn-content3 btn-small active">
          No
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Mini Putt:</b>
        <div className="div-input">
          {buttonAll}
          {buttonYes}
          {buttonNo}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.seasonal !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.seasonal = undefined
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let buttonOpen = (
      <button
        id="buttonOpen"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.seasonal = 1
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        Open Now
      </button>
    )
    if (Xstate.posts.filters.advanced.seasonal === undefined) {
      buttonAll = (
        <button id="buttonAll" className="btn btn-content3 btn-small active">
          All
        </button>
      )
    }
    if (Xstate.posts.filters.advanced.seasonal === 1) {
      buttonOpen = (
        <button id="buttonYes" className="btn btn-content3 btn-small active">
          Open Now
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Seasonal:</b>
        <div className="div-input">
          {buttonAll}
          {buttonOpen}
        </div>
      </span>,
    )
  }
  if (Xstate.posts.filters_id.count_courts !== undefined) {
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.count_courts1 = 1
          Xstate.posts.filters.advanced.count_courts2 = 1
          Xstate.posts.filters.advanced.count_courts3 = 1
          Xstate.posts.filters.advanced.count_courts4 = 1
          Xstate.posts.filters.advanced.count_courts5 = 1
          Xstate.posts.filters.refresh = 1

          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    Xstate.posts.filters.advanced.count_courtsAll = 1
    for (let t = 1; t <= 5; t++) {
      if (Xstate.posts.filters.advanced["count_courts" + t] === undefined) {
        Xstate.posts.filters.advanced["count_courts" + t] = 1
      }
      Xstate.posts.filters.advanced.count_courtsAll = Math.min(
        Xstate.posts.filters.advanced.count_courtsAll,
        Xstate.posts.filters.advanced["count_courts" + t],
      )
    }
    if (Xstate.posts.filters.advanced.count_courtsAll === 1) {
      buttonAll = (
        <button
          id="buttonAll"
          className="btn btn-content3 btn-small active"
          onClick={() => {
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
    }
    let buttons = []
    for (let t = 1; t <= 5; t++) {
      let text = t
      if (t === 5) {
        text = "5+"
      }
      buttons[t] = (
        <button
          id={"button" + t}
          className="btn btn-content3 btn-small"
          onClick={() => {
            if (Xstate.posts.filters.advanced.count_courtsAll === 1) {
              Xstate.posts.filters.advanced.count_courts1 = 0
              Xstate.posts.filters.advanced.count_courts2 = 0
              Xstate.posts.filters.advanced.count_courts3 = 0
              Xstate.posts.filters.advanced.count_courts4 = 0
              Xstate.posts.filters.advanced.count_courts5 = 0
            }
            Xstate.posts.filters.advanced["count_courts" + t] = 1
            Xstate.posts.filters.refresh = 1

            tquery("#updateReact").click()
          }}
        >
          {text}
        </button>
      )
      if (Xstate.posts.filters.advanced["count_courts" + t] === 1) {
        if (Xstate.posts.filters.advanced.count_courtsAll !== 1) {
          buttons[t] = (
            <button
              id={"button" + t}
              className="btn btn-content3 btn-small active"
              onClick={() => {
                Xstate.posts.filters.advanced["count_courts" + t] = 0
                Xstate.posts.filters.refresh = 1

                tquery("#updateReact").click()
              }}
            >
              {text}
            </button>
          )
        }
      }
    }
    resultJSX.push(
      <span>
        <b>Number of Courts:</b>
        <div className="div-input">
          {buttonAll}
          {buttons}
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </div>
      </span>,
    )
  }
  if (Xstate.posts.filters_id.count_holes !== undefined) {
    if (Xstate.posts.filters.advanced.countHolesMin === undefined) Xstate.posts.filters.advanced.countHolesMin = 1
    if (Xstate.posts.filters.advanced.countHolesMax === undefined) Xstate.posts.filters.advanced.countHolesMax = 27
    if (Xstate.posts.filters.advanced.countHolesAll === undefined) Xstate.posts.filters.advanced.countHolesAll = 1
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.countHolesAll = 1
          Xstate.posts.filters.advanced.countHolesMin = 1
          Xstate.posts.filters.advanced.countHolesMax = 27
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let holesCount = (
      <>
        <input
          type="number"
          className="input-number w3em"
          value={1 * Xstate.posts.filters.advanced.countHolesMin}
          step="1"
          onChange={event => {
            Xstate.posts.filters.advanced.countHolesAll = 0
            Xstate.posts.filters.advanced.countHolesMin = 1 * event.target.value
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        />
        &nbsp;-&nbsp;
        <input
          type="number"
          className="input-number w3em"
          value={1 * Xstate.posts.filters.advanced.countHolesMax}
          step="1"
          onChange={event => {
            Xstate.posts.filters.advanced.countHolesAll = 0
            Xstate.posts.filters.advanced.countHolesMax = 1 * event.target.value
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        />
      </>
    )
    if (Xstate.posts.filters.advanced.countHolesAll === 1) {
      buttonAll = (
        <button
          id="buttonAll"
          className="btn btn-content3 btn-small active"
          onClick={() => {
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Number of Holes:</b>
        <div className="div-input">
          {buttonAll}
          {holesCount}
        </div>
      </span>,
    )
  }

  if (Xstate.posts.filters_id.par !== undefined) {
    if (Xstate.posts.filters.advanced.parMin === undefined) Xstate.posts.filters.advanced.parMin = 1
    if (Xstate.posts.filters.advanced.parMax === undefined) Xstate.posts.filters.advanced.parMax = 27
    if (Xstate.posts.filters.advanced.parAll === undefined) Xstate.posts.filters.advanced.parAll = 1
    let buttonAll = (
      <button
        id="buttonAll"
        className="btn btn-content3 btn-small"
        onClick={() => {
          Xstate.posts.filters.advanced.parAll = 1
          Xstate.posts.filters.advanced.parMin = 1
          Xstate.posts.filters.advanced.parMax = 27
          Xstate.posts.filters.refresh = 1
          tquery("#updateReact").click()
        }}
      >
        All
      </button>
    )
    let parCount = (
      <>
        <input
          type="number"
          className="input-number w3em"
          value={1 * Xstate.posts.filters.advanced.parMin}
          step="1"
          onChange={event => {
            Xstate.posts.filters.advanced.parAll = 0
            Xstate.posts.filters.advanced.parMin = 1 * event.target.value
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        />
        &nbsp;-&nbsp;
        <input
          type="number"
          className="input-number w3em"
          value={1 * Xstate.posts.filters.advanced.parMax}
          step="1"
          onChange={event => {
            Xstate.posts.filters.advanced.parAll = 0
            Xstate.posts.filters.advanced.parMax = 1 * event.target.value
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        />
      </>
    )
    if (Xstate.posts.filters.advanced.parAll === 1) {
      buttonAll = (
        <button
          id="buttonAll"
          className="btn btn-content3 btn-small active"
          onClick={() => {
            tquery("#updateReact").click()
          }}
        >
          All
        </button>
      )
    }
    resultJSX.push(
      <span>
        <b>Par:</b>
        <div className="div-input">
          {buttonAll}
          {parCount}
        </div>
      </span>,
    )
  }

  return (
    <tr>
      <td>
        <b>Advanced Filters: </b>
      </td>
      <td>
        <button
          id="advancedFilters"
          className="btn btn-content2 btn-small"
          onClick={() => {
            Xstate.posts.advancedFilters = 0
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          Hide
        </button>
        {resultJSX}
      </td>
    </tr>
  )
}
export var PostsVenuesFiltersID = (sport = "all") => {
  console.log("PostsVenuesFiltersID", sport)
  let attributes = []
  if (Xstate.posts.filters_sport !== sport) Xstate.posts.filters_sport = sport
  else return 1

  Xstate.posts.filters_id = {}
  if (Xstate.posts.venueAttributes !== undefined) {
    if (Xstate.posts.venueAttributes !== 1) {
      Xstate.posts.venueAttributes.forEach(value => {
        if (value.sport_name.toLowerCase() === sport.toLowerCase()) {
          attributes.push(value)
        } else if (value.sport_name.toLowerCase() === "all") {
          attributes.push(value)
          tquery("#updateReact").click()
        }
      })
    }
  }
  let attributeList = [
    "free",
    "hourly_rate",
    "advance_booking",
    "busy",
    "public",
    "lights",
    "parking",
    "fountain",
    "changeroom",
    "washroom",
    "rental",
    "annual_cost",
    "membership_open",
    "membership_waitlist",
    "membership_closed",
    "null",
    "club_name",

    "indoor",
    "fullcourt",
    "count_courts",
    "holes",
    "indoor_standard_size",
    "sheltered",
    "court_size_soccer_regular",
    "court_regular",
    "outdoor",
    "halfcourt",
    "seasonal",
    "driving_range",
    "indoor_international_size",
    "court_size_soccer_mini",
    "court_glass",
    "fence",
    "seasonality_date_open",
    "mini_putt",
    "indoor_non_standard_size",
    "nets",
    "hardcourt",
    "board",
    "seasonality_date_close",
    "course_length_ft",
    "par",
    "carpet",
    "hill",
    "course_length_yard",
    "number_of_rinks",
    "grass",
    "cart_friendly",
    "lights_start",
    "clay",
    "lights_end",
    "target_baskets",
    "target_discatcher",
    "target_mach",
    "targets_other",
    "tee_concrete",
    "tee_grass",
    "tee_other",
  ]

  if (attributes.length > 0) {
    for (let a = 0; a < attributes.length; a++) {
      for (let i = 1; i <= 40; i++) {
        for (let j = 0; j < attributeList.length; j++) {
          if (attributes[a]["attribute" + i] === attributeList[j]) Xstate.posts.filters_id[attributeList[j]] = i
        }
      }
    }
  }
  tquery("#updateReact").click()
}
export var VenuesSame = id => {
  let details = {}
  if (Xstate.login.userid === "null") {
    details.user_id = "X" + Math.round(Math.random() * 10, 0)
  } else {
    details.user_id = Xstate.login.userid
  }

  var formData = new FormData()
  formData.append("section", "posts")
  formData.append("request", "same-venue")
  formData.append("venues", JSON.stringify(id))
  formData.append("user_id", details.user_id)
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:posts")
    console.log("request:same-venue")
    console.log("venues:" + JSON.stringify(id))
    console.log("user_id:" + details.user_id)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })

  setTimeout(() => {
    if (Xstate.modal.status === "text") {
      //Xstate.modal.status = "venues-view"
      Xstate.posts.modalView = "general"

      tquery("#updateReact").click()
    }
  }, 1000)
}
