import React, { Component } from "react"

import { savedText } from ".././SavedText"

import { Xstate } from "../functions/Shared"
import { DeleteTransactionConfirm, saveLeague } from "../functions/League"
//import { login } from '../account';
import { ModalJoinLeague } from ".././modal/JoinLeague"
import { ModalMessageBoard } from ".././modal/MessageBoard"
import { ModalMenu } from ".././modal/Menu"
import { ModalLogin } from ".././modal/Login"
import { ModalScores } from ".././modal/Scores"
import { ModalPosts } from ".././modal/Posts"
import { ModalUsers } from ".././modal/Users"
import { ModalMessage } from ".././modal/Message"
import { SportandLocationList } from ".././modal/SportandLocationList"
import { cleanPath, tquery } from "../app/ResponsiveLayout-20190729"
//import {fetchLink} from "../fetchLink";

var modalPath = ""

export class ShowModal extends Component {
  render() {
    if (Xstate.modal.status === undefined) Xstate.modal.status = "none"
    if (Xstate.modal.status !== "none") {
      if (modalPath === "") modalPath = cleanPath().slice(17)
      if (modalPath !== cleanPath().slice(17)) {
        modalPath = ""
        window.history.forward()
      }
    }

    var resultJSX = []
    var tCount = 0
    resultJSX.push(<ModalLogin key={tCount++} parentState={this.props.parentState} />)
    resultJSX.push(<ModalScores key={tCount++} parentState={this.props.parentState} />)
    resultJSX.push(<ModalPosts key={tCount++} parentState={this.props.parentState} />)
    resultJSX.push(<ModalUsers key={tCount++} parentState={this.props.parentState} />)
    resultJSX.push(<ModalMenu key={tCount++} parentState={this.props.parentState} />)
    resultJSX.push(<SportandLocationList key={tCount++} parentState={this.props.parentState} />)

    if (Xstate.modal.status === "joinleague")
      resultJSX.push(<ModalJoinLeague key={tCount++} parentState={this.props.parentState} />)
    if (Xstate.modal.status === "message")
      resultJSX.push(<ModalMessage key={tCount++} parentState={this.props.parentState} />)
    if (Xstate.modal.status === "message_sent") return modalText(savedText.general["Your message has been sent."])
    if (Xstate.modal.status === "league_user_already_exists")
      return modalText(savedText.general["The user is already in the league..."])
    if (Xstate.modal.status === "league-message")
      resultJSX.push(<ModalMessageBoard key={tCount++} parentState={this.props.parentState} />)
    if (Xstate.modal.status === "text") return modalText(Xstate.modal.text)
    if (Xstate.modal.status === "text-nobutton") return modalTextNoButton(Xstate.modal.text)
    if (Xstate.modal.status === "leagueDraftConfirm") {
      Xstate.modal.fxYes = () => {
        saveLeague(Xstate.modaldetails.league)
      }
      Xstate.modal.fxNo = () => {}
      Xstate.modal.status = "confirm"
    }
    if (Xstate.modal.status === "leagueTransactionDeleteConfirm") {
      Xstate.modal.fxYes = DeleteTransactionConfirm
      Xstate.modal.fxNo = () => {}
      Xstate.modal.status = "confirm"
    }
    if (Xstate.modal.status === "confirm") {
      return modalConfirm(Xstate.modal.text, Xstate.modal.fxYes, Xstate.modal.fxNo)
    }

    return <span> {resultJSX} </span>
  }
}
export const modalConfirm = (message, fxYes, fxNo) => {
  return modalOuter(
    <div>
      {message}
      {savedText.oneLine}
      <button
        id="confirmButton"
        className="btn btn-content1"
        onClick={event => {
          Xstate.modal.text = "Saving"
          Xstate.modal.status = "text"
          tquery("#updateReact").click()
          fxYes()
          setTimeout(() => {
            //tquery("#club-dashboardx").click();
            Xstate.modal.status = "none"
            tquery("#updateReact").click()
          }, 1000)
        }}
      >
        Confirm
      </button>
      <button
        id="closeButton"
        className="btn btn-content1"
        onClick={event => {
          Xstate.modal.status = "none"
          Xstate.club.challengeID = undefined
          Xstate.club.challengeLabel = undefined
          Xstate.club.challengeResult = undefined
          fxNo()
          tquery("#updateReact").click()
        }}
      >
        Go back
      </button>
    </div>,
  )
}
export const modalHeader = (
  <div className="modal-header">
    &nbsp;
    <span className="p100 right">
      <button
        id="closeButton"
        className="close btn"
        onClick={event => {
          Xstate.modal.status = "none"
          Xstate.posts.msg = ""
          Xstate.posts.replyEmail = "0"
          Xstate.posts.msg = ""
          Xstate.JSX.locationOutputJSX = []
          Xstate.JSX.locationPostCodeJSX = []
          Xstate.location[0].fieldResult = []
          Xstate.location[0].field = ""
          Xstate.club.challengeID = undefined
          Xstate.club.challengeLabel = undefined
          Xstate.club.challengeResult = undefined
          tquery("#updateReact").click()
          if (Xstate.modal.focus !== "") {
            setTimeout(() => {
              tquery(Xstate.modal.focus).scrollIntoView()
              Xstate.modal.focus = ""
            }, 50)
          }
        }}
      >
        &times;&nbsp;
      </button>
    </span>
    <h2 id="popupheader"> </h2>
  </div>
)
export const modalFooter = (
  <div className="modal-header">
    &nbsp;
    <h3 id="popupfooter"> </h3>
  </div>
)
export const modalText = message => {
  let thisMessage = <span id="modalText">{message}</span>
  return modalOuter(
    <div>
      {thisMessage}
      {savedText.oneLine}
      <button
        id="closeButton"
        className="btn btn-content1"
        onClick={event => {
          Xstate.modal.status = "none"
          Xstate.club.challengeID = undefined
          Xstate.club.challengeLabel = undefined
          Xstate.club.challengeResult = undefined
          tquery("#updateReact").click()
          if (Xstate.modal.focus !== "") {
            setTimeout(() => {
              tquery(Xstate.modal.focus).scrollIntoView()
              Xstate.modal.focus = ""
            }, 50)
          }
        }}
      >
        X Close
      </button>
    </div>,
  )
}
export const modalTextNoButton = message => {
  let thisMessage = message

  if (typeof message === "string") {
    thisMessage = <span>{message}</span>
  }
  return modalOuter(
    <div>
      <span id="modalText">{thisMessage}</span>
    </div>,
  )
}
export const modalOuter = modalInner => {
  return (
    <div className="modal">
      <table style={{ height: "100%", width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <div className="modal-content">
                {modalHeader}
                <div className="modal-inner-content">{modalInner}</div>
                {modalFooter}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
