import React, { Component } from "react"
import { Xstate } from "../functions/Shared"
import { tquery } from "../app/ResponsiveLayout-20190729"
import { modalTextNoButton } from "../app/AppModal"

export class ModalJoinLeague extends Component {
  modalTitle = (<h2> Join Club </h2>)

  adminDetails = (
    <span>
      Send Email
      <b> {Xstate.club.details.dbLeagueGeneral[0].username} </b>(Club Admin:)
    </span>
  )

  render() {
    //>>>> TO DO ON CLICK
    return modalTextNoButton(
      <div>
        {this.modalTitle}
        <br />
        {this.adminDetails}
        <br />
        <br />
        <b> Subject: </b> Join Club-
        {Xstate.club.details.dbLeagueGeneral[0].league_name}
        <br />
        <textarea
          id="messageBoardAddMessageInput"
          wrap="soft"
          className="input textarea input-large"
          value={Xstate.posts.currentPost.content}
          onChange={event => {
            Xstate.posts.currentPost.content = event.target.value
            tquery("#updateReact").click()
          }}
        />
        <br />
        <br />
        <button id="sendEmail" className="btn btn-content1" onClick={() => {}}>
          Send Email
        </button>
      </div>,
    )
  }
}
