import React, { Component } from "react"

/*
<script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({
          google_ad_client: "ca-pub-5766742203172087",
          enable_page_level_ads: true
     });
</script>
*/
export class AdComponent extends Component {
  render() {
    return (
      <span></span>
      //    <ins className='adsbygoogle'
      //      style={{ display: 'block' }}
      //      data-ad-client='ca-pub-5766742203172087'
      //      /*data-ad-slot='12121212'*/
      //      data-ad-format='auto' /> */}
    )
  }
}
