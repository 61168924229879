import { savedText } from "../SavedText"
import { tquery } from "../app/ResponsiveLayout-20190729"
import { Xstate } from "../functions/Shared"
import React from "react"
import { NavLink } from "react-router-dom"

export var kofi = () => {
  //Only run once per session... to do make this more robust

  if (Xstate.kofi === undefined) {
    //KOFI
    if (Date.now().toString().endsWith("00")) {
      if (Xstate.modal.status === "none") {
        Xstate.kofi = "Done!"
        Xstate.modal.status = "text"
        Xstate.modal.text = (
          <span>
            <b> Thanks for Your Support! </b>
            {savedText.oneLine}
            Connactive Club was created to help people get active and we are glad you are giving it a go!
            {savedText.oneLine}
            We have big plans, so if you are enjoying yourself and want to contribute to our long term growth consider
            buying us a coffee!
            {savedText.oneLine}
            The site is entirely free, has no advertising, and we aren't harvesting your data. We just want to help you
            get active.
            {savedText.oneLine}
            <button
              className="btn btn-red fa fa-coffee"
              title="support connactive club - buy us a coffee"
              onClick={() => {
                window.open("https://ko-fi.com/connactiveclub")
                tquery("#updateReact").click()
              }}
            >
              {savedText.nbsp}KO-FI
            </button>
            {savedText.oneLine}
            <div className="green-line"></div>
          </span>
        )
        tquery("#updateReact").click()
      }
    }
    //ACCOUNT
    if (Xstate.login.status !== true) {
      if (Xstate.modal.status === "none") {
        if (Date.now().toString().endsWith("10")) {
          Xstate.kofi = "Done!"
          Xstate.modal.status = "text"
          Xstate.modal.text = (
            <span>
              <b> Life is better with an account. </b>
              {savedText.oneLine}
              Connactive Club is about making connections - and to do that it is better if you make an account.
              {savedText.oneLine}
              An account will let you find players to play with, create your own league, and much more.{" "}
              {savedText.oneLine}
              P.S. we don't have any newsletters or anything like that.
              {savedText.oneLine}
              <button
                className="btn btn-red"
                onClick={() => {
                  Xstate.login.register = {}
                  Xstate.modal.status = "register"
                  tquery("#updateReact").click()
                }}
              >
                Register
              </button>
              {savedText.oneLine}
              <div className="green-line"></div>
            </span>
          )
          tquery("#updateReact").click()
        }
      }
    }
    //LOCATION
    if (Xstate.login.status === true) {
      if (Xstate.modal.status === "none")
        if (Xstate.login.city === null) {
          if (Date.now().toString().endsWith("20")) {
            Xstate.kofi = "Done!"
            Xstate.modal.status = "text"
            Xstate.modal.text = (
              <span>
                <b> Location, Location, Location </b>
                {savedText.oneLine}
                We assume you don't want to drive 200km for a pickleball match, so to help us help you consider adding
                your location. {savedText.oneLine}
                This will help with player search and looking for a nearby venue.
                {savedText.oneLine}
                We will be discreet (we will not share your location with others and we will only store your approximate
                location).
                {savedText.oneLine}
                <button
                  id="kofi-account"
                  onClick={event => {
                    Xstate.modal.status = ""
                    tquery("#updateReact").click()
                    setTimeout(() => {
                      tquery("#loginButton").click()
                    }, 500)
                  }}
                  className="btn btn-red"
                  to="/account"
                >
                  {savedText.nbsp}Add a location
                </button>
                {savedText.oneLine}
                <div className="green-line"></div>
              </span>
            )
            tquery("#updateReact").click()
          }
        }
    }
    //AVAILABILITY
    if (Xstate.login.status === true) {
      if (Xstate.modal.status === "none")
        if (Date.now().toString().endsWith("30")) {
          Xstate.kofi = "Done!"
          Xstate.modal.status = "text"
          Xstate.modal.text = (
            <span>
              <b> Update your availability </b>
              {savedText.oneLine}
              Do you work 9 - 5 and want to find someone who can play before work? Keep your availability up to date and
              you can search for players that have similar availability. {savedText.oneLine}
              We aren't looking for your entire schedule, just indicate if you are is Unavailable, Available, or your
              Favourite time. Trust me it will make it easier to schedule a game!
              {savedText.oneLine}
              <button
                id="kofi-account"
                onClick={event => {
                  Xstate.modal.status = ""
                  tquery("#updateReact").click()
                  setTimeout(() => {
                    tquery("#loginButton").click()
                  }, 500)
                }}
                className="btn btn-red"
                to="/account"
              >
                {savedText.nbsp}Update Availability
              </button>
              {savedText.oneLine}
              <div className="green-line"></div>
            </span>
          )
          tquery("#updateReact").click()
        }
    }
    //ACCOUNT2
    if (!Xstate.login.status) {
      if (Xstate.modal.status === "none")
        if (Date.now().toString().endsWith("40")) {
          Xstate.kofi = "Done!"
          Xstate.modal.status = "text"
          Xstate.modal.status = "text"
          Xstate.modal.text = (
            <span>
              <h3> Join the Club! </h3>
              It looks like you are enjoying the club. Thanks for giving it a go!
              <br />
              <br /> Want to make this official (like Facebook status official)? If you create an account then you can
              get even more features!
              <br />
              <br />
              We can help you find other players to play with and you can create your own leagues! <br />
              <br />
              <button
                className="btn btn-content1"
                onClick={() => {
                  Xstate.login.register = {}
                  Xstate.modal.status = "register"
                  tquery("#updateReact").click()
                }}
              >
                Register
              </button>
              <br />
              <br />
              <br />
              <br />
              We understand if you're not ready :(
              <br />
              <b>Have a great match! </b>
            </span>
          )
          Xstate.modal.fxNo = () => {
            Xstate.modal.status = "none"
            tquery("#updateReact").click()
          }
          tquery("#updateReact").click()
        }
    }
  }
}
