import React, { Component } from "react"
import { message } from "../App"
import { tquery } from "../app/ResponsiveLayout-20190729"
import { fetchLink } from "../fetchLink"
import { savedText } from "../SavedText"
import { Xstate } from "../functions/Shared"
import { getUserID } from "../functions/League"
import { modalTextNoButton } from "../app/AppModal"

const sendEmail = (emailType, user = 0) => {
  //Mass Email - no personal information
  if (user === 0) {
    if (emailType === "message") {
      let formData = new FormData()
      formData.append("section", "email")
      formData.append("details", JSON.stringify(Xstate.message))
      if (Xstate.testing === "on") {
        console.log("FETCH")
        console.log("section:email")
        console.log("details:" + JSON.stringify(Xstate.message))
      }
      fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
        .then(function (response) {
          return response.text()
        })
        .then(function (data) {
          console.log(data)
          if (data.trim() !== "SENT") {
            Xstate.modal.text =
              "Hmmm... that didn't work, sorry!  We will fix it, but in the meantime maybe give your friend a call instead."
            Xstate.modal.status = "text"
          } else {
            Xstate.modal.status = "message_sent"
            if (Xstate.modal.fxYes !== undefined) Xstate.modal.fxYes()
          }
          tquery("#updateReact").click()
        })
    }
  }

  //Specific email to each user in the league
  else if (emailType === "message") {
    Xstate.message.contentIntro = Xstate.JSX.msgIntroduction
    Xstate.message.content = Xstate.message.contentIntro + "<br/><hr><br/>" + Xstate.message.content
    Xstate.message.to.forEach(x => {
      let tempMessage = { ...Xstate.message }
      let formData = new FormData()
      formData.append("section", "email")
      tempMessage.to = [x]
      tempMessage.url = ("" + Xstate.message.url).replace("~USERID~", getUserID(x))
      formData.append("details", JSON.stringify(tempMessage))
      if (Xstate.testing === "on") {
        console.log("FETCH")
        console.log("section:email")
        console.log(`to:${x} (FYI ONLY)`)
        console.log("details:" + JSON.stringify(tempMessage))
      }
      fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
        .then(function (response) {
          return response.text()
        })
        .then(function (data) {
          console.log(data.trim())
          if (data.trim() !== "SENT") {
            Xstate.modal.text =
              "Hmmm... that didn't work, sorry!  We will fix it, but in the meantime maybe give your friend a call instead."
            Xstate.modal.status = "text"
          } else {
            Xstate.modal.status = "message_sent"
            if (Xstate.modal.fxYes !== undefined) Xstate.modal.fxYes()
          }
          tquery("#updateReact").click()
        })
    })
  }
}

export class ModalMessage extends Component {
  setup = () => {
    //Title
    if (Xstate.message.title === undefined) Xstate.message.title = "Send Email"
    if (Xstate.message.subject === undefined) Xstate.message.subject = ""

    //toText()
    if (Xstate.message.to === undefined) Xstate.message.to = []
    Xstate.message.toText = () => {
      let tCount = 6713
      let thisResult = ""
      let usersJSX = []
      if (message) {
        if (Xstate.message.toList === "league-users") {
          Xstate.message.to = []
          if (Xstate.message.users === undefined) Xstate.message.users = [0]
          let maxCount = 0
          Xstate.message.users.forEach(user => {
            if (1 * user > 0) maxCount = 1
            else user = 0
          })
          if (maxCount < 1) {
            usersJSX.push(
              <span key={tCount++}>
                Select users below <b className="red-font">(none selected): </b>
                <br />
              </span>,
            )
          } else {
            usersJSX.push(
              <span key={tCount++}>
                Select users below:
                <br />
              </span>,
            )
          }
          let repeatUser = "~"
          for (let i = 0; i < Xstate.club.details.dbLeagueUsers.length; i++) {
            let thisUser = Xstate.club.details.dbLeagueUsers[i]

            //Break if temporary
            if (thisUser.userid.substring(0, 1) === "X") continue

            //Real Users
            let username = "" + thisUser.username
            if (repeatUser.search(username) > 0) Xstate.message.users[i] = 0
            else {
              repeatUser += username + "~"
              if (1 * Xstate.message.users[i] !== 1) {
                Xstate.message.users[i] = 0
                usersJSX.push(
                  <span
                    id={"email" + i}
                    key={tCount++}
                    className="btn btn-content1 btn-inactive btn-small"
                    user_id={i}
                    onClick={event => {
                      Xstate.message.users[event.target.getAttribute("user_id")] = 1
                      tquery("#updateReact").click()
                    }}
                  >
                    {username}
                  </span>,
                )
              } else {
                Xstate.message.to.push(username)
                usersJSX.push(
                  <span
                    id={"emailActive" + i}
                    key={tCount++}
                    className="btn btn-content1 btn-small active"
                    user_id={i}
                    onClick={event => {
                      Xstate.message.users[event.target.getAttribute("user_id")] = 0
                      tquery("#updateReact").click()
                    }}
                  >
                    {username}
                  </span>,
                )
              }
            }
          }
          return <span>{usersJSX}</span>
        } else if (Xstate.message.to.length > 0) {
          Xstate.message.to.forEach(thisTo => {
            thisResult += thisTo + ","
          })
          return thisResult.substring(0, thisResult.length - 1)
        } else {
          return "No Users Selected"
        }
      }
    }

    //Button
    Xstate.JSX.msgButton = (
      <span>
        {savedText.halfLine}
        <br />
      </span>
    )
    if (Xstate.message.type === undefined) Xstate.message.type = "email"
    if (Xstate.message.type === "email") {
      if (Xstate.message.to.length > 0 || Math.max.apply(null, Xstate.message.users) > 0) {
        Xstate.JSX.msgButton = (
          <div className="center">
            {savedText.halfLine}
            <button
              id="sendEmail"
              className="btn btn-content1"
              onClick={() => {
                if (Xstate.message.to.length === 0) {
                  for (let i = 0; i < Xstate.club.details.dbLeagueUsers.length; i++) {
                    if (Xstate.message.users[i] === 1)
                      Xstate.message.to.push(Xstate.club.details.dbLeagueUsers[i].username)
                  }
                }
                sendEmail("message", 1)
                Xstate.modal.status = "text-nobutton"
                Xstate.modal.text = "Loading..."
                tquery("#updateReact").click()
              }}
            >
              Send Email
            </button>
          </div>
        )
      }
    }
  }

  render() {
    this.setup()
    if (Xstate.login.status === false) {
      return modalTextNoButton(<> Please login to send an email! </>)
    }
    if (Xstate.message.content === undefined) Xstate.message.content = ""
    return modalTextNoButton(
      <>
        <h2> {Xstate.message.title} </h2>
        <div className="div-input">
          <div className="table-center left">
            <b> To: </b>
            <div className="div-input"> {Xstate.message.toText()} </div>
            <br />
            <br />
            <b> Subject: </b>
            <br />
            <input
              type="text"
              className="div-input input input-large"
              value={Xstate.message.subject}
              onChange={event => {
                Xstate.message.subject = event.target.value
                tquery("#updateReact").click()
              }}
            />
            <br />
            <br />
            <b>Body:</b>
            <br />
            <br />
            <div className="div-input">{Xstate.JSX.msgIntroduction} </div>
            <textarea
              wrap="soft"
              className="div-input input textarea input-large"
              onChange={event => {
                Xstate.message.content = event.target.value
                tquery("#updateReact").click()
              }}
            />
            <br />
            <br />
            <div className="div-input"> {Xstate.JSX.msgConclusion}</div>
            {Xstate.JSX.msgButton}
          </div>
        </div>
      </>,
    )
  }
}
