import React from "react"
//import {NavLink} from "react-router-dom";
import bannerSmall from "../img/banner.png"
import Picture1 from "../img/Picture1.webp"
import Picture2 from "../img/Picture2.webp"
import Picture3 from "../img/Picture3.webp"
import Picture4 from "../img/Picture4.webp"
import Picture5 from "../img/Picture5.webp"
import Picture6 from "../img/Picture6.webp"
import PictureA from "../img/PictureA.webp"
import PictureB from "../img/PictureB.webp"
import PictureC from "../img/PictureC.webp"
import { Xstate } from "../functions/Shared"
import { tquery } from "./ResponsiveLayout-20190729"
import { savedText } from "../SavedText"

export const LandingPage = () => {
  let picture = Picture1
  let d = new Date()
  let thisRandom = d.getMinutes()
  if (thisRandom < 10) {
    picture = Picture2
  } else if (thisRandom < 20) {
    picture = Picture3
  } else if (thisRandom < 30) {
    picture = Picture4
  } else if (thisRandom < 40) {
    picture = Picture5
  } else if (thisRandom < 50) {
    picture = Picture6
  }
  return (
    <div className="row row-landing1">
      <div className="landing-page-header">
        <div className="p100 right ">
          <button
            id="getStarted"
            className="z10000 right btn btn-premium font100"
            onClick={event => {
              Xstate.landingPage = 0
              Xstate.subLandingPage = 0
              tquery("#updateReact").click()
            }}
          >
            Get Started
          </button>
          <br />
          <img
            id="img-getStarted"
            src={picture}
            onClick={event => {
              Xstate.landingPage = 0
              Xstate.subLandingPage = 0
              tquery("#updateReact").click()
            }}
            alt=""
          ></img>
        </div>
      </div>
      <div className="landing-page-content p100 center">
        <h1>Connactive Club</h1>
        <h4> Play. Compete. Have Fun. </h4>
        <b>Connactive Club </b> is for anyone who is looking to have fun through playing sports and competing against
        friends.
        {savedText.halfLine}
        <div className="col-md-12 left gold-greenborder landingSub">
          <div className="p50 center ">
            <img src={PictureA} style={{ maxWidth: "15em", width: "100%", height: "auto" }} alt=""></img>
          </div>
          <div className="p50 left">
            <h4> Get connected with other players </h4>
            You can find other players to play your favourite sports or group activities with using Connactive Club
            Player Search Tool
          </div>
        </div>
        <div className="col-md-12 left gold-greenborder  landingSub">
          <div className="p50 left">
            <h4> Manage Club Expenses</h4>
            You can manage your club’s expense conveniently including adding and settling using Connactive Club Cash
            Tool
          </div>
          <div className="p50 center ">
            <img src={PictureB} style={{ maxWidth: "15em", width: "100%", height: "auto" }} alt=""></img>
          </div>
        </div>
        <div className="col-md-12 left gold-greenborder landingSub">
          <div className="p50 center ">
            <img src={PictureC} style={{ maxWidth: "15em", width: "100%", height: "auto" }} alt=""></img>
          </div>
          <div className="p50 left">
            <h4> Find a Venue to Play Your Favourite Sport</h4>
            Find nearby venues using user driven Connactive Club Venues Database
          </div>
        </div>
        <div className="col-md-12 center font 100">
          <br />
          <button
            className="btn btn-premium btn-large"
            id="LandingPageButton"
            onClick={event => {
              Xstate.landingPage = 0
              tquery("#updateReact").click()
            }}
          >
            Get Started
          </button>
        </div>
        &nbsp;
        <br />
      </div>
    </div>
  )
}
