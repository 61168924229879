import React from "react"

//Initial so that we can use nbsp and tab in other variables
export var savedText = {
  nbsp: "\u00A0",
  tab: "\u00A0 \u00A0 \u00A0 \u00A0 \u00A0",
  halfLine: <div className="font-small">&nbsp;</div>,
  oneLine: <div>&nbsp;</div>,
  twoLines: (
    <>
      <br /> &nbsp; <br /> &nbsp; <br />
    </>
  ),
  threeLines: (
    <>
      <br /> &nbsp; <br /> &nbsp; <br /> &nbsp; <br />
    </>
  ),
}
savedText = {
  nbsp: "\u00A0",
  tab: "\u00A0 \u00A0 \u00A0 \u00A0 \u00A0",
  halfLine: <div className="font-small">&nbsp;</div>,
  oneLine: <div>&nbsp;</div>,
  twoLines: (
    <>
      <br /> &nbsp; <br /> &nbsp; <br />
    </>
  ),
  threeLines: (
    <>
      <br /> &nbsp; <br /> &nbsp; <br /> &nbsp; <br />
    </>
  ),
  home: {
    connactive_description: (
      <span>
        <b>Connactive Club </b> is for anyone who is looking to play sports with friends or who wants to meet new people
        and play with new people. <br /> We offer integrated tools and resources that help users to have more enjoyable
        experience playing sports. {savedText.halfLine}So let's get out and play, use our competitive spirit and most
        importantly let's have fun.
      </span>
    ),
    home: <span>Home</span>,
    player_search: <span>Player Search</span>,
    about_us: <span>About Us</span>,
    venues_description: (
      <span>
        <b> Venues </b> is a user driven search engine to help people find a location to play their sport based on
        detailed search results.
      </span>
    ),
    search_description: (
      <span>
        <b> Player Search </b> is a tool to help people find other people to play their sport with. This includes
        joining a team/club or finding a player for matchup.
      </span>
    ),
  },
  descriptions: {
    webname: <span>connactive</span>,
    tagline: <span>play. compete. have fun.</span>,
    connactive_description: (
      <span>
        <b>welcome to Connactive Club. </b>play. compete. have fun. <br />
        we provide tools to help you play sports and be active <br /> and <b> play </b> <br /> so let's get those
        <b> competitive </b> juices flowing <br /> and let's <b> have fun </b> {savedText.halfLine}
        <b> give it a go </b> to get your club started, we can help you build a schedule and manage your standings.{}
        {savedText.halfLine}
        <b> club - scheduler </b>
        <br /> create your schedule in 10 minutes <br /> up to 32 teams <br /> round robin or playoff or both <br />
        dates and times - great to schedule a tournament or weekly league <br />
        <b>
          <i> super easy to use. </i>
        </b>
        {savedText.halfLine}
        <b> league - standings </b>
        <br /> track your standings on the fly <br /> enter scores and the league table will automatically update <br />
        <b>
          <i> keep up to date on the fly </i>
        </b>
        {savedText.halfLine}
      </span>
    ),
    description1: <span>We have tools to help you create and manage a league or tournament.</span>,
    description2: <span>We can create your schedule, record the scores, and update the league table.</span>,
    venues_description: (
      <span>
        need to find a place to play? is it open? <br /> <br /> our database is updated by users so you can work
        together to find a court/pitch/field near you
      </span>
    ),
    search_description: (
      <span>
        looking to play someone new or join a team? {savedText.halfLine}we can help you find a match or team based on
        your location and skill level
      </span>
    ),
    orange_description: (
      <span>
        buying? selling? <br /> let's reuse and recycle that old equipment. {savedText.halfLine}got an event? <br /> let
        our users know {savedText.halfLine}we'll also help you post to social media
      </span>
    ),
  },
  error: {
    general: <span>Sorry, we ran into an error. Please try again.</span>,
  },
  general: {
    "The user is already in the league...": (
      <span>The user is already in the league. If you can't see them below, please contact the league admin.</span>
    ),
    "Your message has been sent.": <span>Your message has been sent.</span>,
    end: <span></span>,
  },
  club: {
    input_error: "Sorry, there are some errors.  Please fix these before continuing.",
    //info
    info_cash: (
      <span>
        <b> Cash Management </b>
        {savedText.halfLine}Split those bills.
        {savedText.halfLine}A simple but effective approach to sharing costs between players/teams in a club. You can
        track expenses and payments. Easy peasy.
      </span>
    ),
    info_schedule: (
      <span>
        <b> Schedule </b>
        {savedText.halfLine}
        Just like the NHL. {savedText.halfLine}Our core feature, create a schedule for a league, including round robin
        and playoffs. Game on.
      </span>
    ),
    info_ladder: (
      <span>
        <b> Ladder </b>
        {savedText.halfLine}King of the hill. {savedText.halfLine}Sometimes you don't have a schedule but you want to
        know who is top of the hill. A ladder is a great way to track scores and standings. If you're not first, your
        last.
      </span>
    ),
    info_draft: (
      <span>
        <b> Draft </b>
        {savedText.halfLine}With the first selection... {savedText.halfLine}Want to add an extra layer of fun. A draft
        gives you a way to select teams for your local tournament or select players in NHL20. GM Mode.
      </span>
    ),
    info_events: (
      <span>
        <b> Events </b>
        {savedText.halfLine}A calendar for your club {savedText.halfLine}Create events and track attendance. Simple but
        necessary for managing a club.
      </span>
    ),
    info_discussion: (
      <span>
        <b> Message Board </b> {savedText.halfLine}An old school chatroom {savedText.halfLine}A simple way to
        communicate with the league. This is great for clubs that don't want another Whatsapp group.
      </span>
    ),
    join_league_details0: (
      <span>Want to join this ladder? Please log in and you can request to join the league now.</span>
    ),
    join_league_details1: <span>Want to join this ladder? Click here to email the league admin.</span>,
    join_league_details2: (
      <span>Are you in this league? Please log in and you can email the league admin and be assigned to a team.</span>
    ),
    join_league_details3: (
      <span>Are you in this league? Click here to email the league admin and be assigned to a team.</span>
    ),

    //PREMIUM
    premium_divisions: (
      <>
        <b>Premium Feature</b>
        {savedText.oneLine}
        This is a premium feature: more than one division.
        {savedText.oneLine}
        Premium features take your schedule to the next level. This isn't small potatoes anymore.
        {savedText.oneLine}
        Premium schedules cost $10 (total), but our tool will save you hours so you can use the time savings for
        something more productive (maybe a good book)
        {savedText.oneLine}
        P.S. founding members can create unlimited premium schedules.
        {savedText.oneLine}
      </>
    ),
    pro_divisions: (
      <>
        <b>Pro Feature</b>
        {savedText.oneLine}
        This is a pro feature: more than five divisions.
        {savedText.oneLine}
        Pro features are for advanced schedules, and require some complicated algorithms to make sure we get the right
        schedule for your league.
        {savedText.oneLine}
        This feature will cost $1 <b>per team/player</b>, but we are sure that it will save you lots of time - maybe you
        can go for a run instead?
        {savedText.oneLine}
      </>
    ),
    premium_teams: (
      <>
        <b>Premium Feature</b>
        {savedText.oneLine}
        This is a premium feature: more than 32 teams.
        {savedText.oneLine}
        Premium features take your schedule to the next level. This isn't small potatoes anymore.
        {savedText.oneLine}
        Premium schedules cost $10 (total), but our tool will save you hours so you can use the time savings for
        something more productive (maybe a good book)
        {savedText.oneLine}
        P.S. founding members can create unlimited premium schedules.
        {savedText.oneLine}
      </>
    ),
    pro_teams: (
      <>
        <b>Pro Feature</b>
        {savedText.oneLine}
        This is a pro feature: more than 100 teams.
        {savedText.oneLine}
        Pro features are for advanced schedules, and require some complicated algorithms to make sure we get the right
        schedule for your league.
        {savedText.oneLine}
        This feature will cost $1 <b>per team/player</b>, but we are sure that it will save you lots of time - maybe you
        can go for a run instead?
        {savedText.oneLine}
      </>
    ),
    premium_games: (
      <>
        <b>Premium Feature</b>
        {savedText.oneLine}
        This is a premium feature: more than 200 games.
        {savedText.oneLine}
        Premium features take your schedule to the next level. This isn't small potatoes anymore.
        {savedText.oneLine}
        Premium schedules cost $10 (total), but our tool will save you hours so you can use the time savings for
        something more productive (maybe a good book)
        {savedText.oneLine}
        P.S. founding members can create unlimited premium schedules.
        {savedText.oneLine}
      </>
    ),
    pro_games: (
      <>
        <b>Pro Feature</b>
        {savedText.oneLine}
        This is a premium feature: more than 1000 games.
        {savedText.oneLine}
        Pro features are for advanced schedules, and require some complicated algorithms to make sure we get the right
        schedule for your league.
        {savedText.oneLine}
        This feature will cost $1 <b>per team/player</b>, but we are sure that it will save you lots of time - maybe you
        can go for a run instead?
        {savedText.oneLine}
      </>
    ),
    premium_locations: (
      <>
        <b>Premium Feature</b>
        {savedText.oneLine}
        This is a premium feature: a random or distance based location approach.
        {savedText.oneLine}
        Premium features take your schedule to the next level. This isn't small potatoes anymore.
        {savedText.oneLine}
        Premium schedules cost $10 (total), but our tool will save you hours so you can use the time savings for
        something more productive (maybe a good book)
        {savedText.oneLine}
        P.S. founding members can create unlimited premium schedules.
        {savedText.oneLine}
      </>
    ),
    pro_locations: (
      <>
        <b>Pro Feature</b>
        {savedText.oneLine}
        This is a pro feature: special locations.
        {savedText.oneLine}
        Pro features are for advanced schedules, and require some complicated algorithms to make sure we get the right
        schedule for your league.
        {savedText.oneLine}
        This feature will cost $1 <b>per team/player</b>, but we are sure that it will save you lots of time - maybe you
        can go for a run instead?
        {savedText.oneLine}
      </>
    ),
    premium_league: (
      <span>
        *premium feature* <br /> your schedule contains premium features <br /> <br /> premium features take your club
        to the next level. this isn't small potatoes anymore <br /> <br /> premium schedules cost $10, but our tool will
        save you hours so you can use the time savings for something more productive (maybe a good book) <br /> <br />
        p.s. founding members can create unlimited premium schedules.
      </span>
    ),

    //ERROR
    error_dne: (
      <span>
        The club you selected does not exist, please select a different club
        <br />
      </span>
    ),
    error_other: (
      <span>
        Sorry, an error has occurred, please try again
        <br />
      </span>
    ),
    error_private: (
      <span>
        The club you selected is private, please log in or select a different club
        <br />
      </span>
    ),
    error_logged_out_new: (
      <span>
        Every club needs an administrator. Please log in or create an account to start your club. It's quick and easy.
      </span>
    ),
    error_settings: (
      <span>
        To view and update the settings you must be the club administrator. <br />
      </span>
    ),

    //SECTION INTRODUCTIONS
    basicInfo: (
      <span>
        Let's gather the details to set up your club. We have split it into a few short steps and you can always go back
        and change your mind before locking it in. So let's get this club started.
      </span>
    ),
    format_description: (
      <span>
        We will let you keep things simple and add the details where needed. Check out the info buttons for more info.
      </span>
    ),
    times: (
      <span>
        We are getting into the nitty-gritty here. Choose between simplicity (sessions) or details (times). If you have
        friends like mine make sure you remind them to show up on time or your schedule will fall behind pretty fast.
      </span>
    ),
    teams_multidivision: (
      <span>
        Your league has multiple divisions. Please enter all of the teams here and we will sort them into their
        divisions later
      </span>
    ),
    games: (
      <span>We will need to calculate the number of matches in your league, so let's get some more details. </span>
    ),
    games_multidivision: <span>Since your league has multiple divisions, take them one at a time. </span>,
    games_custom: (
      <span>Good news mate. Since your league has a custom schedule, you can skip this step. Nice one. </span>
    ),

    //DASHBOARD
    dashboard: {
      notInLeague: <span>Club members can see ther matchups here. Please talk to the club admin.</span>,
      end: <span></span>,
    },
    //SCHEDULE INFO
    inputs_invalid: (
      <span>
        <br /> Please update the basic info so we can set the structure first
      </span>
    ),
    build1: <span>The following information is used to build your schedule. {savedText.halfLine}</span>,
    build2: (
      <span>
        Updating these inputs will change the schedule and may result in a loss of data. {savedText.halfLine}
        Alternatively, you can create a new club. <br />
      </span>
    ),
    inputs_playoff_games: (
      <span>
        Do you have time for an epic best 4 out of 7 or do you prefer a one match winner take all <br /> <br /> There
        are different ways to allow teams to play even after being knocked out, choose one of the three
      </span>
    ),
    inputs_playoff_teams: (
      <span>
        Everyone loves playoffs <br /> <br /> Enter the number of teams and if you want us to randomly assign the
        matchups or if your teams are ranked (team 1 is the best team)
      </span>
    ),
    inputs_rr_games: (
      <span>
        Teams only play other teams in their group <br /> <br /> How many times should teams play eachother?{}
        {savedText.halfLine}Enter the number of cycles below <br /> <br /> For example a single cycle would see each
        team play every team in their group once
      </span>
    ),
    inputs_rr_teams: (
      <span>
        For a round robin, each team plays each team in the groups <br /> <br /> Please enter the number of teams you
        have <br /> <br /> And if you want to divide your teams into groups we can assign the groups in order, or we can
        randomly split up the teams for you
      </span>
    ),
    inputs_team_names: (
      <span>
        Please include teams for all divisions here and we can assign them to their divisions and groups later
      </span>
    ),
    inputs_timings: (
      <span>
        Simple: <br /> You can have a simple schedule, with matches ordered but no set times: we call this sessions
        <br /> <br /> Detailed: <br /> Alternatively, you can add dates and times to set the shedule for one or more
        days
      </span>
    ),
    inputs_dates: (
      <span>
        If we are building your schedule, you have to add the dates so that we can book the matches into the calendar.
        <br /> <br /> This is not required if you have a custom schedule, so you can skip this step
      </span>
    ),
    inputs_divisions: (
      <span>
        Simple: <br /> A basic tournament or league should only have one division, so you can keep things simple.{}
        {savedText.halfLine}Detailed: <br /> Leagues within leagues, but don't worry it's not as complicated as the
        movie inception. You may want to have multiple schedules (a round robin followed by playoffs, separate divisions
        for teams of different levels)/ This is what divisions are for. we have a maximum of 20 divisions, if you need
        more maybe you need two leagues
      </span>
    ),
    inputs_prerequisites: (
      <span>
        Prerequisite definition: required beforehand (sorry that we couldn't use a simpler word) <br /> <br /> This is
        the division that must be completed before the current division starts. So if you are doing a round robin
        followed by a playoff, the round robin is a prerequistie of the playoff
      </span>
    ),

    //SCHEDULE ERRORS
    warning_divisions: <span>auto-fix: you need at least 1 division, and the maximum allowed is 10 divisions</span>,
    error_division_teams: <span>error: every division must have at least 2 </span>,
    warning_groups: (
      <span>auto-fix: you need at least 1 group, and the maximum for this division (based on number of teams) is </span>
    ),
    warning_tiers: <span>auto-fix: you need at least 1 tier</span>,
    warning_max_tiers: <span>auto-fix: you need at least 1 team in each tier</span>,
    error_halfGame: <span>error: this schedule has half of match, please adjust</span>,
    error_unassigned: <span>error: please assign all teams to a group or remove them from the league</span>,
    warning_playoffTeamsMin: <span>auto-fix: playoffs must have at least two teams</span>,
    warning_playoffTeamsMax: <span>auto-fix: playoffs cannot have more teams than the round robin</span>,
    warning_playoffTeams32: <span>auto-fix: playoffs can only have 32 teams... for now</span>,
    error_nameMin: <span>Error: name must be at least 5 characters.</span>,
    error_nameMax: <span>Error: name must be less than 100 characters.</span>,
    error_labelMin: <span>error: all labels must be at least 1 character</span>,
    error_labelMax: <span>error: all labels must be less than 20 characters</span>,
    warning_repeatName: <span>warning: repeat name</span>,
    error_datesOverlap: <span>error: dates and times cannot overlap</span>,
    error_gameTimes: (
      <span>error: you have too many matches and not enough dates/times, please add more dates/times</span>
    ),
    error_games: <span>error: your schedule has no matches</span>,
    warning_teamCount: <span>auto-fix: leagues must have between 2 and 1000 teams</span>,

    //NEW LEAGUE TIMES INPUT
    sessions1: <span>each session will have up to</span>,
    sessions2: <span>match(es) scheduled (based on the number of locations).</span>,
    schedule_times_type: <span>Schedule Times Type:</span>,
    match_length: <span>Match Length (minutes)</span>,
    break_required: <span>Break Required </span>,
    break_required1: <span>(this means that the team is not playing for a given timeslot)</span>,

    //SCHEDULE MODAL
    warning_edit: (
      <span>
        {savedText.halfLine}
        <b>Warning: </b> Editing match details cannot be undone. Your schedule may have conflicts (two matches in the
        same place, one team playing two matches simultaneously) or other errors as a result.
        {savedText.halfLine}
      </span>
    ),
    add_edit: (
      <span>
        {savedText.halfLine}
        <b>Warning: </b> Watch out for any conflicts (teams, locations) when adding future matches.
        {savedText.halfLine}
      </span>
    ),

    //BUILD SCHEDULE
    build_schedule: (
      <span>
        <br /> are you ready to build your schedule? <br /> <br /> building the schedule will replace any existing
        schedule based on the updated inputs (if there is an exisiting schedule) <br /> <br /> do you want to create /
        recreate the schedule? <br /> <br />
      </span>
    ),

    //SCHEDULE
    //schedule_nogames: (<span>the schedule does not contain any matches. you can build the schedule using our tool, or you can enter the schedule if it is already build. <br/> </span>),
    //schedule_nogames1: (<span>sorry, the schedule does not contain any games. please check that you have the right club id. <br/> </span>),
    //schedule_unlocked: (<span>an error has occurred, please rebuild your schedule <br/></span>),
    schedule_unscheduled: (
      <span>
        unscheduled games, that doesn't sound good. <br /> <br /> we can help. it seems that you have too many games or
        too many breaks for the timeslots that you input <br /> <br /> option 1: let's go back and rejig the schedule so
        that these games can be included <br /> <br /> option 2: delete these games and go with the games in the
        schedule <br /> <br /> choose your own adventure
      </span>
    ),

    //scores
    manage_scores_label: (
      <span>
        in hockey it's goals. in baseball it's runs. in tennis it's sets. {savedText.halfLine}what label do you want for
        your scores?
      </span>
    ),
    manage_tools: (
      <span>
        <b> we are developing tools to manage your club after you have created your schedule. </b>
        {savedText.halfLine}
        please check back with us to use these tools
        <br />
        {savedText.tab}- scores
        <br />
        {savedText.tab}- stats
        <br />
        {savedText.tab}- standings
        <br />
        {savedText.tab}- update playoff bracket"
      </span>
    ),
  },
  login: {
    create_username: <span>Create a username</span>,
    login_by_email: <span>Login by email</span>,
    login_with: <span>Login with...</span>,
    register_by_email: <span>Register by email</span>,
    register_for_new_account: <span>Register for a new account</span>,
    register_with: <span>Register with...</span>,
    register_username: (
      <span>
        usernames are public and can be seen by other website users
        {savedText.halfLine}
        usernames must be at least 2 characters long (maximum 20)
        <br />
        usernames cannot contain spaces
      </span>
    ),
    register_email: (
      <span>
        we will send you an email to activate your account, so emails must have a valid email format{" "}
        {savedText.halfLine}
        we won't share your email address with other users or send you spam, no worries
      </span>
    ),
    register_password: (
      <span>
        passwords must be at least 6 characters long...passwords must contain
        <ul>
          <li>at least one uppercase letter (A..Z)</li> <li>at least one lowercase letter (a..z)</li>
          <li>at least one number (0..9)</li>
        </ul>
      </span>
    ),
    reset_password: <span>Reset password</span>,
    registration_social: <span>Registration successful. Please Login and Let's play!</span>,
    end: <span>end</span>,
  },
  venues: {
    filters: <span>Filters</span>,
    user: <span>User:</span>,
    category: <span>Category:</span>,
    select: <span>Select</span>,
    address: <span>Address</span>,
    country: <span>Country</span>,
    email: <span>Email:</span>,
    phone: <span>Phone:</span>,
    website: <span>Website:</span>,
    comments: <span>Comments:</span>,
    something_look_wrong: <span>Something look wrong?</span>,
    message: <span>Message</span>,
    suggest_an_update: <span>Suggest an update</span>,
    back: <span>Back</span>,
    go_back: <span>Go back</span>,
    back_to_log_in: <span>Back to log in</span>,
    back_to_profile: <span>Back to Profile</span>,
    back_to_league: <span>Back to Club</span>,
    venue_check_default: <span>Is it right? Click to let us know! ... or edit it please </span>,
    venue_check_match: <span>Confirmed by you!</span>,
    venue_check_wrong: <span>The consensus is: </span>,
    venue_edit_default: <span>Typo? Error? Help us fix it please.</span>,
  },
  helmet: {
    title: "Connactive Club",
    description_home: (
      <span>
        Create a sports club. Schedule your sports league or tournament. Find a field, court or arena. Connactive Club
        is for anyone who is looking to have fun through playing sports and competing against friends
      </span>
    ),
    keywords_home: <span>Sports, League, Tournament, Round Robin, Playoff, Arena, Court, Field, Player, Tennis</span>,
    end: <span>end</span>,
  },
  aboutus: {
    connactive1: (
      <span>
        <h3> About Connactive Club</h3>A little play on words, we combined connected and active to create Connactive.
        {savedText.halfLine}
        Let’s start with active. We enjoy sports and an active lifestyle. We’d rather play a set of tennis than run on
        the treadmill, so for us an active lifestyle means sports.
        {savedText.halfLine}
        Now connected. The world today is all about being connected and while that usually means joining online networks
        and posting pictures, we knew that it can be used to help people enjoy sports.
        {savedText.halfLine}
        So, <b>Connactive Club</b> is about playing sports, together.
        {savedText.oneLine}
      </span>
    ),
    connactive2: (
      <span>
        <h3> A Connactive Future </h3>
        The idea is simple, to make organizing sports easier.
        {savedText.halfLine}
        We are still growing. We have all kinds of ideas and tools to build and that will take time.
        {savedText.halfLine}
        As we grow we want to interact with our users. What can we do to make your life easier as a sports organiser?
        our door is open for any suggestions that will help you.
        {savedText.halfLine}
        You can send us your great ideas at&nbsp;
        <a href="mailto:contactus@connactiveclub.com">contactus@connactiveclub.com</a>
        {savedText.oneLine}
        <br />
      </span>
    ),
    connactive3: (
      <span>
        <h3> Our Goals </h3>
        <b>1) Find a game easier. </b> If you are just looking to play a game sometimes it’s not so easy. John is at
        work. The football pitch is booked. The tennis court doesn’t have any lights. We want to help as much as we can
        by helping you find you a place to play and someone to play with.
        {savedText.halfLine}
        <b>2) Organise a game easier. </b>
        We want to help you, so that you can be the captain or leader without getting lost in the details. There are
        lots of tools out there already and we want to put these all in one place for you.
        {savedText.halfLine}
        <b>3) No more excel. </b>
        We use excel every day at our day jobs. It is a great program, but we want to avoid using it for our personal
        lives. We wanted to create tools that everyone can use rather than building a schedule from scratch with excel
        every time (and learning those pesky countifs() formulas).
        {savedText.oneLine}
      </span>
    ),
    end: <span></span>,
  },
  development: {
    in_development: (
      <span>
        <div class="subsection">
          sorry, we are still working on this functionality. <br />
          <br /> currently we have built the schedule functionality and are working on the league management <br />
          <br /> do you want to receive updates and help support our development? consider joining Connactive Club as a
          founding member
        </div>
      </span>
    ),
  },
}
