import React, { Component } from "react"

//import {posts} from "../functions/Posts";
import { savedText } from "../SavedText"
import { saveLeague, ClearClub, CreateClubMessage } from "../functions/League"
import { tquery } from ".././app/ResponsiveLayout-20190729"
import { Xstate } from "../functions/Shared"
import { modalTextNoButton } from "../app/AppModal"

export class ModalMessageBoard extends Component {
  render() {
    if (Xstate.club.messageDetails.title1 === undefined) {
      Xstate.club.messageDetails.title1 = "" + Xstate.club.messageDetails.title
    }

    let modalTitle = <h2> {Xstate.club.messageDetails.title1} </h2>
    let editButton = (
      <span
        onClick={() => {
          Xstate.club.messageDetails.status = "edit"
          tquery("#updateReact").click()
        }}
        className="btn btn-content1 btn-small"
      >
        <i className="far fa-edit" /> Edit
      </span>
    )
    let deleteButton = (
      <span
        onClick={() => {
          Xstate.club.messageDetails.deleted = 1
          Xstate.modal.text = "Saving"
          Xstate.modal.status = "text-nobutton"
          tquery("#updateReact").click()
          saveLeague({ dbPosts: [Xstate.club.messageDetails] })
          setTimeout(() => {
            ClearClub()
            Xstate.modal.status = "none"
            tquery("#updateReact").click()
          }, 1000)
        }}
        className="btn btn-content1 btn-small"
      >
        <span className=" far fa-edit" /> Delete
      </span>
    )
    let replyButton = (
      <span
        onClick={() => {
          CreateClubMessage("RE: " + Xstate.club.messageDetails.title)
        }}
        className="btn btn-content1 btn-small"
      >
        <span className=" fas fa-reply" /> Reply
      </span>
    )
    Xstate.JSX.modaldetails = (
      <span>
        {savedText.halfLine}
        {Xstate.club.messageDetails.datetime.substr(0, 4)}-{Xstate.club.messageDetails.datetime.substr(4, 2)}-
        {Xstate.club.messageDetails.datetime.substr(6, 2)}
        &nbsp;
        {Xstate.club.messageDetails.datetime.substr(8, 2)}:{Xstate.club.messageDetails.datetime.substr(10, 2)}
        <br />
        {Xstate.club.messageDetails.username}
        {savedText.halfLine}
        {Xstate.club.messageDetails.text}
        {savedText.halfLine}
        <span
          onClick={() => {
            Xstate.club.messageDetails = undefined
            Xstate.modal.status = "none"
            tquery("#updateReact").click()
          }}
          className="btn btn-content1"
        >
          &times;&nbsp; Close
        </span>
      </span>
    )

    if (
      Xstate.login.username === Xstate.club.messageDetails.username ||
      Xstate.club.users.details.league_view === "league admin"
    ) {
      if (Xstate.club.messageDetails.status !== "edit") {
        modalTitle = (
          <span>
            <h2> {Xstate.club.messageDetails.title1} </h2>
            {editButton}
            {deleteButton}
          </span>
        )
      } else {
        Xstate.JSX.modaldetails = (
          <span>
            {Xstate.club.messageDetails.datetime.substr(0, 4)}-{Xstate.club.messageDetails.datetime.substr(4, 2)}-
            {Xstate.club.messageDetails.datetime.substr(6, 2)}
            &nbsp;
            {Xstate.club.messageDetails.datetime.substr(8, 2)}:{Xstate.club.messageDetails.datetime.substr(10, 2)}
            <br />
            {Xstate.club.messageDetails.username}
            {savedText.oneLine}
            <b> Title </b>
            <br />
            <input
              className="input input-large"
              value={Xstate.club.messageDetails.title}
              onChange={event => {
                Xstate.club.messageDetails.title = event.target.value
                tquery("#updateReact").click()
              }}
            />
            {savedText.oneLine}
            <b> Message </b>
            <br />
            <textarea
              className="textarea input-large"
              value={Xstate.club.messageDetails.text}
              onChange={event => {
                Xstate.club.messageDetails.text = event.target.value

                tquery("#updateReact").click()
              }}
            />
            {savedText.oneLine}
            <span
              onClick={() => {
                Xstate.club.messageDetails = undefined
                Xstate.modal.status = "none"
                tquery("#updateReact").click()
              }}
              className="btn btn-content1"
            >
              &times;&nbsp; Close
            </span>
            <span
              onClick={() => {
                Xstate.modal.text = "Saving"
                Xstate.modal.status = "text-nobutton"

                tquery("#updateReact").click()

                saveLeague({ dbPosts: [Xstate.club.messageDetails] })
                setTimeout(() => {
                  ClearClub()
                  Xstate.modal.status = "none"
                  tquery("#updateReact").click()
                }, 1000)
              }}
              className="btn btn-content1"
            >
              Save Message
            </span>
          </span>
        )
      }
    } else if (Xstate.club.users.details.league_type !== "none") {
      console.log(Xstate.club.users.details.username)
      modalTitle = (
        <span>
          <h2> {Xstate.club.messageDetails.title1} </h2>
          {replyButton}
        </span>
      )
    }

    return modalTextNoButton(
      <div>
        {modalTitle}
        {Xstate.JSX.modaldetails}
      </div>,
    )
  }
}
