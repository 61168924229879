import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { savedText } from "./../SavedText"
import { Xstate } from "../functions/Shared"
import { tquery } from "../app/ResponsiveLayout-20190729"
import { modalTextNoButton } from "../app/AppModal"

export class ModalMenu extends Component {
  render() {
    if (Xstate.modal.status === "menu") {
      return modalTextNoButton(
        <>
          <div style={{ textAlign: "center", width: "100%", display: "table" }}>
            <div className="table-center center">
              <NavLink
                id="homeButton"
                className="btn btn-content1"
                exact
                to="/"
                onClick={() => {
                  Xstate.modal.status = "none"
                  tquery(".contentsection").scrollTo(0, 0)

                  tquery("#updateReact").click()
                }}
              >
                <i className=" fa fa-home"></i> Home
              </NavLink>
              <br />
              <NavLink
                id="ClubButton"
                className="btn btn-content1"
                to="/club"
                onClick={() => {
                  Xstate.modal.status = "none"
                  tquery("#updateReact").click()
                  tquery(".contentsection").scrollTo(0, 0)
                }}
              >
                <i className=" fas fa-clipboard"></i> Clubs
              </NavLink>
              <br />
              <NavLink
                id="venuesButton"
                className="btn btn-content1"
                to="/Venues"
                onClick={() => {
                  Xstate.modal.status = "none"
                  tquery(".contentsection").scrollTo(0, 0)

                  tquery("#updateReact").click()
                }}
              >
                <i className=" fa fa-globe"></i> Venues
              </NavLink>
              <br />
              <NavLink
                id="playerSearchButton"
                className="btn btn-content1"
                to="/playersearch"
                onClick={() => {
                  Xstate.modal.status = "none"
                  tquery(".contentsection").scrollTo(0, 0)

                  tquery("#updateReact").click()
                }}
              >
                <i className=" fa fa-user-friends"></i> Player Search
              </NavLink>
              <br />
              <NavLink
                id="messageBoardButton"
                className="btn btn-content1"
                to="/messageboard"
                onClick={() => {
                  Xstate.modal.status = "none"
                  tquery(".contentsection").scrollTo(0, 0)

                  tquery("#updateReact").click()
                }}
              >
                <i className=" fa fa-comment-alt"></i> Message Board
              </NavLink>
              <br />
              <NavLink
                id="aboutusButton"
                className="btn btn-content1"
                to="/aboutus"
                onClick={() => {
                  Xstate.modal.status = "none"
                  tquery(".contentsection").scrollTo(0, 0)

                  tquery("#updateReact").click()
                }}
              >
                <i className=" fa fa-info-circle"></i> {savedText.home.about_us}
              </NavLink>
              <br />
            </div>
          </div>
        </>,
      )
    }

    return <span />
  }
}
