/* The purpose of this document is to manage the screen on mobile and on computer*/
import { tracker, Xstate } from "../functions/Shared"
import { modalTextNoButton } from "./AppModal"

export function cleanPath() {
  if (window.location.pathname === "/") return ""
  if (window.location.pathname.slice(-1) === "/")
    return window.location.pathname.slice(1, window.location.pathname.length - 1)
  return window.location.pathname.slice(1, 1000)
}
export function testPathFull(path) {
  if (window.location.pathname.slice(path.length + 1) === "/" + path) return true
  if (window.location.pathname.slice(path.length + 2) === "/" + path + "/") return true
  return false
}
export function testPathRight(path) {
  if (window.location.pathname.slice(-1 * path.length) === path) return true
  if (window.location.pathname.slice(-1 * path.length - 1) === path + "/") return true
  return false
}
export function testPathLeft(path) {
  if (window.location.pathname.substr(0, path.length + 1) === "/" + path) return true
  return false
}
export function testPathContains(text) {
  if (window.location.pathname.indexOf(text) === -1) return false
  return true
}
export function tquery(css_query) {
  //hidden default is the garbage can for when an item doesn't exist
  if (!document.querySelector(".hiddendefault")) {
    var div = document.createElement("div")
    div.className = "hiddendefault"
    div.style.display = "none"
    document.querySelector("body").appendChild(div)
  }
  if (document.querySelector(css_query)) {
    return document.querySelector(css_query)
  } else {
    return document.querySelector(".hiddendefault")
  }
}
export function tqueryAll(css_query) {
  //hidden default is the garbage can for when an item doesn't exist
  if (!document.querySelector(".hiddendefault")) {
    var div = document.createElement("div")
    div.className = "hiddendefault"
    div.style.display = "none"
    document.querySelector("body").appendChild(div)
  }
  if (document.querySelectorAll(css_query)) {
    return document.querySelectorAll(css_query)
  } else {
    return document.querySelectorAll(".hiddendefault")
  }
}

export function DelayedResponsiveLayout(timeInSeconds) {
  ResponsiveLayout()
  for (let i = 1; i < 10; i++) {
    setTimeout(function () {
      ResponsiveLayout()
    }, ((i * timeInSeconds) / 10) * 1000)
  }
}

export function ResponsiveLayout() {
  if (Xstate.testing === "off") console.clear()

  choose_mode()

  //Same size p30
  same_size(".p30")
  same_size(" .div-dashboard-table")

  //Tracker
  //<<<<<>>>> We note that this adds the onclick MANY times for each button and may cause it to be slow.
  //Let's monitor this.  The potential solution is to use jquery as you can actually remove the prior
  //event listener.
  tracker()
  document.querySelectorAll(".btn").forEach(x => {
    x.addEventListener(
      "click",
      function () {
        tracker(x.id)
      },
      false,
    )
  })
  document.querySelectorAll("select").forEach(x => {
    x.addEventListener(
      "change",
      function () {
        tracker("", x.id, x.value)
      },
      false,
    )
  })
  document.querySelectorAll("input").forEach(x => {
    x.addEventListener(
      "change",
      function () {
        tracker("", x.id, x.value)
      },
      false,
    )
  })
}

function choose_mode() {
  if (Xstate.css.change === 0) {
    return 0
  }

  Xstate.css.change = 0
  if (Xstate.css.mode === "dark") {
    document.documentElement.style.setProperty("--color1", "rgba(30, 30, 30, 1)")
    document.documentElement.style.setProperty("--font1", "rgba(180,180,180,1)")
    document.documentElement.style.setProperty("--fontLink", "rgba(222, 161, 54, 1)")
  } else {
    console.log("DARK")
    document.documentElement.style.setProperty("--color1", "rgba(220,220,220,1)")
    document.documentElement.style.setProperty("--font1", "rgba(13,89,0,1)")
    document.documentElement.style.setProperty("--fontLink", "rgba(13,89,0,1)")
  }
}

function same_size(x) {
  if (tquery(x).offsetHeight > 0) {
    tquery(x).style.minHeight = 0
    let maxheight = 0
    document.querySelectorAll(x).forEach(element => {
      maxheight = Math.max(maxheight, element.offsetHeight)
    })
    document.querySelectorAll(x).forEach(element => {
      element.style.minHeight = maxheight + "px"
    })
  }
}

//ON WINDOW SIZE CHANGE THEN UPDATE WEBPAGE
var lastWindowWidth = 0
var lastWindowHeight = 0

document.body.onresize = function () {
  var windowWidth = document.body.clientWidth
  var windowHeight = document.body.clientHeight
  if (lastWindowWidth !== windowWidth) {
    ResponsiveLayout()
    lastWindowWidth = windowWidth
  }
  if (lastWindowHeight !== windowHeight) {
    ResponsiveLayout()
    lastWindowHeight = windowHeight
  }
}
