import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import bannerSmall from "../img/banner.png"
import { savedText } from "./../SavedText"
import { Xstate } from "../functions/Shared"
import { tquery } from "./ResponsiveLayout-20190729"
import { LoginModal } from "../functions/Account"

export class SportSelectionPage extends Component {
  render() {
    //var resultJSX = <span/>;
    if (Xstate.login.sport === undefined) {
      Xstate.login.sport = "none"
    }

    return (
      <div>
        <nav scroll="no" className="navbar-fixed-top">
          <table className="p100">
            <tbody>
              <tr>
                <td style={{ textAlign: "left", verticalAlign: "middle" }}>
                  <img src={bannerSmall} className="banner-title" alt="connactive club"></img>
                </td>
                <td className="right">
                  <NavLink
                    onClick={() => {
                      tquery("#updateReact").click()
                      tquery(".contentsection").scrollTo(0, 0)
                    }}
                    id="loginButton"
                    to="/account"
                    style={{ display: "none" }}
                  >
                    <i className="fa fa-user"></i>
                  </NavLink>
                  <button
                    type="submit"
                    id="loginModalButton1"
                    className="btn btn-header loginButtonVisible"
                    onClick={event => {
                      if (Xstate.login.status) {
                        document.querySelector("#loginButton").click()
                      } else {
                        LoginModal()
                      }
                    }}
                  >
                    <i className="fa fa-user"></i> Login
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </nav>
        <div key="contentSection" className="selectionpage">
          <br />
          &nbsp;
          <br />
          <h1>{savedText.home.top_banner}</h1>
          <div style={{ marginTop: "1em", marginBottom: "1em", fontSize: "1.5em" }}>
            <b>Let's get started</b> by choosing your favorite sport
          </div>
          <button
            className="btn btn-sportselect"
            id="ChooseYourSport"
            onClick={event => {
              Xstate.sportVariable = "Xstate"
              Xstate.modal.status = "SportandLocationList"
              tquery("#updateReact").click()
            }}
          >
            <i className="center 100p far fa-futbol fa-6x"></i>
            {savedText.oneLine}
            <span style={{ fontSize: "1.1em" }}>choose your sport</span>
          </button>
          <br />
          <br />
          <br />
          <span
            className="btn btn-content1"
            id="ContinueWithoutSport"
            onClick={event => {
              Xstate.login.sport = "none"
              Xstate.landingPage = 0
              Xstate.subLandingPage = 0
              tquery("#updateReact").click()
            }}
          >
            continue without sport
          </span>
        </div>
      </div>
    )
  }
}
