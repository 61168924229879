export var fetchLink = ""
export var testing = "on"

if (process.env.NODE_ENV === "development" || window.location.hostname === "localhost") {
  fetchLink = "http://localhost/connactiveclub/backend/index.php"
  if (navigator.platform === "MacIntel") {
    fetchLink = "http://localhost/~tylerhargrave/connactiveclub/backend/index.php"
    fetchLink = "http://localhost:8080/backend/index.php"
  }
} else if (process.env.NODE_ENV === "production") {
  fetchLink = "https://connactiveclub.com/backend/index.php"
}
