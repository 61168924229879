import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { getLatLng, getDistanceFromLatLonInKm } from ".././venues/MapSelect"
import { VenuesUpdate, PostsVenuesFiltersID, VenueNewAdd, VenueNewSame } from "../functions/Venue"
import { PostsUpdate } from "../functions/Posts"
import { SaveUsersLocation } from "../functions/Account"
import dateformat from "dateformat"
import { fetchLink } from "../fetchLink"
import { savedText } from "./../SavedText"
import { modalTextNoButton } from "../app/AppModal"

import { venuesSports, sportsListABC, Xstate, sportRatingJSX, geoFindMe, tDateFormat } from "../functions/Shared"
import { tquery, cleanPath } from "../app/ResponsiveLayout-20190729"
import { LoginModal } from "../functions/Account"
import {
  playerSearchAddPost,
  playerSearchDeletePost,
  playerSearchEditPost,
  playerSearchOptions,
  playerSearchOptions1,
} from "../functions/League"

var postsCount = 1000

export class ModalPosts extends Component {
  similarVenues() {
    //only use longitude and latitude if map was used and address was NOT changed
    if (Xstate.posts.useMap === 1) {
      //do nothing = all good!
    } else {
      var thisLatLng = getLatLng(Xstate.posts.dbVenuesGeneral[0].venue_address_line1)
      if (thisLatLng.lat !== undefined) {
        Xstate.posts.dbVenuesGeneral[0].venue_latitude = thisLatLng.lat
      }
      if (thisLatLng.lng !== undefined) {
        Xstate.posts.dbVenuesGeneral[0].venue_longitude = thisLatLng.lng
      }
    }

    //calculate distance on a pythagorean theorum method
    var result = [[], [], []]
    var distance = 0
    for (let i; i < Xstate.posts.details.consensus.length; i++) {
      distance = getDistanceFromLatLonInKm(
        Xstate.posts.dbVenuesGeneral[0].venue_latitude,
        Xstate.posts.dbVenuesGeneral[0].venue_longitude,
        Xstate.posts.details.consensus[i].venue_latitude,
        Xstate.posts.details.consensus[i].venue_longitude,
      )
      //top result;
      if (result[0].distance === undefined || result[0].distance > distance) {
        result = [{ venue: Xstate.posts.details.consenses[i], distance: distance }, result[0], result[1]]
      } else if (result[1].distance === undefined || result[1].distance > distance) {
        result = [result[0], { venue: Xstate.posts.details.consenses[i], distance: distance }, result[1]]
      } else if (result[2].distance === undefined || result[2].distance > distance) {
        result = [result[0], result[1], { venue: Xstate.posts.details.consenses[i], distance: distance }]
      }
    }
    return result
  }
  likeButtons() {
    var likeButtonStatus = "btn btn-content1 likeInactiveAdd"
    var dislikeButtonStatus = "btn btn-content1 dislikeInactiveAdd"
    if (Xstate.dbStatusValue !== "connected") {
      return <span />
    }
    if (Xstate.posts.currentPost.my_like_count * 1 === 1) {
      likeButtonStatus = "btn btn-content1 active likeActiveAdd"
    }
    if (Xstate.posts.currentPost.my_dislike_count * 1 === 1) {
      dislikeButtonStatus = "btn btn-content1 active dislikeActiveAdd"
    }
    return (
      <span>
        <span style={{ fontSize: "130%" }}>
          <i className="fas fa-thumbs-up"></i> {Xstate.posts.currentPost.like_count}
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span style={{ fontSize: "130%" }}>
          <i className="fas fa-thumbs-down"></i> {Xstate.posts.currentPost.dislike_count}
        </span>
        <br />
        <button
          className={likeButtonStatus + " td-button"}
          style={{ width: "5em" }}
          onClick={() => {
            if (Xstate.posts.dbPostsLikeTemp === undefined) {
              Xstate.posts.dbPostsLikeTemp = { x: "y" }
            }
            if (Xstate.posts.dbPostsTemp === undefined) {
              Xstate.posts.dbPostsTemp = { x: "y" }
            }
            Xstate.posts.dbPostsTemp = [...Xstate.posts.dbPosts]
            if (Xstate.posts.dbPostsLikeTemp[0] === undefined) {
              Xstate.posts.dbPostsLikeTemp[0] = {}
            }
            Xstate.posts.dbPostsLikeTemp[0].post_id = Xstate.posts.currentPost.post_id
            Xstate.posts.dbPostsLikeTemp[0].dislikes = 0
            if (
              Xstate.posts.dbPostsLikeTemp[0].likes === undefined ||
              Xstate.posts.dbPostsLikeTemp[0].likes * 1 === 0
            ) {
              Xstate.posts.dbPostsLikeTemp[0].likes = 1
            } else if (Xstate.posts.dbPostsLikeTemp[0].likes * 1 === 1) {
              Xstate.posts.dbPostsLikeTemp[0].likes = 0
            }
            Xstate.posts.dbPostsLikeTemp[0].user_id = Xstate.login.userid

            var formData = new FormData()
            formData.append("section", "posts")
            formData.append("request", "save-likes")
            formData.append("post_id", Xstate.posts.dbPostsLikeTemp[0].post_id)
            formData.append("details", JSON.stringify(Xstate.posts.dbPostsLikeTemp[0]))
            if (Xstate.testing === "on") {
              console.log("FETCH")
              console.log("section:posts")
              console.log("request:save-likes")
              console.log("post_id:" + Xstate.posts.dbPostsLikeTemp[0].post_id)
              console.log("details:" + JSON.stringify(Xstate.posts.dbPostsLikeTemp[0]))
            }
            return (
              //should be http://localhost/connactiveclub/public for dev
              fetch(fetchLink, {
                credentials: "include",
                method: "POST",
                body: formData,
              })
                .then(response => {
                  return response.text()
                })
                .then(data => {
                  if (data === "post success") {
                    Xstate.posts.dbPostsTemp = 0
                    Xstate.posts.dbPosts = 0
                    //localStorage.dbPosts = 0;
                    Xstate.posts.msg = "Your post has been updated successfully."
                    Xstate.modal.status = "postsuccess"
                    tquery("#updateReact").click()
                  }
                })
            )
          }}
        >
          Like
        </button>
        <button
          className={dislikeButtonStatus + " td-button"}
          style={{ width: "5em" }}
          onClick={() => {
            if (Xstate.posts.dbPostsLikeTemp === undefined) {
              Xstate.posts.dbPostsLikeTemp = { x: "y" }
            }
            if (Xstate.posts.dbPostsTemp === undefined) {
              Xstate.posts.dbPostsTemp = { x: "y" }
            }
            Xstate.posts.dbPostsTemp = [...Xstate.posts.dbPosts]
            if (Xstate.posts.dbPostsLikeTemp[0] === undefined) {
              Xstate.posts.dbPostsLikeTemp[0] = {}
            }
            Xstate.posts.dbPostsLikeTemp[0].post_id = Xstate.posts.currentPost.post_id
            if (
              Xstate.posts.dbPostsLikeTemp[0].dislikes === undefined ||
              Xstate.posts.dbPostsLikeTemp[0].dislikes * 1 === 0
            ) {
              Xstate.posts.dbPostsLikeTemp[0].dislikes = 1
            } else if (Xstate.posts.dbPostsLikeTemp[0].dislikes * 1 === 1) {
              Xstate.posts.dbPostsLikeTemp[0].dislikes = 0
            }
            Xstate.posts.dbPostsLikeTemp[0].likes = 0
            Xstate.posts.dbPostsLikeTemp[0].user_id = Xstate.login.userid

            var formData = new FormData()
            formData.append("section", "posts")
            formData.append("request", "save-likes")
            formData.append("post_id", Xstate.posts.dbPostsLikeTemp[0].post_id)
            formData.append("details", JSON.stringify(Xstate.posts.dbPostsLikeTemp[0]))
            if (Xstate.testing === "on") {
              console.log("FETCH")
              console.log("section:posts")
              console.log("request:save-likes")
              console.log("post_id:" + Xstate.posts.dbPostsLikeTemp[0].post_id)
              console.log("details:" + JSON.stringify(Xstate.posts.dbPostsLikeTemp[0]))
            }
            return (
              //should be http://localhost/connactiveclub/public for dev
              fetch(fetchLink, {
                credentials: "include",
                method: "POST",
                body: formData,
              })
                .then(response => {
                  return response.text()
                })
                .then(data => {
                  if (data === "post success") {
                    Xstate.posts.dbPostsTemp = 0
                    Xstate.posts.dbPosts = 0
                    //localStorage.dbPosts = 0;
                    Xstate.posts.msg = "Your post has been updated successfully."
                    Xstate.modal.status = "postsuccess"

                    tquery("#updateReact").click()
                  }
                })
            )
          }}
        >
          Dislike
        </button>
      </span>
    )
  }
  NewVenueSimilar() {
    //ERROR TRAPPING
    if (Xstate.posts.similarVenues === undefined) return <span />
    if (Xstate.posts.similarVenues === 0) return <span />
    if (Xstate.posts.similarVenues.consensus.length < 1) return <span />

    let tableJSX = []
    for (let $i = 0; $i < Xstate.posts.similarVenues.consensus.length; $i++) {
      let sportsJSX = []
      for (let $j = 0; $j < Xstate.posts.similarVenues.consensus[$i].dbVenuesSportsValues.length; $j++) {
        let row = Xstate.posts.similarVenues.consensus[$i]["dbVenuesSportsValues"][$j]
        if (1 * row.sport_exists > 0.5) {
          sportsJSX.push(<span>{row.sport_name}</span>)
          sportsJSX.push(<span>, </span>)
        }
      }
      sportsJSX = sportsJSX.slice(0, sportsJSX.length - 1)
      tableJSX.push(
        <tr key={postsCount++}>
          <td className="w60p td-input">
            <button className="w100p btn btn-content1 left">
              <b>{Xstate.posts.similarVenues.consensus[$i].dbVenuesGeneral.name}</b>
              <br />
              {sportsJSX}
            </button>
          </td>
          <td className="w40p td-input">
            <button className="w100p btn btn-content3" onClick={() => VenueNewSame()}>
              Same Venue <br />
              Update This Venue
            </button>
          </td>
        </tr>,
      )
    }

    return (
      <span>
        <h4> Same Venue </h4>
        We don't want duplicates - so please check to make sure the venue isn't already in the list.
        {savedText.halfLine}
        <table>
          <tbody>{tableJSX}</tbody>
        </table>
        {savedText.halfLine}
        <button id="saveVenue" onClick={() => VenueNewAdd()} className="btn btn-content1">
          Save Venue
        </button>
      </span>
    )
  }
  location() {
    Xstate.JSX.locationInputJSX = []
    Xstate.JSX.locationOutputJSX = []
    Xstate.JSX.locationPostCodeJSX = []
    if (Xstate.location[0].type === undefined) Xstate.location[0].type = "postalcode"

    //BUTTON-POSTCODE
    let buttonPostCode = (
      <button
        id="locationSearchPostCodeButtonInactive"
        className="btn btn-content2 btn-small"
        onClick={() => {
          Xstate.location[0].type = "postalcode"
          Xstate.location[0].fieldResult = []
          tquery("#updateReact").click()
        }}
      >
        Postcode
      </button>
    )
    if (Xstate.location[0].type === "postalcode") {
      buttonPostCode = (
        <button id="locationSearchPostCodeButtonActive" className="btn btn-content2 active btn-small">
          Postcode
        </button>
      )
    }

    //BUTTON-CITY
    let buttonCity = (
      <button
        id="locationSearchCityButtonInactive"
        className="btn btn-content2 btn-small"
        onClick={() => {
          Xstate.location[0].type = "city"
          Xstate.location[0].fieldResult = []
          tquery("#updateReact").click()
        }}
      >
        City
      </button>
    )
    if (Xstate.location[0].type !== "postalcode") {
      buttonCity = (
        <button id="locationSearchCityButtonActive" className="btn btn-content2 active btn-small" onClick={() => {}}>
          City
        </button>
      )
    }

    //CURRENT LOCATION
    let buttonCurrentLocation = (
      <button
        id="locationSearchCurrentButton"
        className="btn btn-content2 btn-small"
        onClick={() => {
          geoFindMe()
          Xstate.JSX.locationPostCodeJSX = []
          Xstate.location[0].fieldResult = []
          Xstate.modal.status = "text-nobutton"
          Xstate.modal.text = Xstate.location[0].fieldError
          tquery("#updateReact").click()
        }}
      >
        Current Location
      </button>
    )

    //INPUT
    let searchInput = (
      <input
        id="searchInput"
        type="text"
        className="input input-small"
        defaultValue={Xstate.location[0].field}
        onChange={event => {
          Xstate.location[0].field = event.target.value
          console.log(Xstate.location[0].field)
          //tquery("#updateReact").click()
          //tquery("#searchInput").focus()
        }}
      />
    )

    let searchButton = (
      <button
        id="searchButton"
        className="btn btn-content1 btn-small fas fa-search"
        onClick={() => {
          if (Xstate.location[0].field === undefined) Xstate.location[0].field = ""
          if (Xstate.location[0].field.trim() === "") {
            Xstate.location[0].fieldResult = [{ postal_code: "novalue" }]
            tquery("#updateReact").click()
          } else {
            var formData = new FormData()
            formData.append("section", "map")
            formData.append("request", Xstate.location[0].type)
            formData.append(Xstate.location[0].type, Xstate.location[0].field)
            if (Xstate.testing === "on") {
              console.log("FETCH")
              console.log("section: map")
              console.log("request:" + Xstate.location[0].type)
              console.log(Xstate.location[0].type + ": " + Xstate.location[0].field)
            }
            fetch(fetchLink, {
              credentials: "include",
              method: "POST",
              body: formData,
            })
              .then(response => {
                return response.json()
              })
              .then(data => {
                Xstate.location[0].fieldResult = data

                tquery("#updateReact").click()
              })
          }
        }}
      />
    )
    if (Xstate.location[0].field === undefined) Xstate.location[0].field = ""
    Xstate.JSX.locationInputJSX.push(
      <div className="margin0" key={postsCount++}>
        {buttonCurrentLocation}
        {savedText.oneLine}
        {buttonPostCode}
        {buttonCity}
        {savedText.oneLine}
        {searchInput}
        {searchButton}
        {savedText.oneLine}
      </div>,
    )

    if (Xstate.location[0].fieldResult.length * 1 === 1) {
      //FAIL
      if (Xstate.location[0].fieldResult[0].postal_code === "fail") {
        //ERROR MSG
        Xstate.location[0].errormsg = ""
        if (Xstate.location[0].type === "postalcode")
          Xstate.location[0].errormsg =
            "No result found - please ensure that postcode is in a proper format (i.e M2J3M2)"
        if (Xstate.location[0].type === "city")
          Xstate.location[0].errormsg = "No result found - please try again with different input"
        Xstate.JSX.locationOutputJSX.push(
          <tr style={{ marginLeft: "0%" }} key={postsCount++}>
            <td className="td-left">
              <b>{Xstate.location[0].errormsg}</b>
            </td>
          </tr>,
        )
      }

      //NO VALUE
      else if (Xstate.location[0].fieldResult[0].postal_code === "novalue") {
        Xstate.JSX.locationOutputJSX.push(
          <tr key={postsCount++}>
            <td id="alert-message0">You have not input any value.</td>
          </tr>,
        )
      }

      //SUCCESS CASE
      else {
        if (Xstate.location[0].type === "postalcode") {
          Xstate.JSX.locationPostCodeJSX.push(
            <td key={postsCount++} className="td-left">
              <b>Postcode: </b>
            </td>,
          )
          Xstate.JSX.locationPostCodeJSX.push(
            <td key={postsCount++} className="td-left ">
              {Xstate.location[0].fieldResult[0].postal_code}
            </td>,
          )
        }
        Xstate.JSX.locationOutputJSX.push(
          <tr key={postsCount++}>
            <td id="cityDetails0" className="td-left ">
              {Xstate.location[0].fieldResult[0].postal_code} {Xstate.location[0].fieldResult[0].City}
              &nbsp;
              {Xstate.location[0].fieldResult[0].Province}, {Xstate.location[0].fieldResult[0].Country}
            </td>
            {Xstate.JSX.locationPostCodeJSX}
            <td className="td-left ">
              <button
                className="btn btn-content2 btn-small"
                id="selectButton"
                onClick={() => {
                  console.log(Xstate.modal.location_use)
                  if (Xstate.modal.location_use === "PlayerSearch") {
                    Xstate.posts.playerSearch.city = Xstate.location[0].fieldResult[0].City
                    Xstate.posts.playerSearch.latitude = Xstate.location[0].fieldResult[0].Latitude
                    Xstate.posts.playerSearch.longitude = Xstate.location[0].fieldResult[0].Longitude
                    Xstate.posts.playerSearch.postalcode = Xstate.location[0].fieldResult[0].Postal_Code
                    Xstate.posts.playerSearch.label = Xstate.location[0].fieldResult[0].City
                    Xstate.modal.status = "playersearch"
                    tquery("#updateReact").click()
                  } else {
                    Xstate.location[Xstate.locationID].label = Xstate.location[0].fieldResult[0].City
                    Xstate.location[Xstate.locationID].lat = Xstate.location[0].fieldResult[0].Latitude
                    Xstate.location[Xstate.locationID].lng = Xstate.location[0].fieldResult[0].Longitude
                    Xstate.posts.locationOn = 1
                    Xstate.modal.status = "none"
                    tquery("#updateReact").click()
                  }

                  //Refresh Filters
                  Xstate.posts.filters.refresh = 1
                  setTimeout(() => {
                    tquery("#refreshButton").click()
                  }, 1000)

                  //Clear Temp Variables
                  Xstate.location[0].field = ""
                  Xstate.location[0].fieldResult = []
                  Xstate.JSX.locationOutputJSX = []
                  Xstate.JSX.locationPostCodeJSX = []

                  //Back to start of Filters
                  Xstate.posts.printMax = 19

                  //Default Sortby to Distance
                  Xstate.posts.filters.sortby = "Distance"
                  if (Xstate.modal.location_use === "Profile") SaveUsersLocation()
                  tquery("#updateReact").click()
                }}
              >
                Select
              </button>
            </td>
          </tr>,
        )
      }
    } else if (Xstate.location[0].fieldResult.length * 1 > 1) {
      for (let i = 0; i < Xstate.location[0].fieldResult.length; i++) {
        Xstate.JSX.locationPostCodeJSX = []
        if (Xstate.location[0].type === "postalcode") {
          Xstate.JSX.locationPostCodeJSX.push(
            <td key={postsCount++} className="td-left ">
              <b>postcode:</b>
            </td>,
          )
          Xstate.JSX.locationPostCodeJSX.push(
            <td key={postsCount++}>{Xstate.location[0].fieldResult[i].postal_code}</td>,
          )
        }
        Xstate.JSX.locationOutputJSX.push(
          <tr key={postsCount++}>
            <td className="td-left">
              <b>{i + 1}.</b>
            </td>
            <td id={"cityDetails" + i} className="td-left">
              {Xstate.location[0].fieldResult[i].postal_code} {Xstate.location[0].fieldResult[i].City}
              &nbsp;
              {Xstate.location[0].fieldResult[i].Province}, {Xstate.location[0].fieldResult[i].Country}
            </td>
            {Xstate.JSX.locationPostCodeJSX}
            <td className="td-left">
              <button
                id={"selectButton" + i}
                className="btn btn-content2 btn-small"
                onClick={() => {
                  console.log("" + Xstate.modal.location_use)
                  if (Xstate.modal.location_use === "PlayerSearch") {
                    Xstate.posts.playerSearch.city = Xstate.location[0].fieldResult[i].City
                    Xstate.posts.playerSearch.label = Xstate.location[0].fieldResult[i].City
                    Xstate.posts.playerSearch.latitude = Xstate.location[0].fieldResult[i].Latitude
                    Xstate.posts.playerSearch.longitude = Xstate.location[0].fieldResult[i].Longitude
                    Xstate.posts.playerSearch.postalcode = Xstate.location[0].fieldResult[i].Postal_Code
                    Xstate.modal.status = "playersearch"
                    tquery("#updateReact").click()
                  } else {
                    Xstate.location[Xstate.locationID].label = Xstate.location[0].fieldResult[i].City
                    Xstate.location[Xstate.locationID].lat = Xstate.location[0].fieldResult[i].Latitude
                    Xstate.location[Xstate.locationID].lng = Xstate.location[0].fieldResult[i].Longitude
                    Xstate.posts.locationOn = 1
                    Xstate.modal.status = "none"
                    tquery("#updateReact").click()
                  }
                  //Refresh Filters
                  Xstate.posts.filters.refresh = 1
                  setTimeout(() => {
                    tquery("#refreshButton").click()
                  }, 1000)

                  //Clear Temp Variables
                  Xstate.location[0].field = ""
                  Xstate.location[0].fieldResult = []
                  Xstate.JSX.locationOutputJSX = []
                  Xstate.JSX.locationPostCodeJSX = []

                  //Back to start of Filters
                  Xstate.posts.printMax = 19

                  //Default Sortby to Distance
                  Xstate.posts.filters.sortby = "Distance"
                  if (Xstate.modal.location_use === "Profile") SaveUsersLocation()
                  tquery("#updateReact").click()
                }}
              >
                Select
              </button>
            </td>
          </tr>,
        )
      }
    }

    return modalTextNoButton(
      <>
        <h2> Search Location </h2>
        {Xstate.JSX.locationInputJSX}
        <table style={{ marginLeft: "5%", marginRight: "5%", width: "80%" }}>
          <tbody>{Xstate.JSX.locationOutputJSX}</tbody>
        </table>
        <br />
        <button
          className="btn btn-content1"
          onClick={() => {
            if (Xstate.posts.playerSearch.status === "add") {
              Xstate.location[0].field = ""
              Xstate.location[0].fieldResult = []
              Xstate.modal.status = "playersearch"
              tquery("#updateReact").click()
            } else if (Xstate.posts.playerSearch.status === "sort") {
              Xstate.location[0].field = ""
              Xstate.location[0].fieldResult = []
              Xstate.JSX.locationOutputJSX = []
              Xstate.JSX.locationOutputJSX = []
              Xstate.JSX.locationPostCodeJSX = []
              Xstate.modal.status = "none"
              tquery("#updateReact").click()
            }
          }}
        >
          &nbsp;
          {savedText.venues.back}
          &nbsp;
        </button>
        <br />
      </>,
    )
  }
  playerSearch() {
    let tableJSX = []
    if (Xstate.login.status === false) {
      return modalTextNoButton(
        <div>
          <br />
          <b> Join connactive club today to add a post </b>
          <br />
          <br />
          <button className="btn btn-content1" onClick={LoginModal}>
            Login
          </button>
        </div>,
      )
    }

    //SUBJECT
    tableJSX.push(
      <tr key={postsCount++}>
        <td className="td-left" colSpan="2">
          Subject:
          <br />
          <input
            id="playerSearchAddSubjectInput"
            type="text"
            className="input input-large"
            defaultValue={Xstate.posts.playerSearch.title}
            onKeyUp={event => {
              Xstate.posts.playerSearch.title = event.target.value
            }}
          />
        </td>
      </tr>,
    )

    //MESSAGE
    if (Xstate.posts.playerSearch.content === undefined) Xstate.posts.playerSearch.content = ""
    tableJSX.push(
      <tr key={postsCount++}>
        <td className="td-left" colSpan="2">
          Message:
          <br />
          <textarea
            id="playerSearchAddMessageInput"
            wrap="soft"
            className="input textarea input-large"
            defaultValue={Xstate.posts.playerSearch.content.split("<br/>").join("\n")}
            onChange={event => {
              Xstate.posts.playerSearch.content = event.target.value
            }}
          />
        </td>
      </tr>,
    )

    //BREAK
    tableJSX.push(
      <tr key={postsCount++}>
        <td>{savedText.oneLine}</td>
      </tr>,
    )

    //my_type
    tableJSX.push(
      <tr key={postsCount++}>
        <td className="td-left">I am a:</td>
        <td className="td-left">
          <select
            id="playerSearchAddIAM"
            value={Xstate.posts.playerSearch.my_type}
            onChange={event => {
              Xstate.posts.playerSearch.my_type = event.target.value
              tquery("#updateReact").click()
            }}
          >
            {playerSearchOptions(0)}
          </select>
        </td>
      </tr>,
    )

    //SEARCH TYPE
    tableJSX.push(
      <tr key={postsCount++}>
        <td className="td-left">Looking for a:</td>
        <td className="td-left">
          <select
            id="playerSearchAddLookingFor"
            value={Xstate.posts.playerSearch.search_type}
            onChange={event => {
              Xstate.posts.playerSearch.search_type = event.target.value
              tquery("#updateReact").click()
            }}
          >
            {playerSearchOptions(0)}
          </select>
        </td>
      </tr>,
    )

    //LEAGUE TYPE
    tableJSX.push(
      <tr key={postsCount++}>
        <td className="td-left">Type:</td>
        <td className="td-left">
          <select
            id="playerSearchAddType"
            value={Xstate.posts.playerSearch.league_type}
            onChange={event => {
              Xstate.posts.playerSearch.league_type = event.target.value
              Xstate.modal.status = "playersearch"
              tquery("#updateReact").click()
            }}
          >
            {playerSearchOptions1(0)}
          </select>
        </td>
      </tr>,
    )

    //BREAK
    tableJSX.push(
      <tr key={postsCount++}>
        <td>{savedText.oneLine}</td>
      </tr>,
    )

    //LOCATION
    if (Xstate.posts.playerSearch.city === undefined) Xstate.posts.playerSearch.city = ""
    Xstate.posts.playerSearch.label = Xstate.posts.playerSearch.city
    tableJSX.push(
      <tr key={postsCount++}>
        <td className="top td-left">Location:</td>
        <td className="td-left">
          {Xstate.posts.playerSearch.label}
          &nbsp;
          <button
            id="locationSearchAddModal"
            className="btn btn-content2 btn-small"
            onClick={() => {
              Xstate.location[0].type = "postalcode"
              Xstate.JSX.locationOutputJSX = []
              Xstate.JSX.locationPostCodeJSX = []
              Xstate.posts.filters.refresh = 1
              Xstate.locationID = 0
              Xstate.modal.status = "location"
              tquery("#updateReact").click()
            }}
          >
            Select
          </button>
        </td>
      </tr>,
    )
    Xstate.modal.location_use = "PlayerSearch"

    //BREAK
    tableJSX.push(
      <tr key={postsCount++}>
        <td>{savedText.oneLine}</td>
      </tr>,
    )

    //DATE
    if (Xstate.posts.playerSearch !== "add") {
      if (Xstate.posts.playerSearch.update_date === undefined) Xstate.posts.playerSearch.update_date = ""
      if (Xstate.posts.playerSearch.update_date === "") {
        let myToday = new Date()
        Xstate.posts.playerSearch.update_date = dateformat(myToday, "yyyymmddHHMMss")
      }
      tableJSX.push(
        <tr key={postsCount++}>
          <td className="td-left">Last Update:</td>
          <td className="td-left">{tDateFormat(Xstate.posts.playerSearch.update_date, "full")}</td>
        </tr>,
      )
      if (Xstate.posts.playerSearch.expiry_date !== undefined) {
        tableJSX.push(
          <tr key={postsCount++}>
            <td className="td-left">Expiry:</td>
            <td className="td-left">{tDateFormat(Xstate.posts.playerSearch.expiry_date, "full")}</td>
          </tr>,
        )
      }
    }

    //BUTTONJSX
    let buttonJSX = <span />
    if (Xstate.posts.playerSearch.status === "add") {
      buttonJSX = (
        <button
          className="btn btn-content1"
          id="playerSearchPostsAddExecuteButton"
          onClick={() => {
            Xstate.posts.playerSearch.title = tquery("#playerSearchAddSubjectInput").value
            Xstate.posts.playerSearch.content = tquery("#playerSearchAddMessageInput").value
            playerSearchAddPost()
          }}
        >
          Add Post
        </button>
      )
    } else {
      buttonJSX = (
        <>
          <button
            className="btn btn-content1"
            id="playerSearchPostsAddExecuteButton"
            onClick={() => {
              playerSearchEditPost()
            }}
          >
            Save Changes
          </button>
          <button
            className="btn btn-content1"
            id="playerSearchPostsAddExecuteButton"
            onClick={() => {
              playerSearchDeletePost()
            }}
          >
            Delete Post
          </button>
        </>
      )
    }
    return modalTextNoButton(
      <>
        <h2> Player Search-{Xstate.sport} </h2>
        <table className="table-center left">
          <tbody>{tableJSX}</tbody>
        </table>
        {savedText.oneLine}
        <div id="alert-posts-message">{Xstate.posts.msg}</div>
        {savedText.oneLine}
        {buttonJSX}
        {savedText.oneLine}
      </>,
    )
  }
  venuesNavigationButtons = () => {
    Xstate.posts.modalButtons = {}

    if (Xstate.posts.modalView === undefined) {
      Xstate.posts.modalView = "general"
    }

    //sportsButtons
    let sportsButtons = []
    Xstate.posts.modalButtons = []

    for (let i = 0; i < sportsListABC.length; i++) {
      Xstate.posts.modalButtons[sportsListABC[i]] = <span key={postsCount++} />
      if (Xstate.posts.sports[sportsListABC[i]] === 1) {
        Xstate.posts.modalButtons[sportsListABC[i]] = (
          <span
            id={sportsListABC[i]}
            key={postsCount++}
            className="link linkgreen"
            onClick={() => {
              PostsVenuesFiltersID(sportsListABC[i])
              Xstate.posts.modalView = sportsListABC[i]
              tquery("#updateReact").click()
            }}
          >
            <span className="linkgreen">{sportRatingJSX(sportsListABC[i])}</span>
            <span className="small-break" />
            <span className="small-break" />
          </span>
        )
        if (Xstate.posts.modalView === sportsListABC[i]) {
          Xstate.posts.modalButtons[sportsListABC[i]] = (
            <span>
              <span className="btn btn-content1 btn-small active">{sportRatingJSX(sportsListABC[i])}</span>
              <span className="small-break" />
              <span className="small-break" />
            </span>
          )
        }
      }
      sportsButtons.push(Xstate.posts.modalButtons[sportsListABC[i]])
    }
    let addSportsText = "+ Add Another Sport"
    if (Xstate.posts.currentVenue.dbVenuesGeneral.post_id === "new") {
      addSportsText = "Sports List"
    }
    if (Xstate.posts.modalView !== "add") {
      sportsButtons.push(
        <span>
          <br />
          <span
            key={postsCount++}
            className="link"
            onClick={() => {
              Xstate.posts.modalView = "add"
              tquery("#updateReact").click()
            }}
          >
            <span className="linkgreen" id="addSports">
              {addSportsText}
            </span>
          </span>
        </span>,
      )
    } else {
      sportsButtons.push(
        <span>
          <br />
          <span>
            <span
              className="btn btn-content1 btn-small active"
              onClick={() => {
                Xstate.posts.modalView = "add"
                tquery("#updateReact").click()
              }}
            >
              {addSportsText}
            </span>
          </span>
        </span>,
      )
    }
    if (Xstate.posts.currentVenue.dbVenuesGeneral.post_id !== "new") {
      if (Xstate.posts.similarVenues !== undefined) {
        if (Xstate.posts.similarVenues !== 0) {
          if (Xstate.posts.similarVenues.consensus.length > 1) {
            if (Xstate.posts.modalView !== "nearby") {
              sportsButtons.push(
                <span>
                  {savedText.tab}
                  <button
                    key={postsCount++}
                    className="link"
                    onClick={() => {
                      Xstate.posts.modalView = "nearby"
                      tquery("#updateReact").click()
                    }}
                  >
                    <span className="linkgreen">Nearby Venues</span>
                  </button>
                </span>,
              )
            } else {
              sportsButtons.push(
                <span>
                  {savedText.tab}
                  <button
                    className="btn btn-content1 btn-small active"
                    onClick={() => {
                      Xstate.posts.modalView = "add"
                      tquery("#updateReact").click()
                    }}
                  >
                    Nearby Venues
                  </button>
                </span>,
              )
            }
          }
        }
      }
    }
    //generalButton
    Xstate.posts.modalButtons.general = (
      <span
        className="link"
        onClick={() => {
          Xstate.posts.modalView = "general"

          tquery("#updateReact").click()
        }}
      >
        <span className="linkgreen">General</span>
        <span className="small-break" />
        <br />
      </span>
    )
    if (Xstate.posts.modalView === "general") {
      Xstate.posts.modalButtons.general = (
        <span>
          <span className="btn btn-content1 btn-small active">General</span>
          <span className="small-break" />
          <br />
        </span>
      )
    }

    if (Xstate.posts.modalView === "new-similar") {
      return <span />
    }
    return (
      <span>
        {Xstate.posts.modalButtons.general}
        {sportsButtons}
      </span>
    )
  }
  venuesEdit = () => {
    var tableJSX = []

    //name
    //buttonJSX
    return tableJSX
  }
  venuesAddSport = () => {
    let resultJSX = []

    for (let i = 0; i < sportsListABC.length; i++) {
      if (Xstate.posts.sports[sportsListABC[i]] !== 1) {
        resultJSX.push(
          <tr key={postsCount++}>
            <td className="td-left"> {sportsListABC[i]} </td>
            <td className="td-left">
              <button
                className="btn btn-content1 btn-small"
                id={"sport" + i}
                onClick={() => {
                  //my current venue
                  let sportsfound = 0

                  Xstate.posts.myCurrentVenue.dbVenuesSportsValues.forEach(sport => {
                    if (sport.sport_name === sportsListABC[i]) {
                      sport.sport_exists = 1
                      sportsfound = 1
                    }
                  })
                  if (sportsfound === 0) {
                    Xstate.posts.myCurrentVenue.dbVenuesSportsValues.push({
                      sport_name: sportsListABC[i],
                      sport_exists: 1,
                      sport_rating: 3,
                    })
                  }

                  //current venue
                  sportsfound = 0
                  Xstate.posts.currentVenue.dbVenuesSportsValues.forEach(sport => {
                    if (sport.sport_name === sportsListABC[i]) {
                      sport.sport_exists = 1
                      sportsfound = 1
                    }
                  })
                  if (sportsfound === 0) {
                    Xstate.posts.currentVenue.dbVenuesSportsValues.push({
                      sport_name: sportsListABC[i],
                      sport_exists: 1,
                      sport_rating: 3,
                    })
                  }
                  Xstate.posts.sports[sportsListABC[i]] = 1
                  PostsVenuesFiltersID(sportsListABC[i])
                  Xstate.posts.modalView = sportsListABC[i]
                  Xstate.modal.status = "venues-edit"

                  tquery("#updateReact").click()
                }}
              >
                + Add
              </button>
            </td>
          </tr>,
        )
      }
    }

    return (
      <table className="table-center left">
        <tbody>{resultJSX}</tbody>
      </table>
    )
  }
  venuesGeneral = () => {
    return (
      <table className="table-center left">
        <tbody>
          <tr>{this.venuesGeneralField("Name", "name")}</tr>
          <tr>
            <td className="font-small">&nbsp;</td>
          </tr>
          <tr>{this.venuesGeneralField("Address", "venue_address_line1")}</tr>
          <tr>{this.venuesGeneralField("City", "venue_city")}</tr>
          <tr>{this.venuesGeneralField("Province", "venue_province")}</tr>
          <tr>{this.venuesGeneralField("Country", "venue_country")}</tr>
          <tr>
            <td className="font-small">&nbsp;</td>
          </tr>
          <tr>{this.venuesGeneralField("Email", "email")}</tr>
          <tr>{this.venuesGeneralField("Phone", "phone")}</tr>
          <tr>{this.venuesGeneralField("Website", "website")}</tr>
        </tbody>
      </table>
    )
  }
  venuesNearby = () => {
    //ERROR TRAPPING
    if (Xstate.posts.similarVenues === undefined) return <span />
    if (Xstate.posts.similarVenues === 0) return <span />
    if (Xstate.posts.similarVenues.consensus.length < 1) return <span />

    //ROWS FOR EACH VENUE
    let tableJSX = []
    for (let $i = 0; $i < Xstate.posts.similarVenues.consensus.length; $i++) {
      let sportsJSX = []
      for (let $j = 0; $j < Xstate.posts.similarVenues.consensus[$i].dbVenuesSportsValues.length; $j++) {
        let row = Xstate.posts.similarVenues.consensus[$i]["dbVenuesSportsValues"][$j]
        if (1 * row.sport_exists > 0.5) {
          sportsJSX.push(<span>{row.sport_name}</span>)
          sportsJSX.push(<span>, </span>)
        }
      }
      sportsJSX = sportsJSX.slice(0, sportsJSX.length - 1)
      tableJSX.push(
        <tr key={postsCount++}>
          <td className="w60p td-input">
            <NavLink
              to={"/venues-" + Xstate.posts.similarVenues.consensus[$i].dbVenuesGeneral.post_id}
              id={"x" + Xstate.posts.similarVenues.consensus[$i].dbVenuesGeneral.post_id}
              className="hidden"
            />
            <button
              className="w100p btn btn-content1 left"
              onClick={() => {
                //ClearPostsModal()
                //Xstate.modal.status = "venues-general"

                Xstate.posts.page = "venuedetail"
                Xstate.posts.directVenuesLink = 0
                tquery("#x" + Xstate.posts.similarVenues.consensus[$i].dbVenuesGeneral.post_id).click()
                tquery("#updateReact").click()
                tquery(".contentsection").scrollTo(0, 0)
              }}
            >
              <b>{Xstate.posts.similarVenues.consensus[$i].dbVenuesGeneral.name}</b>
              <br />
              {sportsJSX}
            </button>
          </td>
          <td className="w20p td-input">
            <button
              className="w100p btn btn-content3"
              onClick={() => {
                Xstate.posts.modalView = "same"
                Xstate.posts.sameVenue = $i

                tquery("#updateReact").click()
              }}
            >
              REPEAT
            </button>
          </td>
        </tr>,
      )
    }
    return (
      <table className="w100p">
        <tbody>{tableJSX}</tbody>
      </table>
    )
  }
  venuesJSX = () => {
    let content = <span />
    if (Xstate.posts.modalView === undefined) Xstate.posts.modalView = "general"
    if (Xstate.posts.modalView === "general") content = this.venuesGeneral()
    else if (Xstate.posts.modalView === "add") content = this.venuesAddSport()
    else if (Xstate.posts.modalView === "nearby") content = this.venuesNearby()
    else if (Xstate.posts.modalView === "new-similar") content = this.NewVenueSimilar()
    else content = this.venuesDetails()

    return (
      <span>
        {this.venuesEdit()}
        <br />
        {content}
        <br />
      </span>
    )
  }
  /*<<<<<>>>> TO DELETE 
  venuesMap = () => {
    let googleMapsLink = <span />
    let newVenueBackButton = <span />
    if (Xstate.posts.currentVenue.dbVenuesGeneral.post_id !== "new") {
      googleMapsLink = (
        <a
          className="link linkgreen"
          href={
            "https://www.google.com/maps/search/?api=1&query=" +
            Xstate.posts.currentVenue.dbVenuesGeneral.venue_latitude +
            "," +
            Xstate.posts.currentVenue.dbVenuesGeneral.venue_longitude
          }
          target="_blanks"
        >
          open in google maps
        </a>
      )
    } else {
      newVenueBackButton = (
        <button
          className="btn btn-content1"
          onClick={() => {
            Xstate.posts.modalView = "general"
            tquery("#updateReact").click()
          }}
        >
          Back to Venue Details
        </button>
      )
    }

    //VIEW
    if (Xstate.posts.currentVenue.dbVenuesGeneral.venue_latitude !== "0") {
      if (Xstate.posts.currentVenue.dbVenuesGeneral.venue_longitude !== "0") {
        if (Xstate.posts.currentVenue.dbVenuesGeneral.venue_latitude !== undefined) {
          if (Xstate.posts.currentVenue.dbVenuesGeneral.venue_longitude !== undefined) {
            return (
              <div>
                <iframe
                  title="map"
                  src={
                    "https://maps.google.com/maps?z=10&width=300%&amp;height=300&output=embed&q=" +
                    Xstate.posts.currentVenue.dbVenuesGeneral.venue_latitude +
                    "," +
                    Xstate.posts.currentVenue.dbVenuesGeneral.venue_longitude
                  }
                  frameBorder="no"
                  height="301px"
                  width="301px"
                ></iframe>
                <br />
                {googleMapsLink}
                {newVenueBackButton}
              </div>
            )
          }
        }
      }
    }
    return <span />
  }
  */

  venuesGeneralField = (label, field) => {
    if (Xstate.posts.currentVenue === 0) {
      return <span />
    }
    if (Xstate.posts.currentVenue === 1) {
      return <span />
    }
    if (Xstate.posts.myCurrentVenue === 0) {
      return <span />
    }

    let database = "dbVenuesGeneral"
    let x = {}

    //FIELDS
    x.field1 = "" + field
    x.field2 = field.substr(0, 9) + (1 * field.substr(9, 100) + 1).toString()
    x.field3 = field.substr(0, 9) + (1 * field.substr(9, 100) + 2).toString()
    x.field4 = field.substr(0, 9) + (1 * field.substr(9, 100) + 3).toString()

    for (let i = 1; i <= 4; i++) {
      x["fieldText" + i] = ""
      if (
        Xstate.posts.currentVenue.dbVenuesGeneral[x["field" + i]] === undefined ||
        Xstate.posts.currentVenue.dbVenuesGeneral[x["field" + i]] === null
      ) {
        x["fieldConsensus" + i] = ""
      } else {
        x["fieldConsensus" + i] = "" + Xstate.posts.currentVenue.dbVenuesGeneral[x["field" + i]].toString()
        x["fieldText" + i] = "" + x["fieldConsensus" + i]
      }

      if (
        Xstate.posts.myCurrentVenue.dbVenuesGeneral[x["field" + i]] === undefined ||
        Xstate.posts.myCurrentVenue.dbVenuesGeneral[x["field" + i]] === null
      ) {
        x["fieldPersonal" + i] = ""
      } else {
        x["fieldPersonal" + i] = "" + Xstate.posts.myCurrentVenue.dbVenuesGeneral[x["field" + i]].toString()
        x["fieldText" + i] = "" + x["fieldPersonal" + i]
      }
    }
    x.fieldText = "" + x.fieldText1
    x.fieldConsensus = "" + x.fieldConsensus1
    x.fieldPersonal = "" + x.fieldPersonal1

    //DEFAULTS
    x.fieldCheck = (
      <button
        className="pup"
        onClick={() => {
          Xstate.posts.myCurrentVenue.dbVenuesGeneral[field] = Xstate.posts.currentVenue.dbVenuesGeneral[field]
          let tempValue = { ...Xstate.posts.myCurrentVenue.dbVenuesGeneral }
          VenuesUpdate(database, field, Xstate.posts.myCurrentVenue.dbVenuesGeneral)
          let fullAddress = ""
          if (
            x.field1 === "venue_address_line1" ||
            x.field1 === "venue_city" ||
            x.field1 === "venue_province" ||
            x.field1 === "venue_country"
          ) {
            fullAddress = ""
            if (
              tempValue.venue_address_line1 === undefined ||
              tempValue.venue_address_line1 === null ||
              tempValue.venue_address_line1 === ""
            ) {
              fullAddress += Xstate.posts.currentVenue.dbVenuesGeneral.venue_address_line1
            } else {
              fullAddress += tempValue.venue_address_line1
            }

            if (tempValue.venue_city === undefined || tempValue.venue_city === null || tempValue.venue_city === "") {
              fullAddress += ", " + Xstate.posts.currentVenue.dbVenuesGeneral.venue_city
            } else {
              fullAddress += ", " + tempValue.venue_city
            }

            if (
              tempValue.venue_province === undefined ||
              tempValue.venue_province === null ||
              tempValue.venue_province === ""
            ) {
              fullAddress += ", " + Xstate.posts.currentVenue.dbVenuesGeneral.venue_province
            } else {
              fullAddress += ", " + tempValue.venue_province
            }

            if (
              tempValue.venue_country === undefined ||
              tempValue.venue_country === null ||
              tempValue.venue_country === ""
            ) {
              fullAddress += ", " + Xstate.posts.currentVenue.dbVenuesGeneral.venue_country
            } else {
              fullAddress += ", " + tempValue.venue_country
            }

            tempValue.venue_address_line2 = fullAddress
            VenuesUpdate(database, "venue_address_line2", tempValue)
          }

          tquery("#updateReact").click()
        }}
      >
        <i className="fas fa-check opacity50" />
        {savedText.venues.venue_check_default}
      </button>
    )
    x.fieldEdit = (
      <button
        id={field + "Edit"}
        className="pup"
        onClick={() => {
          Xstate.posts.myCurrentVenue[field + "Edit"] = 1
          tquery("#updateReact").click()
        }}
      >
        <i className="fas fa-pencil-alt opacity50" />
        {savedText.venues.venue_edit_default}
      </button>
    )

    if (Xstate.posts.myCurrentVenue[field + "Edit"] === 1) {
      x.fieldCheck = <span />
      x.fieldEdit = <span />
      if (
        Xstate.posts.myCurrentVenue[database][field] === undefined ||
        Xstate.posts.myCurrentVenue[database][field] === null
      ) {
        Xstate.posts.myCurrentVenue[database][field] = Xstate.posts.currentVenue[database][field]
      }

      if (x.fieldPersonal === "") {
        for (let i = 1; i <= 4; i++) {
          Xstate.posts.myCurrentVenue.dbVenuesGeneral["field" + i] =
            Xstate.posts.currentVenue.dbVenuesGeneral["field" + i]
        }
      }
      x.fieldText = (
        <span>
          <input
            id={"Editfield" + field}
            className="input"
            value={Xstate.posts.myCurrentVenue.dbVenuesGeneral[field]}
            onChange={event => {
              Xstate.posts.myCurrentVenue.dbVenuesGeneral[field] = event.target.value
              tquery("#updateReact").click()
            }}
          />
          <button
            id={"saveGeneral" + field}
            className="btn btn-small btn-content1"
            onClick={() => {
              Xstate.posts.myCurrentVenue[field + "Edit"] = 0
              let tempValue = { ...Xstate.posts.myCurrentVenue.dbVenuesGeneral }
              VenuesUpdate(database, x.field1, Xstate.posts.myCurrentVenue.dbVenuesGeneral)

              let fullAddress = ""
              if (
                x.field1 === "venue_address_line1" ||
                x.field1 === "venue_city" ||
                x.field1 === "venue_province" ||
                x.field1 === "venue_country"
              ) {
                fullAddress = ""
                if (
                  tempValue.venue_address_line1 === undefined ||
                  tempValue.venue_address_line1 === null ||
                  tempValue.venue_address_line1 === ""
                ) {
                  fullAddress += Xstate.posts.currentVenue.dbVenuesGeneral.venue_address_line1
                } else {
                  fullAddress += tempValue.venue_address_line1
                }

                if (
                  tempValue.venue_city === undefined ||
                  tempValue.venue_city === null ||
                  tempValue.venue_city === ""
                ) {
                  fullAddress += ", " + Xstate.posts.currentVenue.dbVenuesGeneral.venue_city
                } else {
                  fullAddress += ", " + tempValue.venue_city
                }

                if (
                  tempValue.venue_province === undefined ||
                  tempValue.venue_province === null ||
                  tempValue.venue_province === ""
                ) {
                  fullAddress += ", " + Xstate.posts.currentVenue.dbVenuesGeneral.venue_province
                } else {
                  fullAddress += ", " + tempValue.venue_province
                }

                if (
                  tempValue.venue_country === undefined ||
                  tempValue.venue_country === null ||
                  tempValue.venue_country === ""
                ) {
                  fullAddress += ", " + Xstate.posts.currentVenue.dbVenuesGeneral.venue_country
                } else {
                  fullAddress += ", " + tempValue.venue_country
                }

                tempValue.venue_address_line2 = fullAddress
                VenuesUpdate(database, "venue_address_line2", tempValue)
              }
              tquery("#updateReact").click()
            }}
          >
            Save
          </button>
        </span>
      )
    } else if (
      x.fieldPersonal.toLowerCase().substring(0, 2) === x.fieldConsensus.toLowerCase().substring(0, 2) &&
      x.fieldPersonal !== ""
    ) {
      x.fieldCheck = (
        <span className="pup">
          <i className="fas fa-check font-accent1" />
          {savedText.venues.venue_check_match}
        </span>
      )
    } else if (x.fieldPersonal !== "") {
      let textField = savedText.venues.venue_check_wrong.props.children + x.fieldConsensus
      x.fieldCheck = (
        <span className="pup">
          <i className="fas fa-question-circle font-accent1" />
          <span> {textField} </span>
        </span>
      )
    }

    if (label === "Website") {
      if (Xstate.posts.myCurrentVenue[field + "Edit"] !== 1) {
        let dotdotdot = ""
        let pup = ""
        if (x.fieldText.length > 30) {
          dotdotdot = "..."
          pup = <span>{x.fieldText}</span>
        }
        x.fieldText = (
          <a href={x.fieldText} target="_blank" rel="noopener noreferrer" className="pup">
            {x.fieldText.substr(0, 30) + dotdotdot}
            {pup}
          </a>
        )
      }
    }

    x.fieldResults = (
      <span>
        {x.fieldCheck}
        {x.fieldEdit}
        &nbsp;
        {x.fieldText}
      </span>
    )

    if (Xstate.posts.currentVenue.dbVenuesGeneral.post_id === "new") {
      x.fieldResults = (
        <span>
          <input
            id={"venue" + field}
            type="text"
            className="input"
            value={Xstate.posts.myCurrentVenue.dbVenuesGeneral[field]}
            onChange={event => {
              Xstate.posts.myCurrentVenue.dbVenuesGeneral[field] = event.target.value
              tquery("#updateReact").click()
            }}
          />
        </span>
      )
    }

    return [
      <td key={postsCount++} className="td-padding left">
        <b> {label}: </b>
      </td>,
      <td key={postsCount++} className="left">
        {x.fieldResults}
      </td>,
    ]
  }
  venuesSportsField = (label, field) => {
    let database = "dbVenuesSportsValues"

    let x = {}

    //FIELDS
    x.field1 = "" + field
    x.field2 = field.substr(0, 9) + (1 * field.substr(9, 100) + 1).toString()
    x.field3 = field.substr(0, 9) + (1 * field.substr(9, 100) + 2).toString()
    x.field4 = field.substr(0, 9) + (1 * field.substr(9, 100) + 3).toString()

    //Create myCurrentVenue
    if (Xstate.posts.myCurrentVenue[Xstate.posts.myCurrentVenue.sport] === undefined) {
      Xstate.posts.myCurrentVenue[Xstate.posts.myCurrentVenue.sport] = {}
    }

    for (let i = 1; i <= 4; i++) {
      x["fieldText" + i] = ""
      if (
        Xstate.posts.currentVenue[database][Xstate.posts.currentVenue.sport][x["field" + i]] === undefined ||
        Xstate.posts.currentVenue[database][Xstate.posts.currentVenue.sport][x["field" + i]] === null
      ) {
        x["fieldConsensus" + i] = ""
      } else {
        x["fieldConsensus" + i] =
          "" + Xstate.posts.currentVenue[database][Xstate.posts.currentVenue.sport][x["field" + i]].toString()
      }

      if (
        Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][x["field" + i]] === undefined ||
        Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][x["field" + i]] === null
      ) {
        x["fieldPersonal" + i] = ""
        x["fieldText" + i] = "" + x["fieldConsensus" + i]
      } else {
        x["fieldPersonal" + i] =
          "" + Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][x["field" + i]].toString()
        x["fieldText" + i] = "" + x["fieldPersonal" + i]
      }
    }
    x.fields = ["fieldText", "fieldConsensus", "fieldPersonal"]
    for (let i = 0; i < 3; i++) {
      x[x.fields[i]] = "" + x[x.fields[i] + 1]
      if (label === "Hourly Rate") {
        if (!isNaN(1 * x[x.fields[i]])) {
          x[x.fields[i]] = "$" + (1 * x[x.fields[i]]).toFixed(2)
        }
      }
      if (label === "Annual Cost") {
        if (!isNaN(1 * x[x.fields[i]])) {
          let cost = 1 * x[x.fields[i]]
          if (cost <= 100) x[x.fields[i]] = "$0 to $100"
          else if (cost <= 300) x[x.fields[i]] = "$100 to $300"
          else if (cost <= 1000) x[x.fields[i]] = "$300 to $1,000"
          else x[x.fields[i]] = "$1,000+"
        }
      }
      if (label === "Membership") {
        if (isNaN(1 * x[x.fields[i]])) x[x.fields[i]] = 0
        if (isNaN(1 * x[x.fields[i + 1]])) x[x.fields[i + 1]] = 0
        if (isNaN(1 * x[x.fields[i + 2]])) x[x.fields[i + 2]] = 0
        let localMax = Math.max(1 * x[x.fields[i]], 1 * x[x.fields[i + 1]], 1 * x[x.fields[i + 2]])
        if (localMax < 0.33) x[x.fields[i]] = "No membership"
        else if (1 * x[x.fields[i]] === localMax) x[x.fields[i]] = "Open"
        else if (1 * x[x.fields[i + 1]] === localMax) x[x.fields[i]] = "Waitlist"
        else if (1 * x[x.fields[i + 2]] === localMax) x[x.fields[i]] = "Closed"
        console.log(x)
      }
      if (label === "Membership") {
        if (isNaN(1 * x[x.fields[i]])) x[x.fields[i]] = 0
        if (isNaN(1 * x[x.fields[i + 1]])) x[x.fields[i + 1]] = 0
        if (isNaN(1 * x[x.fields[i + 2]])) x[x.fields[i + 2]] = 0
        let localMax = Math.max(1 * x[x.fields[i]], 1 * x[x.fields[i + 1]], 1 * x[x.fields[i + 2]])
        if (localMax < 0.33) x[x.fields[i]] = "No membership"
        else if (1 * x[x.fields[i]] === localMax) x[x.fields[i]] = "Open"
        else if (1 * x[x.fields[i + 1]] === localMax) x[x.fields[i]] = "Waitlist"
        else if (1 * x[x.fields[i + 2]] === localMax) x[x.fields[i]] = "Closed"
        console.log("TYLER")
      }
      if (label === "Number of Courts" || label === "Busy Level") {
        if (!isNaN(1 * x[x.fields[i]])) {
          x[x.fields[i]] = (1 * x[x.fields[i]]).toFixed(0)
          x.fieldButton = " (max 5)"
        }
      }

      if (label === "Free/Paid") {
        if (!isNaN(1 * x[x.fields[i]])) {
          x[x.fields[i]] = (1 * x[x.fields[i]]).toFixed(0)
          if (x[x.fields[i]] === "1") {
            x[x.fields[i]] = "Free"
          }
          if (x[x.fields[i]] === "0") {
            x[x.fields[i]] = "Paid"
          }
        }
      }
      if (label === "Book in Advance") {
        if (!isNaN(1 * x[x.fields[i]])) {
          x[x.fields[i]] = (1 * x[x.fields[i]]).toFixed(0)
          if (x.fields[i] === "fieldConsensus") {
            if (x[x.fields[i]] === "1") {
              x[x.fields[i]] = "Yes"
            }
            if (x[x.fields[i]] === "0") {
              x[x.fields[i]] = "No"
            }
          } else {
            if (x[x.fields[i]] === "1") {
              x[x.fields[i]] = "Yes: You can book."
            }
            if (x[x.fields[i]] === "0") {
              x[x.fields[i]] = "No: Just show up."
            }
          }
        }
      }
      if (label === "Lights") {
        if (!isNaN(1 * x[x.fields[i]])) {
          x[x.fields[i]] = (1 * x[x.fields[i]]).toFixed(0)
          if (x[x.fields[i]] === "1") {
            x[x.fields[i]] = "Yes"
          }
          if (x[x.fields[i]] === "0") {
            x[x.fields[i]] = "No"
          }
        }
      }
      if (label === "Seasonal") {
        if (!isNaN(1 * x[x.fields[i]])) {
          x[x.fields[i]] = (1 * x[x.fields[i]]).toFixed(0)
          if (x[x.fields[i]] === "1") {
            x[x.fields[i]] = "Yes"
          }
          if (x[x.fields[i]] === "0") {
            x[x.fields[i]] = "No"
          }
        }
      }
      if (label === "Indoor/Outdoor") {
        x.buildingNames = ["Both", "Indoor", "Outdoor"]
        x[x.fields[i] + 1] = (1 * x[x.fields[i] + 1]).toFixed(0)
        x[x.fields[i] + 2] = (1 * x[x.fields[i] + 2]).toFixed(0)
        if (x[x.fields[i] + 1] === "1" && x[x.fields[i] + 2] === "1") {
          x[x.fields[i]] = "Both"
        } else if (x[x.fields[i] + 1] === "1") {
          x[x.fields[i]] = "Indoor"
        } else if (x[x.fields[i] + 2] === "1") {
          x[x.fields[i]] = "Outdoor"
        } else {
          x[x.fields[i]] = ""
        }
      }
      if (label === "Court Type") {
        x[x.fields[i]] = ""
        x.courtNames = Xstate.posts.filters_id.courtNames
        for (let j = 1; j <= 4; j++) {
          x[x.fields[i] + j] = (1 * x[x.fields[i] + j]).toFixed(0)
          if (x[x.fields[i] + j] === "1") {
            x[x.fields[i]] += x.courtNames[j] + ", "
          }
        }
        if (x[x.fields[i]] === "") {
          //Do Nothing
        } else {
          x[x.fields[i]] = x[x.fields[i]].substr(0, x[x.fields[i]].length - 2)
        }
      }
    }
    x.fieldCheck = (
      <button
        className="pup"
        onClick={() => {
          Xstate.posts.myCurrentVenue.dbVenuesSportsValues[Xstate.posts.myCurrentVenue.sport][field] =
            Xstate.posts.currentVenue.dbVenuesSportsValues[Xstate.posts.currentVenue.sport][field]
          VenuesUpdate(
            database,
            field,
            Xstate.posts.myCurrentVenue.dbVenuesSportsValues[Xstate.posts.myCurrentVenue.sport],
          )
          tquery("#updateReact").click()
        }}
      >
        <i className="fas fa-check opacity50" />
        {savedText.venues.venue_check_default}
      </button>
    )
    x.fieldEdit = (
      <button
        id={field + "Edit"}
        className="pup"
        onClick={() => {
          if (Xstate.posts.myCurrentVenue[Xstate.posts.myCurrentVenue.sport] === undefined) {
            Xstate.posts.myCurrentVenue[Xstate.posts.myCurrentVenue.sport] = {}
          }
          Xstate.posts.myCurrentVenue[Xstate.posts.myCurrentVenue.sport][field + "Edit"] = 1
          tquery("#updateReact").click()
        }}
      >
        <i className="fas fa-pencil-alt opacity50" />
        {savedText.venues.venue_edit_default}
      </button>
    )

    if (Xstate.posts.myCurrentVenue[Xstate.posts.myCurrentVenue.sport][field + "Edit"] === 1) {
      x.fieldCheck = <span />
      x.fieldEdit = <span />
      if (
        Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] === undefined ||
        Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] === null
      ) {
        Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] =
          Xstate.posts.currentVenue[database][Xstate.posts.currentVenue.sport][field]
      }

      x.fieldText = (
        <input
          className="input"
          value={Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field]}
          onChange={event => {
            Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] = event.target.value
            tquery("#updateReact").click()
          }}
        ></input>
      )
      x.fieldButton = (
        <button
          className="btn btn-small btn-content1"
          onClick={() => {
            Xstate.posts.myCurrentVenue[Xstate.posts.myCurrentVenue.sport][field + "Edit"] = 0
            let tempValue = {
              ...Xstate.posts.myCurrentVenue.dbVenuesSportsValues[Xstate.posts.myCurrentVenue.sport],
            }
            VenuesUpdate(database, x.field1, tempValue)
            if (label === "Free/Paid") {
              if (1 * tempValue === 1) {
                //console.log(x.field2)
                VenuesUpdate(database, x.field2, tempValue)
              }
            }
            if (label === "Indoor/Outdoor") {
              VenuesUpdate(database, x.field2, tempValue)
            }
            if (label === "Court Type") {
              VenuesUpdate(database, x.field2, tempValue)
              VenuesUpdate(database, x.field3, tempValue)
              VenuesUpdate(database, x.field4, tempValue)
            }

            tquery("#updateReact").click()
          }}
        >
          Save
        </button>
      )
    } else if (
      x.fieldConsensus !== "" &&
      x.fieldConsensus.toLowerCase().substring(0, 2) === x.fieldPersonal.toLowerCase().substring(0, 2)
    ) {
      x.fieldCheck = (
        <span className="pup">
          <i className="fas fa-check font-accent1" />
          {savedText.venues.venue_check_match}
        </span>
      )
    } else if (x.fieldConsensus === "") {
    } else {
      let textField = savedText.venues.venue_check_wrong.props.children + x.fieldConsensus
      x.fieldCheck = (
        <span className="pup">
          <i className="fas fa-question-circle font-accent1" />
          <span> {textField} </span>
        </span>
      )
    }

    //SPECIAL CASES
    if (
      Xstate.posts.myCurrentVenue[Xstate.posts.myCurrentVenue.sport][field + "Edit"] === 1 ||
      Xstate.posts.currentVenue.dbVenuesGeneral.post_id === "new"
    ) {
      if (label === "Free/Paid") {
        x.fieldText = (
          <select
            className="input"
            value={Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field]}
            onChange={event => {
              if (event.target.value === "1") {
                x.field2 = field.substr(0, 9) + (1 * field.substr(9, 100) + 1).toString()
                Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][x.field2] = 0
              }
              Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] = event.target.value

              tquery("#updateReact").click()
            }}
          >
            <option value="1">Free</option>
            <option value="0">Paid</option>
          </select>
        )
      }
      if (label === "Hourly Rate" || label === "Annual Cost") {
        if (isNaN(1 * Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field])) {
          x.fieldButton = <span> must be a # </span>
        }
        x.fieldText = (
          <span>
            $
            <input
              className="input-number w5em"
              type="number"
              value={Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field]}
              step="0.01"
              onChange={event => {
                Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] = 1 * event.target.value

                tquery("#updateReact").click()
              }}
            />
          </span>
        )
      }
      if (label === "Number of Courts") {
        if (isNaN(1 * Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field])) {
          x.fieldButton = <span> must be a # </span>
        }
        x.fieldText = (
          <span>
            <input
              className="input-number w5em"
              type="number"
              value={Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field]}
              step="1"
              onChange={event => {
                Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] = 1 * event.target.value

                tquery("#updateReact").click()
              }}
            />
          </span>
        )
      }
      if (label === "Book in Advance") {
        x.fieldText = (
          <select
            className="input"
            value={Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field]}
            onChange={event => {
              Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] = event.target.value

              tquery("#updateReact").click()
            }}
          >
            <option value="1">Yes: You can book.</option>
            <option value="0">No: Just show up.</option>
          </select>
        )
      }
      if (label === "Lights") {
        x.fieldText = (
          <select
            className="input"
            value={Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field]}
            onChange={event => {
              Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] = event.target.value

              tquery("#updateReact").click()
            }}
          >
            <option value="1">Yes </option>
            <option value="0">No </option>
          </select>
        )
      }
      if (label === "Seasonal") {
        x.fieldText = (
          <select
            className="input"
            value={Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field]}
            onChange={event => {
              Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] = event.target.value

              tquery("#updateReact").click()
            }}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        )
      }
      if (label === "Busy Level") {
        x.fieldText = (
          <select
            className="input"
            value={Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field]}
            onChange={event => {
              Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][field] = event.target.value
              tquery("#updateReact").click()
            }}
          >
            <option value="1">{Xstate.posts.filters_id.busyArray[1]}</option>
            <option value="2">{Xstate.posts.filters_id.busyArray[2]}</option>
            <option value="3">{Xstate.posts.filters_id.busyArray[3]}</option>
            <option value="4">{Xstate.posts.filters_id.busyArray[4]}</option>
            <option value="5">{Xstate.posts.filters_id.busyArray[5]}</option>
          </select>
        )
      }
      if (label === "Indoor/Outdoor") {
        x.fieldText = []
        for (let i = 1; i <= 2; i++) {
          if (1 * x["fieldPersonal" + i] === 1) {
            x.fieldText.push(
              <button
                key={postsCount++}
                onClick={() => {
                  Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][x["field" + i]] = 0

                  tquery("#updateReact").click()
                }}
                className="btn btn-content1 btn-small active"
              >
                {x.buildingNames[i]}
              </button>,
            )
          } else {
            x.fieldText.push(
              <button
                key={postsCount++}
                onClick={() => {
                  Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][x["field" + i]] = 1

                  tquery("#updateReact").click()
                }}
                className="btn btn-content1 btn-small opacity50"
              >
                {x.buildingNames[i]}
              </button>,
            )
          }
        }
      }
      if (label === "Court Type") {
        x.fieldText = []
        for (let i = 1; i <= 4; i++) {
          if (1 * x["fieldPersonal" + i] === 1) {
            x.fieldText.push(
              <button
                key={postsCount++}
                onClick={() => {
                  Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][x["field" + i]] = 0

                  tquery("#updateReact").click()
                }}
                className="btn btn-content1 btn-small active"
              >
                {x.courtNames[i]}
              </button>,
            )
          } else {
            x.fieldText.push(
              <button
                key={postsCount++}
                onClick={() => {
                  Xstate.posts.myCurrentVenue[database][Xstate.posts.myCurrentVenue.sport][x["field" + i]] = 1
                  tquery("#updateReact").click()
                }}
                className="btn btn-content1 btn-small opacity50"
              >
                {x.courtNames[i]}
              </button>,
            )
          }
        }
      }
    }
    if (Xstate.posts.currentVenue.dbVenuesGeneral.post_id === "new") {
      x.fieldCheck = <span />
      x.fieldEdit = <span />
      x.fieldButton = <span />
    }

    let fieldResults = (
      <span>
        {x.fieldCheck}
        {x.fieldEdit}
        &nbsp;
        {x.fieldText}
        {x.fieldButton}
      </span>
    )

    return [
      <td key={postsCount++} className="td-padding left">
        <b> {label}: </b>
      </td>,
      <td className="left" key={postsCount++}>
        {fieldResults}
      </td>,
    ]
  }
  render() {
    var replyToggle
    var myToday
    var replyJSX
    var updatePostJSX
    var deletePostJSX
    var addPostDate
    PostsUpdate()

    if (Xstate.posts.currentPost === undefined) Xstate.posts.currentPost = {}
    if (Xstate.posts.currentPost.addupdate === "update") {
      Xstate.posts.currentPost.addupdate = "update"
      if (Xstate.dbStatusValue !== "connected") {
        Xstate.posts.currentPost.addupdate = "view"
      }
    }
    if (Xstate.modal.status === "members") {
      if (Xstate.login.status === false) {
        if (Xstate.posts.currentPost.addupdate === "add") {
          return modalTextNoButton(
            <>
              <h2> Message Board</h2>
              <div>
                <br />
                <b>Join ConnactiveClub Today</b>
                <br />
                <br />
                <button
                  className="btn btn-content1"
                  onClick={() => {
                    LoginModal()
                  }}
                >
                  login
                </button>
              </div>
            </>,
          )
        } else {
          return modalTextNoButton(
            <>
              <h2> {Xstate.posts.currentPost.title} </h2>
              <br />
              <br /> {Xstate.posts.currentPost.content}
              <br />
              <br />
              <br />
              {Xstate.posts.currentPost.user_name} ({tDateFormat(Xstate.posts.currentPost.update_date, "full")})
              <br />
              <br />
              {replyJSX}
              <br />
              <br />
              <b>Join ConnactiveClub Today</b>
              <br />
              <br />
              <button
                className="btn btn-content1"
                onClick={() => {
                  LoginModal()
                }}
              >
                login
              </button>
            </>,
          )
        }
      }
    }
    if (Xstate.modal.status === "members") {
      if (Xstate.login.status === true) {
        if (Xstate.posts.currentPost.addupdate === "add") {
          replyToggle = <span />
          myToday = new Date()
          addPostDate = dateformat(myToday, "yyyymmddHHMMss")

          if (Xstate.posts.replyEmail * 1 === 1) {
            replyToggle = (
              <span>
                <i
                  onClick={() => {
                    Xstate.posts.replyEmail = "0"
                    Xstate.modal.status = "members"
                    tquery("#updateReact").click()
                  }}
                  className="fa fa-toggle-on"
                >
                  Yes
                </i>
                <i
                  onClick={() => {
                    Xstate.posts.replyEmail = "0"
                    Xstate.modal.status = "members"
                    tquery("#updateReact").click()
                  }}
                  className="fa fa-toggle-off"
                >
                  No
                </i>
              </span>
            )
          } else if (Xstate.posts.replyEmail * 1 === 0) {
            replyToggle = (
              <span>
                <i
                  onClick={() => {
                    Xstate.posts.replyEmail = "1"
                    Xstate.modal.status = "members"
                    tquery("#updateReact").click()
                  }}
                  className="fa fa-toggle-off"
                >
                  Yes
                </i>
                <i
                  onClick={() => {
                    Xstate.posts.replyEmail = "1"
                    Xstate.modal.status = "members"
                    tquery("#updateReact").click()
                  }}
                  className="fa fa-toggle-on"
                >
                  No
                </i>
              </span>
            )
          }

          //<<<<<<>>>>> NOT USED
          let replyInEmail = (
            <span>
              Would you like to receive reply in email ?<br />
              {replyToggle}
            </span>
          )
          replyInEmail = ""

          return modalTextNoButton(
            <>
              <h2 id="memberPostHearderAdd"> Message Board </h2>
              <table className="table-center left">
                <tbody>
                  <tr>
                    <td className="td-left" colSpan="2">
                      Subject:
                      <br />
                      <input
                        type="text"
                        id="messageBoardAddSubjectInput"
                        className="input input-large"
                        value={Xstate.posts.currentPost.title}
                        onChange={event => {
                          Xstate.posts.currentPost.title = event.target.value
                          tquery("#updateReact").click()
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="td-left" colSpan="2">
                      Message:
                      <br />
                      <textarea
                        id="messageBoardAddMessageInput"
                        wrap="soft"
                        className="input textarea input-large"
                        value={Xstate.posts.currentPost.content.split("<br/>").join("\n")}
                        onChange={event => {
                          Xstate.posts.currentPost.content = event.target.value
                          tquery("#updateReact").click()
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="td-left">User:</td>
                    <td className="td-left">{Xstate.login.username}</td>
                  </tr>
                  <tr>
                    <td className="td-left">Date:</td>
                    <td className="td-left">{tDateFormat(addPostDate, "full")}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              {replyInEmail}
              <br />
              <div id="alert-posts-message">{Xstate.posts.msg}</div>
              <br />
              <button
                className="btn btn-content1"
                id="messageBoardAddExecuteButton"
                onClick={() => {
                  if (Xstate.posts.currentPost.content === "" || Xstate.posts.currentPost.title === "")
                    Xstate.posts.msg = "Please fill in subject and content"
                  else {
                    if (Xstate.posts.dbPostsTemp === undefined) Xstate.posts.dbPostsTemp = { x: "y" }
                    Xstate.posts.dbPostsTemp = [...Xstate.posts.dbPosts]
                    if (Xstate.posts.dbPostsTemp[Xstate.posts.dbPostsTemp.length] === undefined) {
                      Xstate.posts.dbPostsTemp[Xstate.posts.dbPostsTemp.length] = {}
                    }
                    if (Xstate.posts.currentPost.title === undefined) Xstate.posts.currentPost.title = ""

                    let thisPost = Xstate.posts.currentPost
                    thisPost.post_id = "new"
                    thisPost.deleted = "0"
                    thisPost.email = Xstate.posts.replyEmail
                    thisPost.expired = "0"
                    thisPost.private = "0"
                    thisPost.reply = ""
                    thisPost.reply_date = ""
                    myToday = new Date()
                    thisPost.update_date = dateformat(myToday, "yyyymmddHHMMss")
                    thisPost.user_email = Xstate.login.email
                    thisPost.user_id = Xstate.login.userid
                    thisPost.user_name = Xstate.login.username
                    console.log(thisPost)

                    //VENUES
                    if (cleanPath().substring(0, 6) === "venues") {
                      if (cleanPath().substring(7, 16) > 0) {
                        thisPost.venue_id = cleanPath().substring(7, 16)
                      }
                    }
                    //club
                    if (cleanPath().substring(0, 4) === "club") {
                      if (cleanPath().substring(6, 15) > 0) {
                        thisPost.league_id = cleanPath().substring(5, 16)
                      }
                    }
                    var formData = new FormData()
                    formData.append("section", "posts")
                    formData.append("request", "save-post")
                    formData.append("post_id", thisPost.post_id)
                    formData.append("details", JSON.stringify(thisPost))

                    tquery("#updateReact").click()

                    if (Xstate.testing === "on") {
                      console.log("FETCH")
                      console.log("section:posts")
                      console.log("request:save-post")
                      console.log("post_id:" + thisPost.post_id)
                      console.log("details:" + JSON.stringify(thisPost))
                    }
                    Xstate.posts.dbPostsTemp = 0
                    Xstate.posts.dbPosts = 0
                    Xstate.posts.replyEmail = "0"
                    //localStorage.dbPosts = 0;
                    Xstate.posts.msg = "Your post has been logged successfully."
                    Xstate.modal.status = "postsuccess"

                    tquery("#updateReact").click()

                    return (
                      //should be http://localhost/connactiveclub/public for dev
                      fetch(fetchLink, {
                        credentials: "include",
                        method: "POST",
                        body: formData,
                      })
                        .then(response => {
                          return response.text()
                        })
                        .then(data => {
                          Xstate.posts.dbPostsTemp = 0
                          Xstate.posts.dbPosts = 0
                          //localStorage.dbPosts = 0;

                          tquery("#updateReact").click()
                        })
                    )
                  }
                }}
              >
                add post
              </button>
            </>,
          )
        } else if (Xstate.posts.currentPost.addupdate === "update") {
          var likeButtonStatus = "btn btn-content1 likeInactiveUpdate"
          var dislikeButtonStatus = "btn btn-content1 dislikeInactiveUpdate"
          if (Xstate.posts.currentPost.my_like_count * 1 === 1) {
            likeButtonStatus = "btn btn-content1 active likeActiveUpdate"
          }
          if (Xstate.posts.currentPost.my_dislike_count * 1 === 1) {
            dislikeButtonStatus = "btn btn-content1 active dislikeActiveUpdate"
          }
          replyJSX = <tr />
          updatePostJSX = <span />
          replyToggle = <span />
          if (Xstate.posts.replyEmail * 1 === 1) {
            replyToggle = (
              <span>
                <i
                  onClick={() => {
                    Xstate.posts.replyEmail = "0"
                    Xstate.modal.status = "members"
                    tquery("#updateReact").click()
                  }}
                  className="fa fa-toggle-on"
                >
                  Yes
                </i>
                <i
                  onClick={() => {
                    Xstate.posts.replyEmail = "0"
                    Xstate.modal.status = "members"
                    tquery("#updateReact").click()
                  }}
                  className="fa fa-toggle-off"
                >
                  No
                </i>
              </span>
            )
          } else if (Xstate.posts.replyEmail * 1 === 0) {
            replyToggle = (
              <span>
                <i
                  onClick={() => {
                    Xstate.posts.replyEmail = "1"
                    Xstate.modal.status = "members"
                    tquery("#updateReact").click()
                  }}
                  className="fa fa-toggle-off"
                >
                  Yes
                </i>
                <i
                  onClick={() => {
                    Xstate.posts.replyEmail = "1"
                    Xstate.modal.status = "members"
                    tquery("#updateReact").click()
                  }}
                  className="fa fa-toggle-on"
                >
                  No
                </i>
              </span>
            )
          }
          if (Xstate.login.userid === "A1") {
            replyJSX = (
              <tr>
                <td className="td-left" colSpan="2">
                  &nbsp; <br /> Admin reply: <br /> &nbsp; <br />
                  <textarea
                    id="memberPostAdminReply"
                    wrap="soft"
                    className="input textarea input-large"
                    value={Xstate.posts.reply}
                    onChange={event => {
                      Xstate.posts.reply = event.target.value

                      tquery("#updateReact").click()
                    }}
                  />
                </td>
              </tr>
            )
          }
          updatePostJSX = (
            <button
              id="buttonUpdatePost"
              className="btn btn-content1"
              onClick={() => {
                if (Xstate.posts.currentPost.content === "" || Xstate.posts.currentPost.title === "") {
                  Xstate.posts.msg = "please fill in subject and content"
                  tquery("#updateReact").click()
                } else {
                  if (Xstate.posts.dbPostsTemp === undefined) {
                    Xstate.posts.dbPostsTemp = { x: "y" }
                  }
                  Xstate.posts.dbPostsTemp = [...Xstate.posts.dbPosts]
                  if (Xstate.posts.currentPost === undefined) Xstate.posts.currentPost = {}
                  if (Xstate.posts.currentPost.title === undefined) Xstate.posts.currentPost.title = ""
                  if (Xstate.posts.currentPost.reply === undefined) Xstate.posts.currentPost.reply = ""
                  if (Xstate.posts.currentPost.reply_date === undefined) Xstate.posts.currentPost.reply_date = ""
                  if (Xstate.posts.currentPost.reply !== Xstate.posts.replyOriginal) {
                    myToday = new Date()
                    Xstate.posts.currentPost.reply_date = dateformat(myToday, "yyyymmddHHMMss")
                  }
                  if (
                    Xstate.posts.currentPost.reply_date === "" &&
                    Xstate.posts.currentPost.reply !== "" &&
                    Xstate.posts.currentPost.replyEmail * 1 === 1
                  ) {
                    Xstate.posts.currentPost.replyEmail = "2"
                  }
                  if (Xstate.posts.currentPost.reply_date !== "" && Xstate.posts.currentPost.replyEmail * 1 === 2) {
                    Xstate.posts.currentPost.replyEmail = "1"
                  }
                  Xstate.posts.currentPost.deleted = "0"
                  Xstate.posts.currentPost.email = Xstate.posts.replyEmail
                  Xstate.posts.currentPost.expired = "0"
                  Xstate.posts.currentPost.private = "0"
                  if (Xstate.posts.currentPost.reply === undefined) Xstate.posts.currentPost.reply = ""
                  if (Xstate.posts.currentPost.reply_date === undefined) Xstate.posts.currentPost.reply_date = ""
                  myToday = new Date()
                  Xstate.posts.currentPost.update_date = dateformat(myToday, "yyyymmddHHMMss")
                  Xstate.posts.currentPost.user_email = Xstate.login.email
                  Xstate.posts.currentPost.user_id = Xstate.login.userid
                  Xstate.posts.currentPost.user_name = Xstate.login.username
                  var formData = new FormData()
                  formData.append("section", "posts")
                  formData.append("request", "save-post")
                  formData.append("post_id", Xstate.posts.currentPost.post_id)
                  formData.append("details", JSON.stringify(Xstate.posts.currentPost))
                  if (Xstate.testing === "on") {
                    console.log("FETCH")
                    console.log("section:posts")
                    console.log("request:save-post")
                    console.log("post_id:" + Xstate.posts.currentPost.post_id)
                    console.log("details:" + JSON.stringify(Xstate.posts.currentPost))
                  }
                  Xstate.posts.dbPostsTemp = 0
                  Xstate.posts.dbPosts = 0
                  //localStorage.dbPosts = 0;
                  Xstate.posts.msg = "Your post has been updated successfully."
                  Xstate.modal.status = "postsuccess"

                  tquery("#updateReact").click()

                  return (
                    //should be http://localhost/connactiveclub/public for dev
                    fetch(fetchLink, {
                      credentials: "include",
                      method: "POST",
                      body: formData,
                    })
                      .then(response => {
                        return response.text()
                      })
                      .then(data => {
                        if (data === "post success") {
                          Xstate.posts.replyEmail = "0"

                          tquery("#updateReact").click()
                        }
                      })
                  )
                }
              }}
            >
              Update post
            </button>
          )
          return modalTextNoButton(
            <>
              <h2 id="memberPostHeaderUpdate"> Message Board </h2>
              <table className="table-center left">
                <tbody>
                  <tr>
                    <td className="td-left" colSpan="2">
                      Subject:
                      <br />
                      <input
                        id="messageBoardUpdateSubjectInput"
                        type="text"
                        className="input input-large"
                        value={Xstate.posts.currentPost.title}
                        onChange={event => {
                          Xstate.posts.currentPost.title = event.target.value
                          tquery("#updateReact").click()
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="td-left" colSpan="2">
                      Message:
                      <br />
                      <textarea
                        id="messageBoardUpdateMessageInput"
                        wrap="soft"
                        className="input textarea input-large"
                        value={Xstate.posts.currentPost.content.split("<br/>").join("\n")}
                        onChange={event => {
                          Xstate.posts.currentPost.content = event.target.value
                          tquery("#updateReact").click()
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="td-left">User:</td>
                    <td className="td-left paddingL10">{Xstate.posts.currentPost.user_name}</td>
                  </tr>
                  <tr>
                    <td className="td-left">Date:</td>
                    <td className="td-left paddingL10">{tDateFormat(Xstate.posts.currentPost.update_date, "full")}</td>
                  </tr>
                  {replyJSX}
                </tbody>
              </table>
              <br />
              <br />
              <div id="alert-posts-message">{Xstate.posts.msg}</div>
              <br />
              {updatePostJSX}
              <button
                className="btn btn-content1"
                id="buttonDeletePost"
                onClick={() => {
                  if (Xstate.posts.dbPostsTemp === undefined) Xstate.posts.dbPostsTemp = []
                  Xstate.posts.dbPostsTemp = [...Xstate.posts.dbPosts]
                  if (Xstate.posts.currentPost === undefined) Xstate.posts.currentPost = {}
                  if (Xstate.posts.currentPost.title === undefined) Xstate.posts.currentPost.title = ""
                  if (Xstate.posts.currentPost.reply === undefined) Xstate.posts.currentPost.reply = ""
                  if (Xstate.posts.currentPost.reply_date === undefined) Xstate.posts.currentPost.reply_date = ""
                  Xstate.posts.currentPost.deleted = "1"
                  Xstate.posts.currentPost.expired = "0"
                  Xstate.posts.currentPost.private = "0"
                  myToday = new Date()
                  Xstate.posts.currentPost.update_date = tDateFormat(new Date(), "num-full(secs)")
                  Xstate.posts.currentPost.user_email = Xstate.login.email
                  Xstate.posts.currentPost.user_id = Xstate.login.userid
                  Xstate.posts.currentPost.user_name = Xstate.login.username
                  var formData = new FormData()
                  formData.append("section", "posts")
                  formData.append("request", "save-post")
                  formData.append("post_id", Xstate.posts.currentPost.post_id)
                  formData.append("details", JSON.stringify(Xstate.posts.currentPost))

                  Xstate.posts.dbPostsTemp = 0
                  Xstate.posts.last_update = "0"
                  //localStorage.dbPosts = 0;
                  Xstate.posts.replyEmail = "0"
                  Xstate.posts.msg = "Your post has been deleted successfully."
                  Xstate.modal.status = "postsuccess"

                  tquery("#updateReact").click()

                  return (
                    //should be http://localhost/connactiveclub/public for dev
                    fetch(fetchLink, {
                      credentials: "include",
                      method: "POST",
                      body: formData,
                    })
                      .then(response => {
                        return response.text()
                      })
                      .then(data => {
                        Xstate.posts.dbPostsTemp = 0
                        Xstate.posts.replyEmail = "0"
                        Xstate.posts.dbPosts = 0

                        tquery("#updateReact").click()
                      })
                  )
                }}
              >
                Delete post
              </button>
              <br />
              <br />
              <span style={{ fontSize: "130%" }}>
                <i className="fas fa-thumbs-up"></i>
                {Xstate.posts.currentPost.like_count}
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ fontSize: "130%" }}>
                <i className="fas fa-thumbs-down"></i>
                {Xstate.posts.currentPost.dislike_count}
              </span>
              <br />
              <button
                className={likeButtonStatus + " td-button"}
                style={{ width: "5em" }}
                onClick={() => {
                  Xstate.posts.dbPostsTemp = [...Xstate.posts.dbPosts]

                  if (Xstate.posts.dbPostsLikeTemp === undefined) Xstate.posts.dbPostsLikeTemp = []
                  if (Xstate.posts.dbPostsLikeTemp[0] === undefined) Xstate.posts.dbPostsLikeTemp[0] = {}
                  Xstate.posts.dbPostsLikeTemp[0].post_id = Xstate.posts.currentPost.post_id
                  Xstate.posts.dbPostsLikeTemp[0].dislikes = 0
                  if (
                    Xstate.posts.dbPostsLikeTemp[0].likes === undefined ||
                    Xstate.posts.dbPostsLikeTemp[0].likes * 1 === 0
                  ) {
                    Xstate.posts.dbPostsLikeTemp[0].likes = 1
                  } else if (Xstate.posts.dbPostsLikeTemp[0].likes * 1 === 1) {
                    Xstate.posts.dbPostsLikeTemp[0].likes = 0
                  }
                  Xstate.posts.dbPostsLikeTemp[0].user_id = Xstate.login.userid

                  var formData = new FormData()
                  formData.append("section", "posts")
                  formData.append("request", "save-likes")
                  formData.append("post_id", Xstate.posts.dbPostsLikeTemp[0].post_id)
                  formData.append("details", JSON.stringify(Xstate.posts.dbPostsLikeTemp[0]))
                  if (Xstate.testing === "on") {
                    console.log("FETCH")
                    console.log("section:posts")
                    console.log("request:save-likes")
                    console.log("post_id:" + Xstate.posts.dbPostsLikeTemp[0].post_id)
                    console.log("details:" + JSON.stringify(Xstate.posts.dbPostsLikeTemp[0]))
                  }
                  return (
                    //should be http://localhost/connactiveclub/public for dev
                    fetch(fetchLink, {
                      credentials: "include",
                      method: "POST",
                      body: formData,
                    })
                      .then(response => {
                        return response.text()
                      })
                      .then(data => {
                        if (data === "post success") {
                          Xstate.posts.dbPostsTemp = 0
                          Xstate.posts.dbPosts = 0
                          //localStorage.dbPosts = 0;
                          Xstate.posts.replyEmail = "0"
                          Xstate.posts.msg = "Your post has been updated successfully."
                          Xstate.modal.status = "postsuccess"
                          tquery("#updateReact").click()
                        }
                      })
                  )
                }}
              >
                Like
              </button>
              <button
                className={dislikeButtonStatus + " td-button"}
                style={{ width: "5em" }}
                onClick={() => {
                  if (Xstate.posts.dbPostsLikeTemp === undefined) {
                    Xstate.posts.dbPostsLikeTemp = { x: "y" }
                  }
                  if (Xstate.posts.dbPostsTemp === undefined) {
                    Xstate.posts.dbPostsTemp = { x: "y" }
                  }
                  Xstate.posts.dbPostsTemp = [...Xstate.posts.dbPosts]
                  if (Xstate.posts.dbPostsLikeTemp[0] === undefined) {
                    Xstate.posts.dbPostsLikeTemp[0] = {}
                  }
                  Xstate.posts.dbPostsLikeTemp[0].post_id = Xstate.posts.currentPost.post_id
                  if (
                    Xstate.posts.dbPostsLikeTemp[0].dislikes === undefined ||
                    Xstate.posts.dbPostsLikeTemp[0].dislikes * 1 === 0
                  ) {
                    Xstate.posts.dbPostsLikeTemp[0].dislikes = 1
                  } else if (Xstate.posts.dbPostsLikeTemp[0].dislikes * 1 === 1) {
                    Xstate.posts.dbPostsLikeTemp[0].dislikes = 0
                  }
                  Xstate.posts.dbPostsLikeTemp[0].likes = 0
                  Xstate.posts.dbPostsLikeTemp[0].user_id = Xstate.login.userid

                  var formData = new FormData()
                  formData.append("section", "posts")
                  formData.append("request", "save-likes")
                  formData.append("post_id", Xstate.posts.dbPostsLikeTemp[0].post_id)
                  formData.append("details", JSON.stringify(Xstate.posts.dbPostsLikeTemp[0]))
                  if (Xstate.testing === "on") {
                    console.log("FETCH")
                    console.log("section:posts")
                    console.log("request:save-likes")
                    console.log("post_id:" + Xstate.posts.dbPostsLikeTemp[0].post_id)
                    console.log("details:" + JSON.stringify(Xstate.posts.dbPostsLikeTemp[0]))
                  }
                  return (
                    //should be http://localhost/connactiveclub/public for dev
                    fetch(fetchLink, {
                      credentials: "include",
                      method: "POST",
                      body: formData,
                    })
                      .then(response => {
                        return response.text()
                      })
                      .then(data => {
                        if (data === "post success") {
                          Xstate.posts.dbPostsTemp = 0
                          Xstate.posts.dbPosts = 0
                          //localStorage.dbPosts = 0;
                          Xstate.posts.replyEmail = "0"
                          Xstate.posts.msg = "Your post has been updated successfully."
                          Xstate.modal.status = "postsuccess"

                          tquery("#updateReact").click()
                        }
                      })
                  )
                }}
              >
                Dislike
              </button>
            </>,
          )
        } else if (Xstate.posts.currentPost.addupdate === "view") {
          replyJSX = <tr />
          updatePostJSX = <span />
          deletePostJSX = <span />

          if (Xstate.posts.currentPost.reply !== "" && Xstate.posts.currentPost.reply !== undefined) {
            replyJSX = []
            replyJSX.push(
              <tr key={postsCount++}>
                <td className="td-left">
                  <br />
                  <b> connactive club reply </b>
                  <br />
                  {Xstate.posts.currentPost.reply}
                </td>
              </tr>,
            )
            replyJSX.push(
              <tr key={postsCount++}>
                <td className="td-left">
                  <br />
                  ConnactiveClub ({Xstate.posts.currentPost.reply_date.substring(0, 4)}-
                  {Xstate.posts.currentPost.reply_date.substring(4, 6)}-
                  {Xstate.posts.currentPost.reply_date.substring(6, 8)})
                </td>
              </tr>,
            )
          }

          if (Xstate.posts.currentPost.user_id === Xstate.login.userid) {
            deletePostJSX = (
              <span>
                <button
                  className="btn btn-content1"
                  onClick={() => {
                    if (Xstate.posts.dbPostsTemp === undefined) {
                      Xstate.posts.dbPostsTemp = []
                    }
                    Xstate.posts.dbPostsTemp = [...Xstate.posts.dbPosts]
                    if (Xstate.posts.currentPost === undefined) Xstate.posts.currentPost = {}
                    if (Xstate.posts.currentPost.title === undefined) Xstate.posts.currentPost.title = ""
                    if (Xstate.posts.currentPostreply === undefined) Xstate.currentPost.posts.reply = ""
                    if (Xstate.posts.currentPost.reply_date === undefined) Xstate.posts.currentPost.reply_date = ""
                    if (Xstate.posts.currentPost.reply !== "") {
                      myToday = new Date()
                      Xstate.posts.reply_date = dateformat(myToday, "yyyymmddHHMMss")
                    }
                    Xstate.posts.currentPost.post_id = Xstate.posts.currentPost.post_id
                    Xstate.posts.currentPost.deleted = "1"
                    Xstate.posts.currentPost.email = Xstate.posts.currentPost.email
                    Xstate.posts.currentPost.expired = "0"
                    Xstate.posts.currentPost.private = "0"
                    myToday = new Date()
                    Xstate.posts.currentPost.update_date = tDateFormat(new Date(), "num-full(secs)")
                    Xstate.posts.currentPost.user_email = Xstate.login.email
                    Xstate.posts.currentPost.user_id = Xstate.login.userid
                    Xstate.posts.currentPost.user_name = Xstate.login.username
                    var formData = new FormData()
                    formData.append("section", "posts")
                    formData.append("request", "save-post")
                    formData.append("post_id", Xstate.posts.currentPost.post_id)
                    formData.append("details", JSON.stringify(Xstate.posts.currentPost))
                    Xstate.posts.dbPostsTemp = 0
                    Xstate.posts.dbPosts = 0
                    Xstate.posts.replyEmail = "0"
                    //localStorage.dbPosts = 0;
                    Xstate.posts.msg = "Your post has been deleted successfully."
                    Xstate.modal.status = "postsuccess"

                    tquery("#updateReact").click()

                    if (Xstate.testing === "on") {
                      console.log("FETCH")
                      console.log("section:posts")
                      console.log("request:save-post")
                      console.log("post_id:" + Xstate.posts.currentPost.post_id)
                      console.log("details:" + JSON.stringify(Xstate.posts.currentPost))
                    }
                    return (
                      //should be http://localhost/connactiveclub/public for dev
                      fetch(fetchLink, {
                        credentials: "include",
                        method: "POST",
                        body: formData,
                      })
                        .then(response => {
                          return response.text()
                        })
                        .then(data => {
                          Xstate.posts.update_date = 0
                          Xstate.posts.dbPostsTemp = 0
                          Xstate.posts.dbPosts = 0
                          //localStorage.dbPosts = 0;
                          Xstate.posts.replyEmail = "0"
                          //localStorage.dbPosts = 0;

                          tquery("#updateReact").click()
                        })
                    )
                  }}
                >
                  delete post
                </button>
                <br />
              </span>
            )
          }
          console.log(Xstate.posts.currentPost)

          return modalTextNoButton(
            <>
              <h2>{Xstate.posts.currentPost.title}</h2>
              {Xstate.posts.currentPost.content}
              <br />
              <br />
              <br />
              {Xstate.posts.currentPost.user_name} ({tDateFormat(Xstate.posts.currentPost.update_date, "full")}
              )
              <br />
              <br />
              {updatePostJSX}
              {deletePostJSX}
              <br />
              {this.likeButtons()}
            </>,
          )
        }
      }
    }
    if (Xstate.modal.status === "postsuccess") {
      return modalTextNoButton(<div id="success-message">{Xstate.posts.msg}</div>)
    }
    if (Xstate.modal.status === "playersearch") {
      return this.playerSearch()
    }
    if (Xstate.modal.status === "location") {
      return this.location()
    }

    /*TO DELETE <<<<<<>>>>>
    if (Xstate.modal.status.substr(0, 6) === "venues") {
      //CURRENT VENUE
      if (Xstate.posts.currentVenue === undefined) Xstate.posts.currentVenue = 0
      if (Xstate.posts.currentVenue === 0) return <span />

      //SPORTS
      for (let i = 0; i < sportsListABC.length; i++) {
        Xstate.posts.sports[sportsListABC[i]] = undefined
      }

      if (Xstate.posts.currentVenue.dbVenuesSportsValues !== undefined) {
        Object.keys(Xstate.posts.currentVenue.dbVenuesSportsValues).forEach(key => {
          let thisSport = Xstate.posts.currentVenue.dbVenuesSportsValues[key]
          Xstate.posts.sports[thisSport.sport_name] = 1 * thisSport.sport_exists
          ratings[thisSport.sport_name] = 1 * thisSport.sport_rating
        })
      }

      //CURRENT VENUE - MY ATTRIBUTES
      //if (Xstate.posts.myCurrentVenue === undefined) Xstate.posts.myCurrentVenue = 0

      if (Xstate.posts.myCurrentVenue === 0) {
        if (1 * Xstate.posts.venue_id > 0) {
          Xstate.posts.myCurrentVenue = Xstate.posts.dbVenues.personal.filter(venue => {
            return 1 * venue.dbVenuesGeneral.post_id === 1 * Xstate.posts.venue_id
          })
          if (Xstate.posts.myCurrentVenue.length === 0) {
            Xstate.posts.myCurrentVenue = [
              {
                dbVenuesComments: [],
                dbVenuesDropin: [],
                dbVenuesGeneral: {},
                dbVenuesPhotos: [],
                dbVenuesSportsValues: [],
              },
            ]
          }
          Xstate.posts.myCurrentVenue = Xstate.posts.myCurrentVenue[0]
          Xstate.posts.myCurrentVenue["dbVenuesGeneral"].post_id = 1 * Xstate.posts.venue_id
          Xstate.posts.myCurrentVenue["dbVenuesGeneral"].user_id = Xstate.login.userid
        }
      }

      //OUTPUT - VENUES

      if (Xstate.posts.currentVenue === 0 || Xstate.posts.currentVenue === 1) {
        return modalTextNoButton(
          <>
            <h2> loading </h2>
          </>,
        )
      }

      let shareButton = <span />
      if (Xstate.posts.currentVenue.dbVenuesGeneral.post_id !== "new") {
        shareButton = (
          <button
            className="btn btn-content1 btn-small fas fa-share-square pup"
            onClick={() => {
              CopyToClipboard("https://connactiveclub.com" + window.location.pathname)
            }}
          >
            <span> Copy to Clipboard </span>
          </button>
        )
      }

      return modalTextNoButton(
        <>
          <h2>
            {Xstate.posts.currentVenue.dbVenuesGeneral.name} {shareButton}
          </h2>
          {this.venuesMap()}
          <div className="center">
            {this.venuesNavigationButtons()}
            <br />
            <br />
            <div className="green-line table-center" style={{ width: "100px" }}></div>
            <div className="center table-center">{this.venuesJSX()}</div>
            {this.venuesBottomButtons()}
          </div>
        </>,
      )
    }*/

    return <span />
  }
}
