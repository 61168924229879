import {fetchLink} from "../fetchLink";

export var mapOptions = {
  lat: 43.6532,
  lng: 79.3832,
  zoom: 8
};

//CALCULATE DISTANCE
export function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

//GET LAT / LONG
export function getLatLng(postcode){
  if (postcode === undefined){
    postcode = "";
  }
  postcode = postcode.replace(" ","");
  postcode = postcode.substring(0,6);
  postcode = postcode.toUpperCase();
  
  var formData = new FormData();
  formData.append("section","map");
  formData.append("request","postalcodes");
  formData.append("details",postcode);
  return (
    fetch(fetchLink,{credentials: "include", method:"POST",body:formData})
    .then((response) => {return response.json();})
    .then((data) => {
      if (data[0] !== undefined){
      return {
        lat: data[0]["Latitude"],
        lng: data[0]["Longitude"],
        city: data[0]["City"],
      }}
      return "fail";
    })
  );
}
