import React, { Component } from "react"

import { Xstate } from "../functions/Shared"
import { ClearClub, saveLeagueGames, getTeamID, getTeamName } from "../functions/League"
import { tquery } from ".././app/ResponsiveLayout-20190729"
import { savedText } from ".././SavedText"
import dateformat from "dateformat"
import DatePicker from "react-datepicker"
import { modalTextNoButton } from "../app/AppModal"

const DeleteMatch = () => {
  Xstate.club.detailsTemp.dbLeagueGames[0].deleted = 1
  saveLeagueGames()
  tquery("#updateReact").click()
}

const GoBack = () => {
  Xstate.modal.status = "leagueScores"
  tquery("#updateReact").click()
}

export class ModalScores extends Component {
  render() {
    //EXIT EARLY
    if (Xstate.modal.status !== "leagueScores") return <span />

    //FUNCTION VARIABLES
    let allTeamsFound = 1
    let allTeamsJSX = <span />
    let buttonJSX = <span />
    let buttonText = "Save"
    let closeButton = <span />
    let closeText = "Close"
    let dateJSX = <span />
    let deleteButton = <span />
    let duplicateTeams = 0
    let editJSX = <span />
    let editWarning = <span />
    let labelJSX = []
    let labelText = []
    let scoreJSX = []
    let selectOptions = []
    let teamJSX = []
    let teamsCountJSX = <span />
    let thisMatch = []
    let tCount = 13133

    //Xstate.club.detailsTEMP
    if (Xstate.club.detailsTemp === undefined) Xstate.club.detailsTemp = {}
    if (Xstate.club.detailsTemp.dbLeagueGames.length === 0) {
      Xstate.club.detailsTemp.dbLeagueGames = Xstate.club.details.dbLeagueGames.filter(x => {
        return 1 * x.game_id === 1 * Xstate.club.game_id_temp && 1 * x.league_id === 1 * Xstate.club.id
      })

      if (Xstate.club.detailsTemp.dbLeagueGames[0] !== undefined) {
        thisMatch = Xstate.club.detailsTemp.dbLeagueGames[0]
        if (isNaN(1 * thisMatch.teams)) thisMatch.teams = 2
        if (thisMatch.teams < 2) thisMatch.teams = 2
        if (Xstate.club.global === 1) thisMatch.edit = 1
        thisMatch.teamsInput = 1 * thisMatch.teams
      }

      //NEW LEAGUE
      if (Xstate.club.details.dbLeagueGames[1 * Xstate.club.game_id_temp - 1] === undefined) {
        Xstate.club.game_id = 1
        for (let game = 0; game < Xstate.club.details.dbLeagueGames.length; game++) {
          if (Xstate.club.details.dbLeagueGames[game].game_id >= Xstate.club.game_id) {
            Xstate.club.game_id = 1 * Xstate.club.details.dbLeagueGames[game].game_id + 1
          }
        }
        Xstate.club.detailsTemp.dbLeagueGames = [
          {
            changed: 1,
            edit: 1,
            new: 1,
            game_id: 1 * Xstate.club.game_id,
            series_id: "roundrobin",
            teams: 2,
            teamsInput: 2,
            team1_label: "0",
            team2_label: "0",
            result: "0",
            division_id: "0",
          },
        ]
        thisMatch = Xstate.club.detailsTemp.dbLeagueGames[0]
        if (Xstate.club.challengeID !== undefined) thisMatch.team2_id = Xstate.club.challengeID
        if (Xstate.club.challengeLabel !== undefined) thisMatch.team2_label = Xstate.club.challengeLabel
        if (Xstate.club.challengeResult !== undefined) thisMatch.result = Xstate.club.challengeResult
        if (Xstate.club.details.dbLeagueGeneral[0].times_type === "sessions") {
          if (Xstate.club.details.dbLeagueGames.length > 0) {
            thisMatch.time_id = (
              "0000000000000000000" +
              (1 * Xstate.club.details.dbLeagueGames[Xstate.club.details.dbLeagueGames.length - 1].time_id + 1)
            ).slice(-12)
          } else {
            thisMatch.time_id = "000000000001"
          }
        } else {
          thisMatch.time_id = dateformat(Date(), "yyyymmddHHMM")
        }

        thisMatch.location_id = 1
        if (thisMatch.locations === 0) {
          thisMatch.location_id = 0
        }
        if (thisMatch.locations === 0) {
          thisMatch.location_id = 0
        }
      }
    }
    thisMatch = Xstate.club.detailsTemp.dbLeagueGames[0]

    //NOT LADDER
    let seriesJSX = <span />
    if (Xstate.club.details.dbLeagueGeneral[0].flag_ladder * 1 === 0) {
      if (thisMatch.series_id === "roundrobin") {
        seriesJSX = (
          <span>
            <br />
            <b>Type: </b> round robin
          </span>
        )
      } else {
        seriesJSX = (
          <span>
            <br />
            <b>Series: </b> {thisMatch.series_id}
          </span>
        )
      }
    }

    //MATCH DESCRIPTION
    let descriptionJSX = <span />
    if (thisMatch.description === undefined) thisMatch.description = ""
    if (Xstate.club.details.dbLeagueGeneral[0].flag_ladder * 1 === 1) {
      if (Xstate.club.global !== 1) {
        if (thisMatch.edit === 1) {
          descriptionJSX = (
            <span>
              <br />
              <b>Description: </b>
              <input
                value={thisMatch.description}
                id="description"
                onChange={event => {
                  thisMatch.description = event.target.value
                  thisMatch.changed = 1

                  tquery("#updateReact").click()
                }}
              />
            </span>
          )
        } else {
          descriptionJSX = (
            <span>
              <br />
              <b>Description: </b> {thisMatch.description}
            </span>
          )
        }
      }
    }
    //DATE
    if (Xstate.club.details.dbLeagueGeneral[0].times_type === "times") {
      let thisTime = thisMatch.time_id
      //Default is 12:00 for global club (turned off for now so we can sort the top 10) XXX
      if (1 * Xstate.club.global === 2) thisMatch.time_id = thisMatch.time_id.slice(0, 8) + "0000"
      Xstate.club.detailsTemp.dateTemp = new Date()
      Xstate.club.detailsTemp.dateTemp.setYear(thisTime.slice(0, 4))
      Xstate.club.detailsTemp.dateTemp.setMonth(thisTime.slice(4, 6) - 1)
      Xstate.club.detailsTemp.dateTemp.setDate(thisTime.slice(6, 8))
      Xstate.club.detailsTemp.dateTemp.setHours(thisTime.slice(8, 10))
      Xstate.club.detailsTemp.dateTemp.setMinutes(thisTime.slice(10, 12))

      if (thisMatch.edit === 1) {
        let timeJSX = <span />
        if (Xstate.club.global !== 2) {
          //XXXX Always for now
          timeJSX = (
            <span>
              <b> Time: </b>
              <input
                type="time"
                id="time_id"
                className="time"
                defaultValue={dateformat(Xstate.club.detailsTemp.dateTemp, "HH:MM")}
                onChange={event => {
                  thisMatch.time_id =
                    thisMatch.time_id.slice(0, 8) + event.target.value.slice(0, 2) + event.target.value.slice(3, 5)
                  thisMatch.changed = 1
                  tquery("#updateReact").click()
                }}
              />
            </span>
          )
        }
        dateJSX = (
          <span>
            <br />
            <b> Date: </b>
            <DatePicker
              className="inline"
              selected={Xstate.club.detailsTemp.dateTemp}
              onChange={event => {
                thisMatch.time_id =
                  dateformat(event, "yyyymmdd") + thisTime.value.slice(8, 2) + thisTime.value.slice(10, 2)
                thisMatch.changed = 1
                tquery("#updateReact").click()
              }}
            />
            <br />
            {timeJSX}
          </span>
        )
      } else {
        let timeJSX = <span />
        if (Xstate.club.global !== 1) {
          timeJSX = (
            <span>
              <b>Time: </b> {dateformat(Xstate.club.detailsTemp.dateTemp, "HH:MM")}
            </span>
          )
        }
        dateJSX = (
          <span>
            <br />
            <b>Date: </b> {dateformat(Xstate.club.detailsTemp.dateTemp, "ddd mm/dd/yyyy")}
            <br />
            {timeJSX}
          </span>
        )
      }
    }

    //SESSION
    if (Xstate.club.details.dbLeagueGeneral[0].times_type === "sessions") {
      if (thisMatch.edit === 1) {
        dateJSX = (
          <span>
            <br />
            <b>Session: </b>
            <input
              type="number"
              value={1 * thisMatch.time_id}
              id="session_id"
              className="center input-number"
              onChange={event => {
                thisMatch.time_id = event.target.value
                thisMatch.changed = 1

                tquery("#updateReact").click()
              }}
            />
          </span>
        )
      } else {
        dateJSX = (
          <span>
            <br />
            <b>Session: </b>
            {1 * thisMatch.time_id}
          </span>
        )
      }
    }

    //LOCATION
    let locationJSX = <span />
    if (Xstate.club.details.dbLeagueGeneral[0].locations > 0) {
      if (thisMatch.edit === 1) {
        selectOptions = []
        for (let i = 0; i < Xstate.club.details.dbLeagueLocations.length; i++) {
          selectOptions.push(
            <option key={"ModalLocations" + i} value={i}>
              {Xstate.club.details.dbLeagueLocations[i].name}
            </option>,
          )
        }
        locationJSX = (
          <span>
            <br />
            <b> Locations: </b>
            <select
              defaultValue={1 * thisMatch.location_id - 1}
              id="location_id"
              onChange={() => {
                thisMatch.changed = 1
              }}
            >
              {selectOptions}
            </select>
          </span>
        )
      } else {
        locationJSX = (
          <span>
            <br /> <b> Locations: </b>
            {Xstate.club.details.dbLeagueLocations[thisMatch.location_id - 1].name}
          </span>
        )
      }
    }

    //TEAMS COUNT
    if (thisMatch.teams === null) {
      if (isNaN(1 * thisMatch.teams)) {
        thisMatch.teams = 2
        thisMatch.teamsInput = 2
      } else {
        thisMatch.teams = Math.max(2, 1 * thisMatch.teams)
        thisMatch.teamsInput = Math.max(2, 1 * thisMatch.teams)
      }
    }
    if (1 * Xstate.club.details.dbLeagueGeneral[0].flag_ladder === 1) {
      teamsCountJSX = (
        <span>
          <br />
          <b>Participants: </b> {thisMatch.teamsInput}
        </span>
      )
      if (thisMatch.edit === 1)
        teamsCountJSX = (
          <span>
            <br />
            <b>Participants: </b>
            <input
              type="number"
              value={thisMatch.teamsInput}
              max={Xstate.club.details.dbLeagueTeams.length}
              id="teamsCount"
              className="center input-number"
              onChange={event => {
                thisMatch.changed = 1
                thisMatch.teamsInput = event.target.value
                thisMatch.teams = Math.min(Math.max(thisMatch.teamsInput, 2), Xstate.club.details.dbLeagueTeams.length)

                tquery("#updateReact").click()
              }}
            />
          </span>
        )
    }

    //MATCH STATUS
    let statusJSX = <span />
    if (isNaN(1 * thisMatch.result)) thisMatch.result = 0
    if (1 * thisMatch.result === 0) {
      for (let team = 1; team <= thisMatch.teams; team++) {
        thisMatch["team" + team + "_score"] = 0
      }
    }
    if (1 * thisMatch.result === 0) {
      if (1 * thisMatch.edit === 1) {
        statusJSX = (
          <span>
            <b> Completed: </b>
            <button
              id="yes"
              className="btn btn-content2 btn-small btn-inactive"
              onClick={() => {
                thisMatch.changed = 1
                thisMatch.result = 1
                for (let team = 1; team <= thisMatch.teams; team++) {
                  thisMatch["team" + team + "_score"] = 0
                }

                tquery("#updateReact").click()
              }}
            >
              Yes
            </button>
            <button id="noActive" className="btn btn-content2 btn-small active">
              No
            </button>
          </span>
        )
      }
      for (let team = 1; team <= thisMatch.teams; team++) {
        scoreJSX[team] = <span />
      }
    } else {
      thisMatch.result = 1
      if (1 * thisMatch.edit) {
        statusJSX = (
          <span>
            <b> Completed: </b>
            <button id="yesActive" className="btn btn-content2 btn-small active">
              Yes
            </button>
            <button
              id="no"
              className="btn btn-content2 btn-small btn-inactive"
              onClick={() => {
                thisMatch.changed = 1
                thisMatch.result = 0
                for (let team = 1; team <= thisMatch.teams; team++) thisMatch["team" + team + "_score"] = 0
                tquery("#updateReact").click()
              }}
            >
              No
            </button>
          </span>
        )
      }
      //SCORES INPUT
      if (Xstate.club.global !== 1) {
        for (let team = 1; team <= thisMatch.teams; team++) {
          if (isNaN(1 * thisMatch["team" + team + "_score"])) {
            thisMatch["team" + team + "_score"] = 0
          }

          if (thisMatch.edit === 1) {
            let negativeJSX = (
              <button
                className="btn btn-content2 btn-small btn-inactive"
                id={"negative" + team}
                onClick={() => {
                  if (1 * thisMatch["team" + team + "_score"] >= 0) {
                    thisMatch["team" + team + "_score"] = "-" + thisMatch["team" + team + "_score"]
                  }
                  tquery("#updateReact").click()
                }}
              >
                -
              </button>
            )
            if (1 * thisMatch["team" + team + "_score"] < 0 || thisMatch["team" + team + "_score"] === "-0") {
              negativeJSX = (
                <button
                  className="btn btn-content2 btn-small active"
                  id={"negative" + team}
                  onClick={() => {
                    thisMatch["team" + team + "_score"] = Math.abs(1 * thisMatch["team" + team + "_score"])
                    tquery("#updateReact").click()
                  }}
                >
                  -
                </button>
              )
            }
            scoreJSX[team] = (
              <span>
                {negativeJSX}
                <input
                  className="input-number center"
                  id={"team" + team + "_score"}
                  value={thisMatch["team" + team + "_score"]}
                  min="-1000000"
                  max="1000000"
                  onChange={event => {
                    thisMatch["team" + team + "_score"] = event.target.value
                    thisMatch.changed = 1
                    Xstate.modal.status = "leagueScores"
                    tquery("#updateReact").click()
                  }}
                />
              </span>
            )
          } else {
            scoreJSX[team] = <b>{1 * thisMatch["team" + team + "_score"]}</b>
          }
        }
      }
      //GLOBAL CLUB ==> WINNER
      else {
        for (let team = 1; team <= thisMatch.teams; team++) {
          let teamResult = "Tie"
          if (isNaN(1 * thisMatch["team" + team + "_score"])) {
            thisMatch["team" + team + "_score"] = 0
            teamResult = ""
          }
          if (thisMatch["team" + team + "_score"] > 0) teamResult = "Win"
          if (thisMatch["team" + team + "_score"] < 0) teamResult = "Loss"
          if (thisMatch.edit === 1) {
            let buttonWin = (
              <button
                id={"Win" + team}
                className="btn btn-content2 btn-small btn-inactive"
                onClick={() => {
                  thisMatch["team" + team + "_score"] = 1
                  tquery("#updateReact").click()
                }}
              >
                Win
              </button>
            )
            let buttonLoss = (
              <button
                id={"Loss" + team}
                className="btn btn-content2 btn-small btn-inactive"
                onClick={() => {
                  thisMatch["team" + team + "_score"] = -1
                  tquery("#updateReact").click()
                }}
              >
                Loss
              </button>
            )
            let buttonTie = (
              <button
                id={"Tie" + team}
                className="btn btn-content2 btn-small btn-inactive"
                onClick={() => {
                  thisMatch["team" + team + "_score"] = 0
                  tquery("#updateReact").click()
                }}
              >
                Tie
              </button>
            )
            if (teamResult === "Win") {
              buttonWin = (
                <button id={"Win" + team} className="btn btn-content2 btn-small active">
                  Win
                </button>
              )
            }
            if (teamResult === "Loss") {
              buttonLoss = (
                <button id={"Loss" + team} className="btn btn-content2 btn-small active">
                  Loss
                </button>
              )
            }
            if (teamResult === "Tie") {
              buttonTie = (
                <button id={"Tie" + team} className="btn btn-content2 btn-small active">
                  Tie
                </button>
              )
            }
            scoreJSX[team] = (
              <span>
                {buttonWin}
                {buttonLoss}
                {buttonTie}
              </span>
            )
          }
        }
      }
    }
    //Teams Labels
    if (1 === 1 * 1) {
      labelText = ["", "Home", "Away"]
      //Global
      if (Xstate.club.global === 1) {
        labelText[1] = "My Score"
        for (let team = 2; team <= thisMatch.teams; team++) {
          labelText[team] = "Opponent " + (team - 1)
        }
      } else if (1 * Xstate.club.details.dbLeagueGeneral[0].flag_ladder === 1) {
        for (let team = 1; team <= thisMatch.teams; team++) {
          labelText[team] = "Team_" + team
        }
      }
    }
    //SELECTED OPTIONS
    if (Xstate.club.global !== 1) {
      selectOptions = []
      for (let i = 0; i < Xstate.club.details.dbLeagueTeams.length; i++) {
        selectOptions.push(
          <option key={"ModalLeagueTeams" + i} value={i + 1}>
            {Xstate.club.details.dbLeagueTeams[i].team_name}
          </option>,
        )
      }
    }
    if (thisMatch.teams > Xstate.club.details.dbLeagueTeams.length) {
      thisMatch.teams = Xstate.club.details.dbLeagueTeams.length
    }

    //GLOBAL IS ALWAYS EDIT
    if (Xstate.club.global === 1 && thisMatch.edit !== 1) {
      thisMatch.edit = 1
      tquery("#updateReact").click()
    }

    for (let team = 1; team <= thisMatch.teams; team++) {
      labelJSX[team] = <span>{labelText[team]}</span>
      if (Xstate.club.global !== 1) {
        if (isNaN(1 * thisMatch["team" + team + "_id"]) || thisMatch["team" + team + "_id"] === null) {
          thisMatch["team" + team + "_id"] = team
        }
      }
      teamJSX[team] = <span>{getTeamName(thisMatch["team" + team + "_id"])}</span>
      if (thisMatch.edit === 1) {
        //GLOBAL
        // <<<<<< >>>>>
        //WE SHOULD REVIEW THIS.  BASICALLY OUR LOGIC IS TO FORCE THEM TO ENTER THE SAME SCORE.
        //SO IF BOTH PLAYERS ENER A SCORE THEN THAT GAME IS RECORDED TWICE.
        //ITS FAIR AND A SHORT TERM SOLUTION ==> I DON'T KNOW HOW TO MAKE IT SO THE CAN "VERIFY" THE SCORE
        //AND SINCE WE NEVER SHOW THE TOTAL GP ETC THEN IT'S MUCH EASIER... BUT ITS A MAJOR MAJOR SHORTCU
        if (Xstate.club.global === 1) {
          let playerFound = <span />
          if (team === 1) {
            thisMatch["team1_id"] = Xstate.club.users.details.userid
            teamJSX[team] = <span>{getTeamName(thisMatch["team" + team + "_id"])}</span>
          } else {
            playerFound = (
              <span className="pup fas fa-check bold green-font">
                <span> Player Found! </span>
              </span>
            )
            if (thisMatch["team" + team + "_label"] === "0")
              thisMatch["team" + team + "_label"] = getTeamName(thisMatch["team" + team + "_id"])

            if (thisMatch["team" + team + "_label"] === "Team undefined") thisMatch["team" + team + "_label"] = ""
            if (thisMatch["team" + team + "_label"] === "Team_undefined") thisMatch["team" + team + "_label"] = ""

            if (getTeamID(thisMatch["team" + team + "_label"]) === 0) {
              playerFound = (
                <span className="pup fas fa-times bold green-font">
                  <span> Enter player's full username </span>
                </span>
              )
              allTeamsFound = 0
            } else thisMatch["team" + team + "_id"] = getTeamID(thisMatch["team" + team + "_label"])
            teamJSX[team] = (
              <span>
                <input
                  type="text"
                  value={thisMatch["team" + team + "_label"]}
                  onChange={event => {
                    thisMatch["team" + team + "_label"] = event.target.value
                    tquery("#updateReact").click()
                    thisMatch.changed = 1
                  }}
                />
                &nbsp;
                {playerFound}
              </span>
            )
          }
        }
        // STANDARD CLUB (i.e. NOT GLOBAL)
        else {
          if (thisMatch.series_id === "roundrobin") {
            teamJSX[team] = (
              <span>
                <select
                  className="input"
                  value={1 * thisMatch["team" + team + "_id"]}
                  id={"team" + team + "_id"}
                  onChange={event => {
                    thisMatch["team" + team + "_id"] = event.target.value
                    tquery("#updateReact").click()
                    thisMatch.changed = 1
                  }}
                >
                  {selectOptions}
                </select>
              </span>
            )
          }
        }
      }
    }

    //TEAMS OUTUT
    let tableJSX = []
    for (let team = 1; team <= thisMatch.teams; team++) {
      tableJSX.push(
        <tr key={tCount++}>
          <td className="td-input td-left"> {teamJSX[team]} </td>
          <td className="td-input"> {scoreJSX[team]} </td>
        </tr>,
      )
    }

    allTeamsJSX = (
      <div className="center">
        <table className="nowidth table-center left">
          <tbody>{tableJSX}</tbody>
        </table>
      </div>
    )

    //edit warning
    if (thisMatch.edit === 1) {
      /*OK*/
    } else if (
      Xstate.club.users.details.league_type === "league admin" ||
      Xstate.club.users.details.league_type === "edit"
    ) {
      editJSX = (
        <button
          id="editButton"
          onClick={() => {
            thisMatch.edit = 1
            tquery("#updateReact").click()
          }}
          className="btn btn-content2 btn-small"
        >
          <i className=" far fa-edit" /> Edit
        </button>
      )
    }
    if (Xstate.club.details.dbLeagueGeneral[0].flag_ladder * 1 === 0) {
      if (thisMatch.edit === 1) {
        editWarning = <span className="font-accent1"> {savedText.club.warning_edit} </span>
      }
    }

    if (thisMatch.edit === 1) {
      editJSX = <span />
    } else if (
      Xstate.club.users.details.league_type === "league admin" ||
      Xstate.club.users.details.league_type === "edit"
    ) {
      editJSX = (
        <button
          id="editButton"
          onClick={() => {
            thisMatch.edit = 1
            tquery("#updateReact").click()
          }}
          className="btn btn-content2 btn-small"
        >
          <i className=" far fa-edit" /> Edit
        </button>
      )
    }
    editWarning = <span />
    if (Xstate.club.details.dbLeagueGeneral[0].flag_ladder * 1 === 0) {
      if (thisMatch.edit === 1) {
        editWarning = <span className="font-accent1"> {savedText.club.warning_edit} </span>
      }
    }

    //Same Team
    let team_idText = "~"
    for (let team = 1; team <= thisMatch.teams; team++) {
      if (team_idText.search("~" + thisMatch["team" + team + "_id"] + "~") >= 0) {
        duplicateTeams = 1
      }
      team_idText += thisMatch["team" + team + "_id"] + "~"
    }

    //delete
    if (thisMatch.series_id === "roundrobin") {
      if (
        Xstate.club.users.details.league_type === "league admin" ||
        Xstate.club.users.details.league_type === "edit"
      ) {
        deleteButton = (
          <button
            className="btn btn-content2 btn-small"
            onClick={() => {
              thisMatch.edit = 1
              Xstate.modal.text = (
                <span>
                  <b>Warning:</b>: Deleting match details cannot be undone. Are you sure you want to delete this match,
                  maybe you can edit it instead
                </span>
              )
              Xstate.modal.fxYes = DeleteMatch
              Xstate.modal.fxNo = GoBack
              Xstate.modal.status = "confirm"
              tquery("#updateReact").click()
            }}
          >
            <i className=" far fa-trash-alt" /> Delete
          </button>
        )
      }
    }

    //BUTTONS
    closeButton = (
      <button
        className="btn btn-content2 p30"
        onClick={() => {
          ClearClub(0, 0, 0, 0)
          Xstate.modal.status = "none"
          tquery("#updateReact").click()
        }}
      >
        {closeText}
      </button>
    )

    if (thisMatch.new === 1) buttonText = "Add Match"

    if (thisMatch.edit === 1) {
      if (duplicateTeams === 1) {
        buttonJSX = (
          <span>
            <button id="saveMatchInactive" className="pup btn btn-content1 btn-inactive p30">
              {buttonText}
            </button>
            <br />
            Error: Same team is repeated. You can't play against yourself!{}
          </span>
        )
      } else if (allTeamsFound === 0) {
        buttonJSX = (
          <span>
            <button id="saveMatchInactive" className="pup btn btn-content1 btn-inactive p30">
              {buttonText}
            </button>
            <br />
            Error: Please check the team names!
          </span>
        )
      } else {
        buttonJSX = (
          <button
            id="saveMatch"
            className="btn btn-content1 p30"
            onClick={() => {
              Xstate.modal.text = "Saving"
              Xstate.modal.status = "text-nobutton"
              tquery("#updateReact").click()
              Xstate.club.detailsTemp.dbLeagueGames[0].deleted = 0
              saveLeagueGames()
              setTimeout(() => {
                ClearClub(0, 0, 0, 0)
                Xstate.modal.status = "none"
                tquery("#updateReact").click()
              }, 1000)
            }}
          >
            &nbsp; {buttonText}
          </button>
        )
      }
    }

    //new match
    if (thisMatch.new === 1) {
      if (Xstate.club.details.dbLeagueGeneral[0].flag_ladder * 1 === 0) {
        editWarning = <span className="font-accent1"> {savedText.club.add_edit} </span>
      }
    }

    return modalTextNoButton(
      <>
        <h2>
          <i className="borderFA far fa-clipboard" /> {Xstate.club.details.dbLeagueGeneral[0].league_name}
        </h2>
        <div style={{ textAlign: "center", width: "100%", display: "table" }}>
          {editJSX}
          {deleteButton}
          <div className="table-center left p100">
            {seriesJSX}
            {descriptionJSX}
            {dateJSX}
            {teamsCountJSX}
            {locationJSX}
            <br />
            <br />
            {statusJSX}
            <br />
          </div>
        </div>
        {allTeamsJSX}
        <div className="center">
          {editWarning}
          <br />
          <div>
            {closeButton}
            {buttonJSX}
          </div>
          <br />
        </div>
      </>,
    )

    //return (<span/>);
  }
}
