import { fetchLink } from "../fetchLink"
import React from "react"
import { savedText } from "../SavedText"
import { tquery } from "../app/ResponsiveLayout-20190729"
import { PostsVenuesFiltersID } from "../functions/Venue"
import { NavLink } from "react-router-dom"
import { isJSON, sportsListABC, venuesSports, theader, Xstate } from "../functions/Shared"
import { ratings } from ".././posts/stars"

export var AboutVenuesText = (link = 0) => {
  let linkButton = <span />
  if (link !== 0) {
    linkButton = (
      <NavLink
        onClick={() => {
          Xstate.posts.dbVenues = undefined
          Xstate.posts.filters.refresh = 0
          tquery("#updateReact").click()
          tquery(".contentsection").scrollTo(0, 0)
        }}
        className="btn btn-content2"
        to="/venues"
      >
        Search now
      </NavLink>
    )
  }
  let sportsButtons = []
  let tCount = 9551
  for (let i = 0; i < venuesSports.length; i++) {
    if (venuesSports[i] === undefined) continue

    let potentialPath = "venues-" + venuesSports[i][0].toLowerCase().trim().replace(" / ", "").replace(" ", "")
    sportsButtons.push(
      <NavLink
        key={tCount++}
        onClick={() => {
          tquery("#updateReact").click()
          tquery(".contentsection").scrollTo(0, 0)
        }}
        className="btn btn-content2 btn-small"
        to={"/" + potentialPath}
      >
        {venuesSports[i][0] + " " + venuesSports[i][1]}
      </NavLink>,
    )
  }

  let frequentSearches = []
  tCount = 87651
  let potentialPath = "venues-tennisClub"
  frequentSearches.push(
    <NavLink
      key={tCount++}
      onClick={() => {
        tquery("#updateReact").click()
        tquery(".contentsection").scrollTo(0, 0)
      }}
      className="btn btn-content2 btn-small"
      to={"/" + potentialPath}
    >
      Local Tennis Clubs
    </NavLink>,
  )

  //theader
  if (1 === 1 * 1) {
    theader("title", "Connactive Club")
    theader("description", "About Venues - " + savedText.home.venues_description)
    theader("og:description", "About Venues - " + savedText.home.venues_description)
    theader("keywords", "arena, court, field, pitch, diamond")
  }

  return (
    <span>
      <div className="col-md-12">
        <h2> What is a Venue </h2>
        Ice hockey is played at the arena. Soccer on the pitch. Baseball on the diamond. Generally, we can group all of
        these as venues. And we are here to help you find the best venue for you to play!
        {savedText.oneLine}
        <h2> Connactive Club Venues </h2>
        Tired of trying to find Squash courts on blog websites? Do you want to know if a tennis court has lights? We've
        been there. {savedText.halfLine}
        Our database is updated by users so you can work together to find a court/pitch/field near you. We have filters
        to help you find the best spot for your needs, including cost and how busy it is.
        {savedText.halfLine}
        <div className="div-input">
          <h3> One stop shop </h3>
          We all use some great websites to compile results from across the web. Booking.com for hotels, Skyscanner for
          flights, Yelp to find a restaurant. These let us search in one place rather than going 20 different sites to
          compare our options.
          {savedText.halfLine}
          What about finding somewhere to play sports? Not so easy. You can try your luck on google maps or go to the
          city's website. Maybe you'll find it, maybe not.
          {savedText.halfLine}
          This venues database will give a better way to find the right place for you to play. We will let you filter
          for the things that you care about. and if we are missing information, you can add it to the database to help
          other users.
          {savedText.oneLine}
          <h3> Wiki approach </h3>
          You get the idea, now we need your help! Booking.com and Skyscanner are able to consolidate the details
          because they make money when you purchase off of their website. But, a free tennis court isn't going to pay
          for you to play there, so we need help consolidating the results.
          {savedText.halfLine}
          Please help keep the our venues tool up to date! When you look at a venue, you will see these symbols
          <div className="div-input">
            &nbsp; <i className="fas fa-check opacity50" /> You are seeing the value from our community. If you agree,
            please click to agree.
            <br />
            &nbsp; <i className="fas fa-check font-accent1" /> Thanks for confirming that this is correct. If you want
            to adjust the value you can.
            <br />
            &nbsp; <i className="fas fa-question-circle font-accent1" /> You have made a correction. Thanks!
            <br />
            &nbsp; <i className="fas fa-pencil-alt opacity50" /> Something look wrong - please let us know.
            <br />
            &nbsp; <b>+</b> If a venue is missing, you can add it to. That helps a lot!&nbsp;
            <NavLink
              onClick={() => {
                tquery("#updateReact").click()
                tquery(".contentsection").scrollTo(0, 0)
              }}
              to="venues-add"
              className="btn-content2 btn-small"
            >
              + Add a venue
            </NavLink>
            {}
          </div>
          {savedText.halfLine} If we all help each other out we can spend more time on the court and less time googling.
          {savedText.halfLine}
        </div>
        <h2> Search by a Specific Sport </h2>
        We have a big database so you will have the best results if you search by your desired sport.
        <br />
        {sportsButtons}
        {savedText.halfLine}
        <h2> Frequent Searches</h2>
        Sometimes you are looking for something more specific. Here are our most frequent searches
        <br />
        {frequentSearches}
        {savedText.halfLine}
        <h2> Online Gaming </h2>
        In 2020; we had to find other ways to connect. When work video calls became the norm; we wanted to continue to
        Play, Compete, and Have Fun. So we found some great resources to use and kept our Club going. Here are some of
        our favourites. Did we mention the best part; these are all free.{}
        <NavLink
          key={tCount++}
          onClick={() => {
            tquery("#updateReact").click()
            tquery(".contentsection").scrollTo(0, 0)
          }}
          className="btn btn-content2 btn-small"
          to="/venues-online"
        >
          Check it out
        </NavLink>
        {savedText.halfLine}
        <div className="center">
          <h4> Give it a go </h4>
          {linkButton}
        </div>
      </div>
    </span>
  )
}
export var AboutVenuesSport = sport => {
  let index = -1
  for (let i = 0; i < venuesSports.length; i++) {
    if (venuesSports[i] !== undefined) {
      if (venuesSports[i][0].toLowerCase() === sport.toLowerCase()) index = i
    }
  }
  if (index === -1) return ""

  let title = venuesSports[index][0] + savedText.nbsp + venuesSports[index][1]
  let description =
    "Looking for a " +
    title +
    "? Use our database and you can find the best one for you.  You can sort and filter by " +
    venuesSports[index][2] +
    " and " +
    venuesSports[index][3] +
    " as well as by Location and (of course) by User Reviews."

  let searchButton = (
    <NavLink
      onClick={() => {
        Xstate.sport = venuesSports[index][0]
        Xstate.posts.dbVenues = undefined
        tquery("#updateReact").click()
        tquery(".contentsection").scrollTo(0, 0)
      }}
      className="btn btn-content2"
      to="/venues"
    >
      Search now
    </NavLink>
  )

  //theader
  if (1 === 1 * 1) {
    theader("title", "Connactive Club")
    theader("description", "Find a " + title + " - " + description)
    theader("og:description", "Find a " + title + " - " + description)
    theader(
      "keywords",
      title + ", " + venuesSports[index][2] + ", " + venuesSports[index][3] + ", " + "Nearby" + ", " + "best",
    )
  }

  return (
    <>
      <div className="col-md-12">
        <h2> {title} </h2>
        {description}
        {savedText.oneLine}
        <div className="center"> {searchButton}</div>
      </div>
    </>
  )
}
export var MessageBoardHeader = (location = "") => {
  let thisClass = " sportheadertext"
  let about = (
    <div>
      <NavLink
        onClick={() => {
          tquery("#updateReact").click()
          tquery(".contentsection").scrollTo(0, 0)
        }}
        className="btn btn-menu1"
        to="/messageboard-about"
      >
        About Message Board
      </NavLink>
    </div>
  )
  let titlebar = (
    <h2>
      <i className="borderFA fa fa-comment-alt"></i> Message Board
    </h2>
  )
  if (location !== "") {
    thisClass = ""
    about = ""
    titlebar = (
      <h3 className="p100 center">
        <i className="fa fa-comment-alt"></i> Message Board
      </h3>
    )
  }

  return (
    <div className={thisClass}>
      {titlebar}
      {about}
    </div>
  )
}
export var OnlineVenues = () => {
  //theader
  if (1 === 1 * 1) {
    theader("title", "Connactive Club")
    theader("description", "Online Venues - Some great sites for playing online")
    theader("og:description", "Online Venues - Some great sites for playing online")
    theader("keywords", "online gaming, cards, board game, video calls")
  }
  return (
    <>
      <div className="col-md-12">
        <h2> Playing Online </h2>
        In 2020; we had to find other ways to connect. When work video calls became the norm; we wanted to continue to
        Play, Compete, and Have Fun.
        {savedText.halfLine}
        So we found some great resources to use and kept our Club going. Here are some of our favourites. Did we mention
        the best part; these are all free.
        {savedText.oneLine}
        <h2> Playing Cards </h2>
        Classic card games are the adult version of dinky cars. My family Christmas always includes a couple of rounds
        of Euchre. You can continue to play using these sites.
        {savedText.halfLine}
        <div className="div-input">
          <a href="https://www.cardzmania.com/games/" target="_blank" rel="noopener noreferrer">
            <b> Cardzmania </b>
            {}
          </a>
          {}
          The ever growing site has all of the games you grew up playing; and can let you keep your poker or bridge
          night going. Example games: Bridge, Euchre, Hearts, President, Texas Holdem
          {savedText.halfLine}
          <a href="http://www.playok.com" target="_blank" rel="noopener noreferrer">
            <b> PlayOK </b>
            {}
          </a>
          {}A simple but fun site with more than just card games; if you are looking for a low-tech option give it a go!
          Example games: 3-5-8, Barbu-king, Canasta, Spades
        </div>
        {savedText.oneLine}
        <h2> Classic Board Games </h2>
        Strategy games never go out of style. That means that you can always have fun playing Checkers or Backgammon.
        {savedText.halfLine}
        <div className="div-input">
          <a href="http://www.playok.com" target="_blank" rel="noopener noreferrer">
            <b> PlayOK </b>
            {}
          </a>
          {}
          An international set of games let's you play the ones you know and love or try new ones. Example games:
          Checkers, Chess, Gomoku (Connect 5), Xiangqi (Chinese Chess)
          {savedText.halfLine}
          <a href="https://papergames.io/" target="_blank" rel="noopener noreferrer">
            <b> PaperGames </b>
            {}
          </a>
          {}A small set of fun games; can you sink my battleship? Example Games: Battleship, Tic Tac Toe, Connect 4,
          Gomoku (Connect 5)
        </div>
        {savedText.oneLine}
        <h2> Board Games </h2>
        Most games have an app now; so you can find them in the app store; but here are some websites that will let you
        play right away.
        {savedText.halfLine}
        <div className="div-input">
          <a href="https://colonist.io/" target="_blank" rel="noopener noreferrer">
            <b> Catan </b>
            {}
          </a>
          {}A games night favourite; you can play for free against your friends and if you want to up your game you can
          purchase expansion packs too.
          {savedText.halfLine}
          <a
            href="https://play.google.com/store/apps/details?id=com.hasbro.riskbigscreen&hl=en_CA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b> Risk </b>
            {}
          </a>
          {}
          This is one of the apps that we talked about above, but deserves a special shoutout. Some people prefer it to
          the actual board game because when you get a 200 army vs a 100 army battles you can be rolling dice forever;
          but isn't that half the fun?
          {savedText.halfLine}
          <a href="https://en.boardgamearena.com/" target="_blank" rel="noopener noreferrer">
            <b> Board Game Arena </b>
            {}
          </a>
          {}
          This site is massive, and while each game is different; I can personally speak to the quality of Through the
          Ages. If you haven't tried that one; it starts with some patience but is well worth it!
        </div>
        {savedText.oneLine}
        <h2> TV Game Shows </h2>
        Looking for a low tech or no tech idea. We've had some fun with the following.
        {savedText.halfLine}
        <div className="div-input">
          <a href="https://endlessgames.com/download-rules/" target="_blank" rel="noopener noreferrer">
            <b> Endless Games </b>
            {}
          </a>
          {}
          The site contains PDF documents with game rules; but the lucky find is that for Family Feud and The Newlywed
          Game there are questions included too. So no board required; just a host and some creativity.
          {savedText.halfLine}
          <b> Trivia Night </b> Find someone to create some questions (or Jeopardy style format) and focus on having
          fun. Some question ideas:
          <div className="div-input">
            1) Songs: Use the spotify rankings to see who knows what songs are most popular for an artist
            <br />
            2) Movies: Use IMDB to see who knows what actors are in what Movies
            <br />
            3) City History: I bet your city has a lot to offer, and see who knows it best.
          </div>
        </div>
        {savedText.oneLine}
        <h2> Video Chat </h2>
        The best part about a games night; make sure you include the video chat. Or think about it this way; if your
        zoom calls are becoming a bit too much "small talk" add a game!
        {savedText.oneLine}
        <h2> Connactive Club </h2>
        How does our site help you? Create a club and track the scores and standings or even build a schedule. Adding a
        little competition will make your game nights part of the weekly routine.
        {savedText.halfLine}
        <div className="center">
          <button
            className="btn btn-content1"
            onClick={() => {
              tquery("#ClubButton").click()
            }}
          >
            {}
            Clubs{}
          </button>
        </div>
      </div>
    </>
  )
}
export var PlayerSearchUpdate = () => {
  if (Xstate.dbStatusValue === 0) return 1
  if (Xstate.posts.dbPlayerSearch === 0) {
    Xstate.posts.dbPlayerSearch = 1
    if (Xstate.dbStatusValue === "connected") {
      Xstate.posts.dbPlayerSearchType = "db"
      var formData = new FormData()
      formData.append("section", "posts")
      formData.append("request", "retrieve-playersearch")
      fetch(fetchLink, {
        credentials: "include",
        method: "POST",
        body: formData,
      })
        .then(function (response) {
          return response.text()
        })
        .then(function (data) {
          if (isJSON(data.split("\\n").join("<br/>"))) {
            Xstate.posts.dbPlayerSearch = JSON.parse(data.split("\\n").join("<br/>"))
            tquery("#updateReact").click()
          }
        })
    }
  }
}
export var PostsUpdate = () => {
  if (Xstate.dbStatusValue === 0) {
    //WAIT FOR CONNECTION
  } else {
    if (Xstate.posts.dbPosts === 0) {
      Xstate.posts.dbPosts = 1
      Xstate.posts.dbPostsType = "db"
      var formData = new FormData()
      formData.append("section", "posts")
      formData.append("request", "retrieve-posts")
      fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
        .then(function (response) {
          return response.text()
        })
        .then(function (data) {
          if (isJSON(data.split("\\n").join("<br/>"))) {
            Xstate.posts.dbPosts = JSON.parse(data.split("\\n").join("<br/>"))
            tquery("#updateReact").click()
          }
          return 0
        })
      return 1
    }
  }
  return 0
}
export var PostsVenueByID = id => {
  Xstate.posts.currentVenue = 1
  var formData = new FormData()
  formData.append("section", "posts")
  formData.append("request", "retrieve-individual-venue")
  formData.append("post_id", id)

  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:posts")
    console.log("request:retrieve-individual-venue")
    console.log("post_id:" + id)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      if (isJSON(data)) {
        ClearPostsModal()
        data = JSON.parse(data)
        if (data.consensus.dbVenuesGeneral.length === 0) {
          Xstate.modal.status = "none"
          tquery("#updateReact").click()
          return 1
        }
        Xstate.posts.venue_id = id
        //Xstate.posts.modalView = "general"

        if (Xstate.posts.directVenuesLink === undefined) {
          Xstate.posts.directVenuesLink = 1
        }
        if (Xstate.posts.directVenuesLink === 1) {
          if (Xstate.sport === "All Sports") {
            Xstate.sport = data.consensus.dbVenuesSportsValues[0].sport_name
          }

          if (Xstate.location[0].lat === undefined) {
            Xstate.location[0].lat = data.consensus.dbVenuesGeneral[0].venue_latitude
            Xstate.posts.filters.lat = Xstate.location[0].lat
          }
          if (Xstate.location[0].lng === undefined) {
            Xstate.location[0].lng = data.consensus.dbVenuesGeneral[0].venue_longitude
            Xstate.posts.filters.lng = Xstate.location[0].lng
          }
          Xstate.location[0].label = data.consensus.dbVenuesGeneral[0].name
          Xstate.posts.filters.sortby = "Distance"
          Xstate.posts.dbVenues = undefined
          Xstate.posts.locationOn = 1
        }
        Xstate.posts.currentVenue = {}
        Xstate.posts.currentVenue.dbVenuesGeneral = data.consensus.dbVenuesGeneral[0]
        Xstate.posts.currentVenue.dbVenuesSportsValues = data.consensus.dbVenuesSportsValues
        Xstate.posts.myCurrentVenue = {}
        Xstate.posts.myCurrentVenue.dbVenuesGeneral = data.personal.dbVenuesGeneral[0]
        if (Xstate.posts.myCurrentVenue.dbVenuesGeneral === undefined) {
          Xstate.posts.myCurrentVenue.dbVenuesGeneral = {}
        }
        Xstate.posts.myCurrentVenue.dbVenuesSportsValues = data.personal.dbVenuesSportsValues

        for (let i = 0; i < sportsListABC.length; i++) {
          Xstate.posts.sports[sportsListABC[i]] = undefined
        }
        if (Xstate.posts.currentVenue.dbVenuesSportsValues !== undefined) {
          Object.keys(Xstate.posts.currentVenue.dbVenuesSportsValues).forEach(key => {
            let thisSport = Xstate.posts.currentVenue.dbVenuesSportsValues[key]
            Xstate.posts.sports[thisSport.sport_name] = 1 * thisSport.sport_exists
            ratings[thisSport.sport_name] = 1 * thisSport.sport_rating
          })
        }

        tquery("#updateReact").click()
      }
      return 0
    })

  Xstate.posts.currentVenue = 1
  formData = new FormData()
  formData.append("section", "posts")
  formData.append("request", "similar-venues")
  formData.append("post_id", id)
  Xstate.posts.similarVenues = 0

  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:posts")
    console.log("request:similar-venues")
    console.log("post_id:" + id)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      if (isJSON(data)) {
        data = JSON.parse(data)
        Xstate.posts.similarVenues = data
        tquery("#updateReact").click()
      }
      return 0
    })
}
export var ClearPostsModal = () => {
  PostsVenuesFiltersID(Xstate.sport)
  Xstate.posts.msg = ""
  Xstate.posts.modalView = undefined
  Xstate.posts.myCurrentVenue = undefined
  Xstate.posts.currentVenue = undefined
}
