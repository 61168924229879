import { fetchLink } from "../fetchLink"
import React from "react"
import { tquery } from "../app/ResponsiveLayout-20190729"
import { updateAllEvents, updateAllLeagues } from "./League"
import { VenueAttributes } from "./Venue"
import { SaveUsersLocation, updateAllDistances } from "./Account"
import { savedText } from "../SavedText"
import dateformat from "dateformat"
import { StarsComponent } from "../posts/stars"

console.log(process.env)

export var O = i => {
  return typeof i === "object" ? i : document.getElementById(i)
}
export var S = i => {
  return O(i).style
}
export var C = i => {
  return document.getElementsByClassName(i)
}
export var Xstate = {
  club: {
    page: "home",
    id: 0,
    newID: 0,
    details: 0,
    recurring: 0,
    newView: "start",
    modalChange: 0,
    startError: 0,
    selectedOption: [],
    teamDisplay: {},
    groupDisplay: {},
    build: 0,
    existingMax: 19,
  },
  css: {
    change: 0,
    mode: "lightp",
  },
  map: {},
  message: {},
  modaldetails: {},

  JSX: {},
  landingPage: 1,
  location: [
    {
      fieldResult: "",
      label: "",
    },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
  ],
  locationID: 0,
  login: {
    db: 0,
    text: "Login",
    inputEmail: "",
    inputPassword: "",
    resetpswd: {},
    msg: "",
    reactivate: <span></span>,
    status: false,
    type: "",
    resetusername: "",
    sportandlocationlist: "",
    dashboardPlayerSearch: "",
    default_sport: "",
    default_sport_temp: "",
    newusername: "",
    register: {
      inputUsername: "",
      inputEmail: "",
      inputPassword: "",
      inputPassword2: "",
      msg0: "",
      msg1: "",
      msg2: "",
      msg3: "",
      msg4: "",
      msg5: "",
      msg6: "",
      msg7: "",
      msg8: "",
      msg9: "",
    },
  },
  modal: {
    status: "none",
  },
  posts: {
    last_update: 0,
    type: "venues",

    dbPosts: 0,
    dbPostsType: "",
    dbPlayerSearch: 0,
    dbPlayerSearchType: "",

    dbVenues: {
      totalVenues: 12000,
    },
    dbVenuesType: "",
    filters: {},
    sports: {},
    venue_id: 0,
    venuesPrint: [],

    attempts: 0,
    maxAttempts: 10,
    sortby: "Name",
    count: 0,
    printCount: 0,
    printMin: 0,
    printMax: 19,

    KM: "100",

    like: 0,
    dislike: 0,
    postYear: "",
    postMonth: "",
    postDay: "",
    replyEmail: "0",
    msg: "",

    filters_id: {},

    location: {
      on: 0,
      type: "",
      selected: "",
      postalCodeUpdate: "",
      field: "",
      fieldResult: "",
      label: "search",
      address: "",
      lat: "none",
      lng: "none",
      lat1: "none",
      lng1: "none",
      postal_code1: "",
      city1: "",
    },

    playerSearch: {},
  },
  sport: "default",
  subLandingPage: 0,
  //VIEW: Things that don't change when we do data refreshes
  view: {},
}
Xstate.testing = "on"
if (process.env.NODE_ENV === "development") {
  /* do nothing */
} else if (process.env.NODE_ENV === "production") {
  Xstate.testing = "off"
}
export const addDays = (date, days) => {
  let result = date
  result.setDate(result.getDate() + days)
  return result
}
export const CopyToClipboard = str => {
  var dummy = document.createElement("input"),
    text = window.location.href
  if (1 === 0) console.log(text) //Error Fixer
  document.body.appendChild(dummy)
  dummy.value = str
  dummy.select()
  dummy.setSelectionRange(0, 99999)
  document.execCommand("copy")
  document.body.removeChild(dummy)
  let temp = Xstate.modal.status
  Xstate.modal.status = "text-nobutton"
  Xstate.modal.text = "Link Copied to Clipboard"
  tquery("#updateReact").click()
  setTimeout(() => {
    Xstate.modal.status = temp
    tquery("#updateReact").click()
  }, 2000)
}
export const DatabaseStart = () => {
  updateAllLeagues()
  updateAllEvents()
  VenueAttributes()
}
export const DaysBetween = (date1, date2) => {
  let xdate1 = new Date()
  xdate1.setYear(1 * date1.substr(0, 4))
  xdate1.setMonth(1 * date1.substr(4, 2) - 1)
  xdate1.setDate(1 * date1.substr(6, 2))

  let xdate2 = new Date()
  xdate2.setYear(1 * date2.substr(0, 4))
  xdate2.setMonth(1 * date2.substr(4, 2) - 1)
  xdate2.setDate(1 * date2.substr(6, 2))

  return Math.round((xdate1 - xdate2) / (1000 * 60 * 60 * 24), 0)
}
export const median = values => {
  if (values.length === 0) return 0

  values.sort(function (a, b) {
    return a - b
  })

  var half = Math.floor(values.length / 2)

  if (values.length % 2) return values[half]

  return (values[half - 1] + values[half]) / 2.0
}
export const starsArray = (rating, tCount) => {
  let stars = []
  rating = Math.min(rating, 5)
  rating = Math.max(rating, 0)
  for (let i = 1; i <= rating; i++) {
    stars.push(
      <span key={tCount++}>
        <i className="fas fa-star"></i>
      </span>,
    )
  }
  for (let j = 1 * rating + 1; j <= 5; j++) {
    stars.push(
      <span key={tCount++}>
        <i style={{ opacity: "0.2" }} className="fas fa-star"></i>
      </span>,
    )
  }
  return stars
}
export const tDateFormat = (date1, type) => {
  if (date1 === undefined) return ""
  //Special Case Time
  if (typeof date1 === "string" && date1.length === 4 && type === "time") {
    return date1.substr(0, 2) + ":" + date1.substr(2, 2)
  }
  let d = new Date()
  if (typeof date1 === typeof d) {
    d = date1
  } else {
    d.setFullYear(date1.substr(0, 4))
    d.setMonth(1 * date1.substr(4, 2) - 1)
    d.setDate(1 * date1.substr(6, 2))
    if (date1.length > 8) {
      d.setHours(1 * date1.substr(0, 2))
      d.setMinutes(1 * date1.substr(2, 2))
    } else {
      d.setHours(0)
      d.setMinutes(0)
    }
  }
  if (type === "value") return d
  if (type === "full") return dateformat(d, "ddd yyyy-mmm-dd")
  if (type === "time") return dateformat(d, "HH:MM")
  if (type === "num-time") return dateformat(d, "HHMM")
  if (type === "num-date") return dateformat(d, "yyyymmdd")
  if (type === "num-full") return dateformat(d, "yyyymmddHHMM")
  if (type === "num-full(secs)") return dateformat(d, "yyyymmddHHMMss")

  return dateformat(d, "ddd yyyy-mmm-dd")
}
export var isJSON = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
export const JSXnotLoggedIn = message => {
  return (
    <span>
      <br />
      <b> Not Logged In </b>
      {savedText.oneLine}
      {message}
      {savedText.oneLine}
      <button
        className="btn btn-content1"
        onClick={() => {
          Xstate.modal.status = "login"
          tquery("#updateReact").click()
        }}
      >
        Login
      </button>
      {savedText.oneLine}
    </span>
  )
}
export const JSXnotInLeague = message => {
  return (
    <span>
      <br />
      <b> Not In Club </b>
      {savedText.oneLine}
      {message}
      {savedText.oneLine}
      <button
        className="btn btn-content1"
        onClick={() => {
          Xstate.club.navigationView = "users"
          Xstate.club.userView = "join"
          tquery("#updateReact").click()
        }}
      >
        Join
      </button>
      {savedText.oneLine}
    </span>
  )
}
export var sportSelect = () => {
  if (Xstate.sport === "") Xstate.sport = "All Sports"
  if (Xstate.sport === "none") Xstate.sport = "All Sports"
  if (Xstate.sport === "none") Xstate.sport1 = undefined
  if (Xstate.sport !== "All Sports") Xstate.sport1 = "All Sports"
  let buttonJSX = (
    <button
      id="sportSelect"
      className="btn btn-content2 btn-small active"
      onClick={() => {
        Xstate.sportVariable = "Xstate"
        Xstate.modal.status = "SportandLocationList"
        tquery("#updateReact").click()
      }}
    >
      {Xstate.sport}
    </button>
  )

  let button2JSX = <span />
  if (Xstate.sport1 !== undefined) {
    button2JSX = (
      <span>
        <br />
        <button
          className="btn-content2 btn-small btn-inactive"
          id="sportSelectAll"
          onClick={() => {
            let tempVar = "" + Xstate.sport
            Xstate.sport = "" + Xstate.sport1
            Xstate.sport1 = tempVar
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          {Xstate.sport1}
        </button>
      </span>
    )
  }
  return (
    <tr>
      <td className="td-middle">
        <b> Sport: </b>
      </td>
      <td>
        {buttonJSX}
        {button2JSX}
      </td>
    </tr>
  )
}
export var geoFindMe = () => {
  function success(position) {
    if (Xstate.modal.location_use === "PlayerSearch") {
      Xstate.posts.playerSearch.longitude = position.coords.longitude
      Xstate.posts.playerSearch.latitude = position.coords.latitude
      Xstate.posts.playerSearch.city = "Custom Location"
      Xstate.posts.playerSearch.postalcode = ""
      Xstate.posts.playerSearch.label = "Current Location"
      Xstate.modal.status = "playersearch"
    } else {
      Xstate.location[Xstate.locationID].lat = position.coords.latitude
      Xstate.location[Xstate.locationID].lng = position.coords.longitude
      console.log(Xstate.location)
      Xstate.location[Xstate.locationID].label = "Current Location"
      Xstate.modal.status = "none"
      Xstate.posts.filters.refresh = 1
      Xstate.posts.filters.sortby = "Distance"
    }
    if (Xstate.modal.location_use === "Profile") SaveUsersLocation()
    Xstate.modal.location_use = undefined
    setTimeout(() => {
      tquery("#refreshButton").click()
    }, 1000)
    tquery("#updateReact").click()
  }

  function error() {
    Xstate.location[0].fieldError = "Unable to retrieve your location"
    Xstate.posts.filters.refresh = 1
    tquery("#updateReact").click()
  }

  if (!navigator.geolocation) {
    Xstate.location[0].fieldError = "Geolocation is not supported by your browser"
    Xstate.posts.filters.refresh = 1
    tquery("#updateReact").click()
  } else {
    Xstate.location[0].fieldError = "Locating…"
    Xstate.posts.filters.refresh = 1
    tquery("#updateReact").click()
    navigator.geolocation.getCurrentPosition(success, error, {
      maximumAge: 0,
      timeout: 10000,
      enableHighAccuracy: true,
    })
  }
}
export const headerSportsList = ["Team Sports", "Individual Sports", "Outdoor Sports", "eSports/Board Games"]
export const sportsList = [
  //Team Sports
  [
    "Ball Hockey",
    "Basketball",
    "Baseball / Softball",
    /*"Croquet",*/
    /*"Football",*/
    "Ice Hockey",
    /*"Lacrosse",
    "Lawn Bowling",*/
    "Soccer",
    /*"Shuffle Board",
    "Ultimate Frisbee",*/
    "Volleyball",
  ],
  //Individual Sports
  [/*"Badminton",  "Bowling",*/ "Disc Golf", "Golf", "Pickleball", "Squash", "Table Tennis", "Tennis"],
  //Outdoor Sports
  [
    /*"Biking",
    /*"Camping",*/
    /*"Hiking",
    /*"Running",*/
    /*"Skateboarding",*/
    /*"Skating",*/
    /* "Skiing / Snowboarding",*/
    /*"Swimming",*/
  ],
  //esports
  [
    /*"Chess"*/
    /*"Connect 5"*/
    /*"Go"*/
  ],
]
export const sportsListABC = [
  /*"Badminton",*/
  "Ball Hockey",
  "Baseball / Softball",
  "Basketball",
  /*"Biking",*/
  /*"Cricket",*/
  /*"Bowling",
 "Camping",
 "Chess",
 "Connect 5",
 "Croquet",*/
  "Disc Golf",
  /*"Football",
 "Go",*/
  "Golf",
  /*"Hiking",*/
  "Ice Hockey",
  /*"Lacrosse",*/
  /*"Lawn Bowling",*/
  "Pickleball",
  /*"Running",*/
  /* "Shuffle Board",
  "Skateboarding",*/
  "Skating",
  /* "Skiing / Snowboarding",*/
  "Soccer",
  "Squash",
  /*"Swimming",*/
  "Table Tennis",
  "Tennis",
  /* "Ultimate Frisbee",*/
  "Volleyball",
]
export var smartPhoneSportsList = () => {
  let result = [{ value: "All Sports", label: "All Sports" }]
  for (let i = 0; i < sportsListABC.length; i++) {
    result.push({ value: sportsListABC[i], label: sportsListABC[i] })
  }
  return result
}
export var SportsListOptions = ListType => {
  let result = []
  let tCount = 97423
  let thisList = smartPhoneSportsList()
  if (ListType === "club") {
    result.push(
      <option key={tCount++} value="Other">
        Other
      </option>,
    )
  } else if (ListType === "modal") {
    result.push(
      <option key={tCount++} value="All Sports">
        All Sports
      </option>,
    )
  }
  for (let i = 1; i < thisList.length; i++) {
    result.push(
      <option key={tCount++} value={thisList[i].value}>
        {thisList[i].label}
      </option>,
    )
  }

  return result
}
export const venuesSports = [
  //Sport, Venue Type, Main Filter 1, Main Filter 2
  /*"Badminton",*/
  ["Ball Hockey", "Court", "Outdoor courts", "Free"],
  ["Baseball / Softball", "Diamond", "Lights", "Free"],
  ["Basketball", "Court", "Full or Half court", "Lights"],
  ["Biking", "Area", "Free", "Price"],
  ["Cricket", "Field", "Free", "Price"],
  /*"Bowling",
 "Camping",
 "Chess",
 "Connect 5",
 "Croquet",*/
  ["Disc Golf", "Course", "Free", "Number of Holes"],
  /*"Football",
 "Go",*/
  ["Golf", "Course", "Price", "Number of Holes"],
  /*"Hiking",*/
  ["Ice Hockey", "Arena", "Indoor or Outdoor", "Price"],
  /*"Lacrosse",*/
  ["Lawn Bowling", "Court", "Price", "Public or Private"],
  /*"Running",*/
  ["Pickleball", "Court", "Price", "Public or Private"],
  ["Shuffle Board", "Court", "Price", "Public or Private"],
  ["Skateboarding", "Park", "Lights", "Free"],
  ["Skating", "Rink", "Indoor or Outdoor", "Free"],
  ["Skiing / Snowboarding", "Hill", "Lights", "Price"],
  ["Soccer", "Pitch", "Lights", "Free"],
  ["Squash", "Court", "Public or Private", "Price"],
  /*"Swimming",*/
  ["Table Tennis", "Court", "Table Top Type", "Free"],
  ["Tennis", "Court", "Lights", "Free"],
  ["Ultimate Frisbee", "Field", "Lights", "Free"][("Volleyball", "Court", "Court Type", "Free")],
]
export const playerSports = [
  /*["Badminton", 1, "individual"],*/
  ["Ball Hockey", 2, "team"],
  ["Baseball / Softball", 3, "team"],
  ["Basketball", 4, "team"],
  /*["Biking", 5, "individual"],*/
  /*["Cricket", 21, "team"],*/
  /*"Bowling",
 "Camping",
 "Croquet",*/
  ["Disc Golf", 6, "individual"],
  /*["Football", 7, "team"],*/
  ["Golf", 8, "individual"],
  /*"Hiking",*/
  ["Ice Hockey", 9, "team"],
  /*["Lacrosse", 10, "team"],*/
  /*["Lawn Bowling", 11, "individual"],*/
  ["Pickleball", 20, "individual"],
  /*["Shuffle Board", 12, "individual"],*/
  /*"Skateboarding",
 "Skating",
 "Skiing / Snowboarding",*/
  ["Soccer", 13, "team"],
  ["Squash", 14, "individual"],
  /* ["Swimming", 15, "individual"],*/
  ["Table Tennis", 16, "individual"],
  ["Tennis", 17, "individual"],
  /*["Ultimate Frisbee", 18, "team"],*/
  ["Volleyball", 19, "team"],
]
export const playerSportsOptions = () => {
  let result = []
  for (let i = 0; i < playerSports.length; i++) {
    result.push(<option value={playerSports[i][0]}>{playerSports[i][0]} </option>)
  }
  return result
}
export const months = [
  0,
  { text: "Jan", full: "January" },
  { text: "Feb", full: "February" },
  { text: "Mar", full: "March" },
  { text: "Apr", full: "April" },
  { text: "May", full: "May" },
  { text: "Jun", full: "June" },
  { text: "Jul", full: "July" },
  { text: "Aug", full: "August" },
  { text: "Sep", full: "September" },
  { text: "Oct", full: "October" },
  { text: "Nov", full: "November" },
  { text: "Dec", full: "December" },
]
export const weekday = [
  { full: "Sunday", text: "Sun" },
  { full: "Monday", text: "Mon" },
  { full: "Tuesday", text: "Tue" },
  { full: "Wednesday", text: "Wed" },
  { full: "Thursday", text: "Thu" },
  { full: "Friday", text: "Fri" },
  { full: "Saturday", text: "Sat" },
]
export const dayofweek = [
  { text: "Weekend", weekdays: [0, 6] },
  { text: "Weekday", weekdays: [1, 2, 3, 4, 5] },
]
export const timeofday = [
  { text: "Morning", timetext: "Before 10:00am", start: "0600", end: "1000" },
  { text: "Noon", timetext: "10:00am to 2:00pm", start: "1000", end: "1400" },
  { text: "Afternoon", timetext: "2:00pm to 6:00pm", start: "1400", end: "1800" },
  { text: "Night", timetext: "After 6:00pm", start: "1800", end: "2400" },
]
export const tracker = (button = "", input = "", value = "", errormsg = "") => {
  //REMOVE DUPLICATES
  if (1 === 1 * 1) {
    if (Xstate.tracker === undefined) Xstate.tracker = []
    if (Xstate.tracker.time === undefined) Xstate.tracker.time = 0
    if (Xstate.tracker.input === undefined) Xstate.tracker.input = 0
    if (Xstate.tracker.button === undefined) Xstate.tracker.button = 0
    if (Xstate.tracker.value === undefined) Xstate.tracker.value = 0
    if (Xstate.tracker.errormsg === undefined) Xstate.tracker.errormsg = 0
    if (1 * new Date() - Xstate.tracker.time < 1000) {
      if (Xstate.tracker.input === input) {
        if (Xstate.tracker.button === button) {
          if (Xstate.tracker.value === value) {
            if (Xstate.tracker.errormsg === errormsg) {
              return 0
            }
          }
        }
      }
    }
    //Save the check next time
    Xstate.tracker.time = 1 * new Date()
    Xstate.tracker.input = input
    Xstate.tracker.button = button
    Xstate.tracker.value = value
    Xstate.tracker.errormsg = errormsg
  }
  //Determine Browser
  var browser = "Unknown"
  if (1 === 1 * 1) {
    //var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0
    var isFirefox = typeof InstallTrigger !== "undefined"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]"
      })(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification))
    var isIE = /*@cc_on!@*/ false || !!document.documentMode
    var isEdge = !isIE && !!window.StyleMedia
    var isChrome = window.chrome
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") !== -1
    var isBlink = isChrome && !!window.CSS

    if (isFirefox) browser = "Firefox"
    else if (isSafari) browser = "Safari"
    //else if (isOpera) browser = "Opera"
    else if (isIE) browser = "IE"
    else if (isEdge) browser = "Edge"
    else if (isEdgeChromium) browser = "Edge Chromium"
    else if (isChrome) browser = "Chrome"
    else if (isBlink) browser = "Bllnk"
    else browser = "Other"
  }

  //No Passwords
  if (input === "inputPassword") return 1
  if (input === "inputPassword2") return 1
  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "action")
  formData.append("button", button)
  formData.append("input", input)
  formData.append("value", value)
  formData.append("errormsg", errormsg)
  formData.append("html", window.location.pathname)
  formData.append("browser", browser)
  formData.append("height", window.innerHeight)
  formData.append("width", window.innerWidth)

  if (Xstate.testing === "on <<<<>>>>") {
    console.log("FETCH")
    console.log("section:account")
    console.log("request:action")
    console.log("button:" + button)
    console.log("input:" + input)
    console.log("value:" + value)
    console.log("errormsg:" + errormsg)
    console.log("html:" + window.location.pathname)
    console.log("browser:" + browser)
    console.log("height" + window.innerHeight)
    console.log("width" + window.innerWidth)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
}
window.onerror = function (message, source, lineno, colno, error) {
  tracker("", "", "", message)
}
export const availability = [
  { text: "Unavailable", class: "fas fa-times-circle red-font", id: "X" },
  { text: "Available", class: "fas fa-check-circle green-font", id: "Y" },
  { text: "Favourite", class: "fas fa-star gold-font", id: "Star" },
]
export const onoff = [
  { text: "off", class: "fas fa-times-circle red-font" },
  { text: "on", class: "fas fa-check-circle green-font" },
]
export const sportRatingJSX = sport => {
  return (
    <span key={sport}>
      <StarsComponent key={sport + "Stars"} variable={sport} type="output" parentState={this.props.parentState} />
      &nbsp;
      {sport}
    </span>
  )
}
export const theader = (type, value) => {
  if (type === "title") document[type] = value
  else {
    var meta = document.getElementsByTagName("meta")
    for (var i = 0; i < meta.length; i++) {
      if (meta[i].name.toLowerCase() === type) {
        meta[i].content = value
      }
    }
  }
}
export const getLocation = input => {
  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "getLocation")
  formData.append("details", input)

  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section:account")
    console.log("request:getLocation")
    console.log("details:" + input)
  }
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      if (isJSON(data)) {
        Xstate.users_distances = undefined
        updateAllDistances(data.latitude, data.longitude)
        Xstate.location = [{ lat: data.latitude, lng: data.longitude }]
        tquery("#redirect").click()
      }
    })
}
