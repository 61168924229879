import { Xstate, isJSON } from "./Shared"
import { tquery } from "../app/ResponsiveLayout-20190729"
import { fetchLink } from "../fetchLink"
import React from "react"
import { ClearClub } from "./League"

export const ActivateAccount = () => {
  Xstate.login.activate = 1
  var formData = new FormData()
  var activationKey = window.location.pathname.substr(18, 10000)
  formData.append("activationKey", activationKey)
  formData.append("section", "account")
  formData.append("request", "activate")
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      window.location = data
    })
}
export const ClearAccountMsg = newState => {
  if (Xstate.login.register === undefined) Xstate.login.register = []
  Xstate.login.register.msg0 = ""
  Xstate.login.register.msg1 = ""
  Xstate.login.register.msg2 = ""
  Xstate.login.register.msg3 = ""
  Xstate.login.register.msg4 = ""
  Xstate.login.register.msg5 = ""
  Xstate.login.register.msg6 = ""
  Xstate.login.register.msg7 = ""
  Xstate.login.register.msg8 = ""

  Xstate.login.msg = ""
  Xstate.login.errorMessage0 = ""

  if (Xstate.login.pswd === undefined) Xstate.login.pswd = []
  Xstate.login.pswd.msg0 = ""
  Xstate.login.pswd.msg1 = ""
  Xstate.login.pswd.msg2 = ""
  Xstate.login.pswd.msg3 = ""
  Xstate.login.pswd.msg4 = ""
  Xstate.login.pswd.msg5 = ""
  Xstate.login.pswd.msg6 = ""
  Xstate.login.pswd.msg7 = ""

  if (Xstate.login.resetpswd === undefined) Xstate.login.resetpswd = []
  Xstate.login.resetpswd.msg = ""
  Xstate.modal.status = newState
  tquery("#updateReact").click()
}
export const ClearUsers = () => {
  Xstate.users_distances = undefined
  Xstate.users_sports = undefined
  Xstate.users_availability = undefined
}
export const CookieLogin = () => {
  if (Xstate.login.status) {
    document.cookie = "Login=" + JSON.stringify(Xstate.login) + ";secure"
    return 1
  } else {
    var dc = ";" + document.cookie
    var start = dc.indexOf(";Login=")

    if (start >= 0) {
      start += "Login".length + 2
      var end = dc.indexOf(";", start)
      end = end === -1 ? dc.length : end

      console.log("Cookie Found")
      console.log(decodeURI(dc.substring(start, end)))
    }
  }
}
export const CookieDelete = () => {
  document.cookie = "Login='';expires=-3600"
}
export const LoginModal = () => {
  Xstate.login.inputEmail = ""
  Xstate.login.msg = ""
  Xstate.JSX.loginReactivate = ""
  Xstate.login.inputPassword = ""
  Xstate.modal.status = "login"
  tquery("#updateReact").click()
}
export const UpdateLogin = () => {
  if (Xstate.login.db === 1) {
    return 1
  }
  updateAllAvailability()
  updateAllDistances()
  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "setup")
  return fetch(fetchLink, {
    credentials: "include",
    method: "POST",
    body: formData,
  })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      if (isJSON(data)) {
        data = JSON.parse(data)
        Object.keys(data).forEach(key => {
          Xstate.login[key] = data[key]
        })
        Xstate.login.db = 1
        CookieLogin()

        //Clear Dashboard (reset)
        Xstate.club.allEvents = undefined

        if (Xstate.login.status) {
          var formData = new FormData()
          formData.append("section", "account")
          formData.append("request", "details")
          return fetch(fetchLink, {
            credentials: "include",
            method: "POST",
            body: formData,
          })
            .then(function (response) {
              return response.text()
            })
            .then(function (data) {
              if (isJSON(data)) {
                data = JSON.parse(data)
                Object.keys(data).forEach(key => {
                  if (key === "users_sports") Xstate.users_sports = data[key]
                  else if (key === "users_availability") {
                    Xstate.login.columns = undefined
                    Xstate.users_availability = data[key]
                  } else Xstate.login[key] = data[key]
                })
              }
              tquery("#updateReact").click()
            })
        }
        tquery("#updateReact").click()
      } else {
        UpdateLogin()
      }
    })
}
export const updateAllAvailability = () => {
  if (Xstate.users_availability_all !== undefined) return 1

  Xstate.users_availability_all = 1

  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "updateAllAvailability")
  return fetch(fetchLink, {
    credentials: "include",
    method: "POST",
    body: formData,
  })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      Xstate.users_availability_all0 = {}
      if (isJSON(data)) {
        Xstate.users_availability_all = JSON.parse(data)
        Xstate.users_availability_all.forEach(item => {
          Xstate.users_availability_all0[item["user_id"] + "_" + item["weekday"] + "_" + item["timeofday"]] =
            1 * item["availability"]
        })
      } else Xstate.users_availability_all = undefined
      tquery("#updateReact").click()
    })
}
export const updateAllDistances = (lat = 0, lng = 0) => {
  if (Xstate.users_distances !== undefined) return 1
  Xstate.users_distances = 1

  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "updateAllDistances")
  formData.append("latitude", lat)
  formData.append("longitude", lng)
  return fetch(fetchLink, {
    credentials: "include",
    method: "POST",
    body: formData,
  })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      if (isJSON(data)) Xstate.users_distances = JSON.parse(data)
      else Xstate.users_distances = undefined
      tquery("#updateReact").click()
    })
}
export const Login = () => {
  var formData = new FormData()
  formData.append("email", Xstate.login.inputEmail)
  formData.append("password", Xstate.login.inputPassword)
  formData.append("section", "account")
  formData.append("request", "login")
  formData.append("type", Xstate.login.type)

  //Show Modal
  Xstate.JSX.loginReactivate = <span></span>
  Xstate.modal.text = "Logging in..."
  Xstate.modal.status = "text-nobutton"
  tquery("#updateReact").click()

  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("email: " + Xstate.login.inputEmail)
    console.log("password: *******")
    console.log("section: account")
    console.log("request: login")
    console.log("type: " + Xstate.login.type)
  }

  //FETCH
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      if (data.trim() === "success") {
        Xstate.login.db = 0
        Xstate.users_availability_all = undefined
        Xstate.users_distances = undefined
        UpdateLogin()
        if (Xstate.login.username === null) {
          Login()
          return 1
        }
        setTimeout(() => {
          if (Xstate.login.username === null || Xstate.login.username === "null") {
            Xstate.modal.text = (
              <span>
                Hi <br />
                <br /> Let's Play!
              </span>
            )
          } else {
            Xstate.modal.text = (
              <span>
                Hi <b>{Xstate.login.username}</b> <br />
                <br /> Let's Play!
              </span>
            )
          }
          Xstate.modal.status = "text"
          tquery("#updateReact").click()
        }, 1000)
      } else if (data.trim() === "user account not yet activated.") {
        Xstate.modal.status = "login"
        Xstate.login.msg = data
        Xstate.JSX.loginReactivate = (
          <button className="btn-link" onClick={() => this.handleReactivate()}>
            resend activation email
          </button>
        )
      } else if (data.trim() === "error") {
        Login()
        return 1
      } else {
        Xstate.modal.status = "login"
        Xstate.login.msg = data
        Xstate.JSX.loginReactivate = <span></span>
        tquery("#updateReact").click()
      }
      ClearClub(0, 0, 0, 1)
      ClearUsers()
    })
}
export const Logout = () => {
  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "logout")

  //Back to Default
  Xstate.login.default_sport_temp = ""
  Xstate.login.text = "Login"
  Xstate.login.status = false
  tquery("#updateReact").click()

  //Now Fetch
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      Xstate.login.db = 0
      Xstate.users_availability_all = undefined
      Xstate.users_distances = undefined
      UpdateLogin()
      ClearClub(0, 0, 0, 1)
      ClearUsers()
      CookieDelete()
      document.querySelector("#homeButton").click()
      tquery("#updateReact").click()
    })
}
export const Register = () => {
  Xstate.login.register.msg1 = ""
  Xstate.login.register.msg2 = ""
  Xstate.login.register.msg3 = ""
  Xstate.login.register.msg4 = ""
  Xstate.login.register.msg5 = ""
  Xstate.login.register.msg6 = ""
  Xstate.login.register.msg7 = ""
  Xstate.login.register.msg8 = ""

  if (Xstate.login.register.inputUsername.length < 2)
    Xstate.login.register.msg1 = "username must be at least 2 characters long"
  if (Xstate.login.register.inputUsername.length > 50) Xstate.login.register.msg2 = "username is too long"
  if (Xstate.login.register.type === "email") {
    if (Xstate.login.register.inputPassword.length < 7)
      Xstate.login.register.msg3 = "password must be at least 7 characters long"
    if (Xstate.login.register.inputPassword.length > 20) Xstate.login.register.msg4 = "password is too long"
    if (Xstate.login.register.inputPassword.search(/\d/) === -1)
      Xstate.login.register.msg5 = "password must contain at least 1 number"
    if (Xstate.login.register.inputPassword.search(/[a-zA-Z]/) === -1)
      Xstate.login.register.msg6 = "password must contain at least 1 letter"
    if (Xstate.login.register.inputPassword.search(/[A-Z]/) === -1)
      Xstate.login.register.msg7 = "password must contain at least 1 capital letter"
    if (Xstate.login.register.inputPassword !== Xstate.login.register.inputPassword2)
      Xstate.login.register.msg8 = "passwords don't match"
  }
  tquery("#updateReact").click()
  if (
    Xstate.login.register.msg1 === "" &&
    Xstate.login.register.msg2 === "" &&
    Xstate.login.register.msg3 === "" &&
    Xstate.login.register.msg4 === "" &&
    Xstate.login.register.msg5 === "" &&
    Xstate.login.register.msg6 === "" &&
    Xstate.login.register.msg7 === "" &&
    Xstate.login.register.msg8 === ""
  ) {
    var formData = new FormData()
    formData.append("email", Xstate.login.register.inputEmail)
    formData.append("username", Xstate.login.register.inputUsername)
    formData.append("password", Xstate.login.register.inputPassword)
    formData.append("type", Xstate.login.register.type)
    formData.append("section", "account")
    formData.append("default_sport", Xstate.login.register.default_sport)
    formData.append("request", "register")
    Xstate.modal.status = "text"
    Xstate.modal.text = "Working on it, please wait."
    tquery("#updateReact").click()
    if (Xstate.testing === "on") {
      console.log("FETCH")
      console.log("section:account")
      console.log("request:register")
      console.log("username:" + Xstate.login.register.inputUsername)
      console.log("email:" + Xstate.login.register.inputEmail)
      console.log("password:" + Xstate.login.register.inputPassword)
      console.log("type:" + Xstate.login.register.type)
      console.log("default_sport:" + Xstate.login.register.default_sport)
    }
    //should be http://localhost/connactiveclub/public for dev
    fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
      .then(function (response) {
        return response.text()
      })
      .then(function (data) {
        if (data.trim() === "register sucess") {
          Xstate.modal.text = "Activation code has been successfully sent to your email address."
          Xstate.modal.status = "text"
          tquery("#updateReact").click()
        } else if (data.trim() === "Login-Social") {
          Xstate.modal.text = "Success. You are all signed up. Please login now. "
          Xstate.modal.status = "text"
          tquery("#updateReact").click()
        } else {
          Xstate.login.register.msg0 = data
          Xstate.modal.status = "register"
          Xstate.login.register.inputPassword = ""
          Xstate.login.register.inputPassword2 = ""
          tquery("#updateReact").click()
        }
        return data
      })
  }
}
export const ChangeUsernameSubmit = () => {
  Xstate.login.errorMessage0 = ""
  if (Xstate.login.newusername.trim().length < 2) {
    Xstate.login.errorMessage0 = "Username must be at least 2 characters long."
    tquery("#updateReact").click()
    return 1
  }
  Xstate.modal.status = "text-nobutton"
  Xstate.modal.text = "Loading"

  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "change_username")
  formData.append("newusername", Xstate.login.newusername)
  if (Xstate.testing === "on") console.log("FETCH - change_username")
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      if (data.trim() === "change_username: success") {
        Xstate.modal.status = "text"
        Xstate.modal.text = "Username updated successfully."
        Xstate.login.db = 0
        Xstate.users_availability_all = undefined
        Xstate.users_distances = undefined
        UpdateLogin()
      } else {
        Xstate.modal.status = "changeUserName"
        Xstate.login.errorMessage0 = data.trim()
      }
      tquery("#updateReact").click()
    })
}
/* export const ChangePasswordChange = (event) => {
 event.preventDefault();
 ClearAccountMsg();
 Xstate.login.pswd[event.target.name] = event.target.value;
 tquery("#updateReact").click();
};
*/
export const ChangePassword = event => {
  ClearAccountMsg()
  tquery("#updateReact").click()

  //Password Errors
  if (Xstate.login.pswd.inputOldPassword.length < 7) {
    Xstate.login.pswd.msg0 = "Current password should be at least 7 characters long."
  }
  if (Xstate.login.pswd.inputNewPassword.length < 7) {
    Xstate.login.pswd.msg1 = "Password must be at least 7 characters long"
  }
  if (Xstate.login.pswd.inputNewPassword.length > 20) {
    Xstate.login.pswd.msg2 = "Password is too long. "
  }
  if (Xstate.login.pswd.inputNewPassword.search(/\d/) === -1) {
    Xstate.login.pswd.msg3 = "Password must contain at least 1 number"
  }
  if (Xstate.login.pswd.inputNewPassword.search(/[a-zA-Z]/) === -1) {
    Xstate.login.pswd.msg4 = "Password must contain at least 1 letter"
  }
  if (Xstate.login.pswd.inputNewPassword.search(/[A-Z]/) === -1) {
    Xstate.login.pswd.msg5 = "Password must contain at least 1 capital letter"
  }
  if (Xstate.login.pswd.inputNewPassword !== Xstate.login.pswd.inputNewPassword2) {
    Xstate.login.pswd.msg6 = "Passwords don't match"
  }
  tquery("#updateReact").click()
  if (
    Xstate.login.pswd.msg1 === "" &&
    Xstate.login.pswd.msg2 === "" &&
    Xstate.login.pswd.msg3 === "" &&
    Xstate.login.pswd.msg4 === "" &&
    Xstate.login.pswd.msg5 === "" &&
    Xstate.login.pswd.msg6 === "" &&
    Xstate.login.pswd.msg7 === ""
  ) {
    Xstate.modal.status = "text-nobutton"
    Xstate.modal.text = "Loading..."
    var formData = new FormData()
    formData.append("section", "account")
    formData.append("request", "process_change_password")
    formData.append("email", Xstate.login.email)
    formData.append("oldpassword", Xstate.login.pswd.inputOldPassword)
    formData.append("newpassword", Xstate.login.pswd.inputNewPassword)
    if (Xstate.testing === "on") {
      console.log("FETCH")
      console.log("Change Password")
    }
    fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
      .then(function (response) {
        return response.text()
      })
      .then(function (data) {
        if (data.trim() === "password updated successfully.") {
          Xstate.modal.status = "text"
          Xstate.modal.text = "Password updated successfully."
        } else if (data.trim() === "error") {
          ChangePassword(event)
          return 1
        } else {
          Xstate.login.pswd.msg0 = data
          Xstate.modal.status = "changepswd"
        }
        tquery("#updateReact").click()
        return data
      })
  } else {
    Xstate.modal.status = "changepswd"
    tquery("#updateReact").click()
  }
}
export const DefaultSportSubmit = () => {
  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "default_sport_change")
  formData.append("email", Xstate.login.email)
  formData.append("default_sport", Xstate.login.default_sport)
  if (Xstate.testing === "on")
    console.log(
      "FETCH",
      "section:account",
      "request:default_sport_change",
      "email:" + Xstate.login.email,
      "default_sport:" + Xstate.login.default_sport,
    )
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      console.log(data)
      if (data.trim() === "default sport updated successfully.") {
        Xstate.modal.text = <span> Your Default Sport Update Has Been Successful </span>
        Xstate.modal.status = "text"
        tquery("#updateReact").click()
      } else {
        Xstate.modal.text = (
          <span> Your Default Sport Update Has Failed - Try again next week? P.S. thanks for your patience. </span>
        )
        Xstate.modal.status = "text"
        tquery("#updateReact").click()
      }
      return data
    })
}
export const ResetPasswordSubmit = event => {
  event.preventDefault()
  ClearAccountMsg()

  //passwords don't match
  if (Xstate.login.pswd.inputNewPassword !== Xstate.login.pswd.inputNewPassword2) {
    Xstate.login.pswd.msg0 = "passwords don't match"
    tquery("#updateReact").click()
  } else {
    var formData = new FormData()
    formData.append("password", Xstate.login.pswd.inputNewPassword)
    formData.append("section", "account")
    formData.append("request", "process_reset")
    formData.append("username", Xstate.login.resetusername)
    fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
      .then(function (response) {
        return response.text()
      })
      .then(function (data) {
        if (data.trim() === "password updated successfully.") {
          Xstate.modal.status = "text"
          Xstate.modal.text = "Password updated successfully."
          tquery("#homeButton").click()
        } else {
          console.log(data)
          Xstate.login.pswd.msg0 = data
        }
        tquery("#updateReact").click()
        return data
      })
  }
}
export const ResetPasswordFetch = event => {
  event.preventDefault()
  Xstate.login.resetpswd.msg = ""
  tquery("#updateReact").click()

  var formData = new FormData()
  formData.append("email", Xstate.login.resetpswd.inputResetEmail)
  formData.append("section", "account")
  formData.append("request", "forgot_pwd")
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      if (data.trim() === "please check your email for resetting your password") {
        Xstate.modal.status = "text"
        Xstate.modal.text = "Your password has been reset. Please check your email."
        tquery("#updateReact").click()
      } else {
        Xstate.login.resetpswd.msg = data
      }
      tquery("#updateReact").click()
      return data
    })
}
export const handleReactivate = () => {
  var formData = new FormData()
  formData.append("email", Xstate.login.inputEmail)
  formData.append("section", "account")
  formData.append("request", "resend_key")
  fetch(fetchLink, { credentials: "include", method: "POST", body: formData })
    .then(function (response) {
      return response.text()
    })
    .then(function (data) {
      if (data.trim() === "Activation code has been successfully sent to your Email Address") {
        Xstate.login.msg = "Activation code has been sent successfully."
        Xstate.JSX.loginReactivate = <span></span>
        Xstate.modal.status = "reactivation"
      } else {
        Xstate.login.msg = data
      }
      tquery("#updateReact").click()
    })

  Xstate.JSX.loginReactivate = <span></span>
}
export const SaveUsersAvailability = () => {
  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "SaveUsersAvailability")
  formData.append("details", JSON.stringify(Xstate.users_availability))
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section: account")
    console.log("details: SaveUsersAvailability")
    console.log("details: " + JSON.stringify(Xstate.users_availability))
  }
  return fetch(fetchLink, {
    credentials: "include",
    method: "POST",
    body: formData,
  })
}
export const SaveUsersSports = () => {
  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "SaveUsersSports")
  formData.append("details", JSON.stringify(Xstate.users_sports))
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section: account")
    console.log("details: SaveUsersSports")
    console.log("details: " + JSON.stringify(Xstate.users_sports))
  }
  return fetch(fetchLink, {
    credentials: "include",
    method: "POST",
    body: formData,
  })
}
export const SaveUsersLocation = () => {
  Xstate.login.latitude = Xstate.location[0].lat
  Xstate.login.longitude = Xstate.location[0].lng
  Xstate.login.city = Xstate.location[0].label
  if (Xstate.login.city === "Current Location") Xstate.login.city = "Custom Location"
  var formData = new FormData()
  formData.append("section", "account")
  formData.append("request", "SaveUsersLocation")
  formData.append("details", JSON.stringify(Xstate.login))
  if (Xstate.testing === "on") {
    console.log("FETCH")
    console.log("section: account")
    console.log("details: SaveUsersLocation")
    console.log("details: " + JSON.stringify(Xstate.login))
  }
  return fetch(fetchLink, {
    credentials: "include",
    method: "POST",
    body: formData,
  })
}
