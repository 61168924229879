import React, { Component } from "react"
import { Xstate } from "../functions/Shared"
import { tquery } from "../app/ResponsiveLayout-20190729"

export const LeagueSettingsLocationSelect = (allowMultiple = 1) => {
  let counter = "53411"
  if (Xstate.posts.filters.KM === undefined) {
    Xstate.posts.filters.KM = 100
  }
  if (Xstate.location === undefined) {
    Xstate.location = []
  }
  for (let i = 0; i <= 4; i++) {
    if (Xstate.location[i] === undefined) {
      Xstate.location[i] = {}
      Xstate.location[i].label = "search"
    }
  }
  Xstate.posts.filters.location = Xstate.location
  let distanceJSX = (
    <tr key={counter++}>
      <td className="td-middle">
        <b>Distance:</b>
      </td>
      <td>
        <input
          id="distanceInput"
          type="number"
          style={{ width: "5em" }}
          required
          min="1"
          max="1000"
          step="1"
          value={Xstate.posts.filters.KM}
          onChange={event => {
            Xstate.posts.filters.KM = event.target.value
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        />
        &nbsp;km
      </td>
    </tr>
  )
  let buttonRow = x => {
    if (Xstate.location[x].lat !== undefined) {
      return (
        <button
          id="locationSearchRemoveX"
          className="btn btn-content2 btn-small"
          onClick={() => {
            Xstate.location[x].lat = undefined
            Xstate.location[x].lng = undefined
            Xstate.location[x].label = "search"
            Xstate.posts.filters.refresh = 1
            tquery("#updateReact").click()
          }}
        >
          &nbsp;X&nbsp;
        </button>
      )
    }
    return (
      <button
        id="locationSearchMainPage"
        className="btn btn-content2 btn-small"
        onClick={() => {
          Xstate.locationID = x
          Xstate.location[0].type = "city"
          Xstate.JSX.locationOutputJSX = []
          Xstate.JSX.locationOutputPostCodeJSX = []
          Xstate.modal.status = "location"
          Xstate.modal.location_use = "Other"
          tquery("#updateReact").click()
        }}
      >
        &nbsp;
        <span className=" fas fa-search" />
        &nbsp;
      </button>
    )
  }
  let locationRow = x => {
    return (
      <span>
        &nbsp;
        {Xstate.location[x].label}
        &nbsp;
        {buttonRow(x)}
      </span>
    )
  }
  let buttonMultiple = <span />
  if (allowMultiple === 1) {
    buttonMultiple = (
      <button
        id="locationSearchMultiplePlayers"
        className="btn btn-content2 btn-small"
        onClick={() => {
          Xstate.location[1] = { ...Xstate.location[0] }
          Xstate.posts.filters.locationType = "multiple"
          tquery("#updateReact").click()
        }}
      >
        Multiple Locations
      </button>
    )
  }
  let buttonSingle = (
    <button
      id="locationSearchMultiplePlayers"
      className="btn btn-content2 btn-small"
      onClick={() => {
        Xstate.posts.filters.locationType = "single"
        Xstate.location[0].label = Xstate.location[1].label
        Xstate.location[0].lat = Xstate.location[1].lat
        Xstate.location[0].lng = Xstate.location[1].lng
        Xstate.posts.filters.refresh = 1
        tquery("#updateReact").click()
      }}
    >
      Single Location
    </button>
  )

  if (Xstate.posts.filters.locationType === "multiple") {
    let temp = []
    let multiple = []
    for (let i = 1; i <= 4; i++) {
      multiple.push(
        <span key={counter++}>
          {locationRow(i)}
          <br />
        </span>,
      )
    }
    temp.push(
      <tr key={counter++}>
        <td>
          <b>Location: </b>
          <br />
        </td>
        <td className="td-middle">
          {multiple}
          {buttonSingle}
        </td>
      </tr>,
    )
    temp.push(distanceJSX)
    return temp
  }

  let temp = []
  temp.push(
    <tr key={counter++}>
      <td>
        <b>Location: </b>
        <br />
      </td>
      <td className="td-middle">
        {locationRow(0)}
        <br />
        {buttonMultiple}
      </td>
    </tr>,
  )
  temp.push(distanceJSX)
  return temp
}
